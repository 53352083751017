import Vue from 'vue';

export default Vue.extend({
  methods: {
    parseDate(date: string): string {
      if (!date) return '';
      if (date.split('.').length === 3) {
        // continue only if date can be splitted
        const [day, month, year] = date.split('.');
        if (/^\d{4}-\d{2}-\d{2}$/.test(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)) {
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }
      }
      return '';
    },
  },
});
