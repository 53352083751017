<template>
  <h1>Tab2</h1>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'tab-2',
});
</script>
