<template>
  <v-container fluid>
    <v-row>
      <v-col class="py-0" cols="2">
        <v-autocomplete
          v-model.number="filter.feature"
          :items="listFeature"
          hide-details
          v-on:change="(tableOptions.page = 1), getListBoxCategories()"
          label="Feature"
          clearable
        />
      </v-col>
      <v-col class="py-0" cols="2">
        <v-autocomplete
          v-model.number="filter.category"
          :items="listCategory"
          hide-details
          v-on:change="
            (tableOptions.page = 1), getDataFromApi(), getListBoxVersionReportedIn(), getListBoxVersionResolvedIn()
          "
          clearable
          label="Category"
        />
      </v-col>
      <v-col class="py-0" cols="2">
        <v-autocomplete
          v-model.number="filter.status"
          :items="listStatus"
          hide-details
          v-on:change="(tableOptions.page = 1), getDataFromApi()"
          clearable
          label="Status"
        />
      </v-col>
      <v-col class="py-0" cols="2">
        <v-autocomplete
          v-model.number="filter.priority"
          :items="listPriority"
          hide-details
          v-on:change="(tableOptions.page = 1), getDataFromApi()"
          clearable
          label="Priority"
        />
      </v-col>
      <v-col class="py-0" cols="2">
        <v-autocomplete
          v-model.number="filter.versionReported"
          :items="listVersionReported"
          clearable
          hide-details
          v-on:change="(tableOptions.page = 1), getDataFromApi()"
          label="Reported"
        />
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-autocomplete
          v-model.number="filter.versionResolved"
          :items="listVersionResolved"
          clearable
          hide-details
          v-on:change="(tableOptions.page = 1), getDataFromApi()"
          label="Resolved"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0" cols="2">
        <v-menu
          ref="dateMenuCreatedFrom"
          v-model="dateMenuCreatedFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormattedCreatedFrom"
              label="Created From:"
              :append-icon="mdiCalendar"
              @blur="filter.createdDateFrom = parseDate(dateFormattedCreatedFrom)"
              readonly
              v-on="on"
              hide-details
              clearable
              @click:clear="clearedCreatedDateFrom"
              autocomplete="off"
            />
          </template>
          <v-date-picker
            v-model="filter.createdDateFrom"
            no-title
            v-on:change="getDataFromApi"
            @input="dateMenuCreatedFrom = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-menu
          ref="dateMenuCreatedTo"
          v-model="dateMenuCreatedTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormattedCreatedTo"
              label="Created To:"
              :append-icon="mdiCalendar"
              @blur="filter.createdDateTo = parseDate(dateFormattedCreatedTo)"
              readonly
              v-on="on"
              hide-details
              clearable
              @click:clear="clearedCreatedDateTo"
              autocomplete="off"
            />
          </template>
          <v-date-picker
            v-model="filter.createdDateTo"
            no-title
            v-on:change="getDataFromApi"
            @input="dateMenuCreatedTo = false"
          />
        </v-menu>
      </v-col>

      <v-col class="pt-0" cols="2">
        <v-menu
          ref="dateMenuModifiedFrom"
          v-model="dateMenuModifiedFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormattedModifiedFrom"
              label="Modified From:"
              :append-icon="mdiCalendar"
              @blur="filter.modifiedDateFrom = parseDate(dateFormattedModifiedFrom)"
              readonly
              v-on="on"
              hide-details
              clearable
              @click:clear="clearedModifiedDateFrom"
              autocomplete="off"
            />
          </template>
          <v-date-picker
            v-model="filter.modifiedDateFrom"
            no-title
            v-on:change="getDataFromApi"
            @input="dateMenuModifiedFrom = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-menu
          ref="dateMenuModifiedTo"
          v-model="dateMenuModifiedTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormattedModifiedTo"
              label="Modified To:"
              :append-icon="mdiCalendar"
              @blur="filter.modifiedDateTo = parseDate(dateFormattedModifiedTo)"
              readonly
              v-on="on"
              hide-details
              clearable
              @click:clear="clearedModifiedDateTo"
              autocomplete="off"
            />
          </template>
          <v-date-picker
            v-model="filter.modifiedDateTo"
            no-title
            v-on:change="getDataFromApi"
            @input="dateMenuModifiedTo = false"
          />
        </v-menu>
      </v-col>
      <v-col class="py-0" cols="4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filter.searchString"
              label="Smart search"
              clearable
              hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <span>
            Search for values that match the meaning and not just the exact wording.
            <br />For exact wording use % like : %example% , or %firstWord%lastWord%
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <div class="my-1"></div>
    <v-data-table
      :headers="ftHeader ? headersFt : headers"
      :loading="loading"
      :items="tableData"
      :footer-props="{ itemsPerPageOptions: [20, 40, 60] }"
      :options.sync="tableOptions"
      :server-items-length="totalItems"
      fixed-header
      must-sort
      dense
      :height="$store.state.windowSize.y - 230"
      class="tableSelectCursor"
    >
      <template v-slot:item="{ item }">
        <tr
          :style="'background-color: #' + setLineColor(item) + ';'"
          @click="(bugDetailDialog = true), (bugDetailRecord = item.reportedBugID)"
        >
          <td v-if="ftHeader">{{ item.ftrank }}</td>
          <td>{{ item.bugNumber }}</td>
          <td>{{ item.created | moment($store.getters.dateFormat) }}</td>
          <td>{{ item.modified | moment($store.getters.dateFormat) }}</td>
          <td>{{ item.reportedVersion }}</td>
          <td>{{ item.resolvedVersion }}</td>
          <td>{{ item.category }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.priority }}</td>
          <td>
            <div style="max-height: 42px; overflow: hidden">{{ item.summary }}</div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="bugDetailDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <BugDetail :bugDetailRecord="bugDetailRecord" @bugDetailClose="bugDetailDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import BugDetail from '@/components/proman/bugtrackDetail.vue';
import dateFormatMixin from '../../mixins/dateFormat';
import { mdiCalendar } from '@mdi/js';

let ftTimer: any = 0; // outside to avoid model inheritance

export default Vue.extend({
  name: 'pmBugtrack',
  components: { BugDetail },
  mixins: [dateFormatMixin],
  data: () => ({
    tableData: [],
    listFeature: [],
    listStatus: [],
    listVersion: [],
    listVersionReported: [],
    listVersionResolved: [] as any,
    listCategory: [],
    listPriority: [],
    dateMenuCreatedFrom: false,
    dateFormattedCreatedFrom: '',
    dateMenuCreatedTo: false,
    dateFormattedCreatedTo: '',
    dateMenuModifiedFrom: false,
    dateFormattedModifiedFrom: '',
    dateMenuModifiedTo: false,
    dateFormattedModifiedTo: '',
    filter: {
      feature: 0,
      status: 0,
      category: 0,
      versionReported: 0,
      versionResolved: 0,
      priority: 0,
      createdDateFrom: '',
      createdDateTo: '',
      modifiedDateFrom: '',
      modifiedDateTo: '',
      smart: false,
      searchString: '',
    },
    tableOptions: {
      sortBy: ['bugNumber'],
      sortDesc: [true],
      itemsPerPage: 20,
      page: 1,
    },
    loading: false,
    totalItems: 0,
    headers: [
      { text: 'Bug', value: 'bugNumber', width: '6%' },
      { text: 'Created', value: 'created', width: '7%' },
      { text: 'Modified', value: 'modified', width: '7%' },
      { text: 'Reported', value: 'reportedVersion', width: '7%' },
      { text: 'Resolved', value: 'resolvedVersion', width: '7%' },
      { text: 'Category', value: 'category', width: '10%' },
      { text: 'Status', value: 'status', width: '7%' },
      { text: 'Priority', value: 'priority', width: '7%' },
      { text: 'Summary', value: 'summary', width: '42%' },
    ],
    headersFt: [
      // Headers in case of fullText
      { text: 'Rank', value: 'ftrank', width: '7%' },
      { text: 'Bug', value: 'bugNumber', width: '7%' },
      { text: 'Created', value: 'created', width: '7%' },
      { text: 'Modified', value: 'modified', width: '7%' },
      { text: 'Reported', value: 'reportedVersion', width: '7%' },
      { text: 'Resolved', value: 'resolvedVersion', width: '7%' },
      { text: 'Category', value: 'category', width: '10%' },
      { text: 'Status', value: 'status', width: '7%' },
      { text: 'Priority', value: 'priority', width: '7%' },
      { text: 'Summary', value: 'summary', width: '34%' },
    ],
    headersFT: [
      { text: 'Rank', value: 'rank', width: '5%' },
      { text: 'Summary', value: 'summary', width: '45%' },
      { text: 'Aditional information', value: 'additionalInformation', width: '50%' },
    ],
    bugDetailDialog: false,
    bugDetailRecord: 0,
    windowHeight: 0,
    ftHeader: false,
    ftDelays: [] as any,
    ftTimeout: '' as any,
    mdiCalendar: mdiCalendar,
  }),
  watch: {
    'filter.createdDateFrom'() {
      this.dateFormattedCreatedFrom = this.$moment(this.filter.createdDateFrom).format(this.$store.getters.dateFormat);
    },
    'filter.createdDateTo'() {
      this.dateFormattedCreatedTo = this.$moment(this.filter.createdDateTo).format(this.$store.getters.dateFormat);
    },
    'filter.modifiedDateFrom'() {
      this.dateFormattedModifiedFrom = this.$moment(this.filter.modifiedDateFrom).format(
        this.$store.getters.dateFormat,
      );
    },
    'filter.modifiedDateTo'() {
      this.dateFormattedModifiedTo = this.$moment(this.filter.modifiedDateTo).format(this.$store.getters.dateFormat);
    },
    'filter.searchString'(value) {
      let ftLoadTimeout = 2000;
      if (this.$store.getters.userSettings.typeSpeed !== 0) {
        ftLoadTimeout = this.$store.getters.userSettings.typeSpeed * 4;
      }
      if (ftTimer === 0) {
        ftTimer = Date.now();
      }
      clearTimeout(this.ftTimeout);
      this.ftDelays.push(Date.now() - ftTimer);
      ftTimer = Date.now();
      this.ftTimeout = setTimeout(() => {
        // remove first because it's 0
        this.ftDelays.shift();
        // remove second because it might be long press delete
        this.ftDelays.shift();
        if (this.ftDelays.length > 1) {
          // At least 4 chars to calculate average
          let average = Math.round(
            this.ftDelays.reduce((previous: number, current: number) => (current += previous)) / this.ftDelays.length,
          );
          if (average > 70 && average < 1200) {
            // Ignore too fast or too slow
            if (this.$store.getters.userSettings.typeSpeed !== 0) {
              // fake weighted average. Saved value is weight is 3times more than new
              average = Math.round((this.$store.getters.userSettings.typeSpeed * 3 + average) / 4);
            }
            axios.patch(`/api/v1/user/typespeed/${average}`);
            this.$store.getters.userSettings.typeSpeed = average;
          }
        }
        this.tableOptions.page = 1;
        // On clear by X string become null so change to '' which cause new watcher call and correct data load
        if (this.filter.searchString === null) {
          this.filter.searchString = '';
          return;
        }
        if (this.filter.searchString != '') {
          if (this.filter.searchString.includes('%')) {
            this.filter.smart = false;
            this.tableOptions.sortBy[0] = 'bugNumber';
            this.tableOptions.sortDesc[0] = true;
            this.getDataFromApi();
          } else {
            this.tableOptions.sortBy[0] = 'ftrank';
            this.tableOptions.sortDesc[0] = true;
            this.filter.smart = true;
            this.getDataFromApi();
          }
        } else {
          this.filter.searchString = '';
          this.tableOptions.sortBy[0] = 'bugNumber';
          this.tableOptions.sortDesc[0] = true;
          this.filter.smart = true;
          this.getDataFromApi();
        }
        this.ftDelays = [];
        ftTimer = 0;
      }, ftLoadTimeout);
    },
    tableOptions: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getListBoxFeatures();
    this.getListBoxStatuses();
    this.getListBoxPriorities();
    this.getListBoxVersionReportedIn();
    this.getListBoxVersionResolvedIn();
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      // if filter cleared rewrite by 0
      if (this.filter.status === undefined) {
        this.filter.status = 0;
      }
      if (this.filter.category === undefined) {
        this.filter.category = 0;
      }
      if (this.filter.versionReported === undefined) {
        this.filter.versionReported = 0;
      }
      if (this.filter.versionResolved === undefined) {
        this.filter.versionResolved = 0;
      }
      if (this.filter.priority === undefined) {
        this.filter.priority = 0;
      }
      // const { sortBy, descending, page, rowsPerPage, totalItems } = this.pagination;
      await axios
        .get('api/v1/bt/list', {
          params: {
            sortBy: this.tableOptions.sortBy[0],
            descending: this.tableOptions.sortDesc[0],
            page: this.tableOptions.page,
            rowsPerPage: this.tableOptions.itemsPerPage,
            feature: this.filter.feature,
            status: this.filter.status,
            category: this.filter.category,
            versionReported: this.filter.versionReported,
            versionResolved: this.filter.versionResolved,
            priority: this.filter.priority,
            createdDateFrom: this.filter.createdDateFrom,
            createdDateTo: this.filter.createdDateTo,
            modifiedDateFrom: this.filter.modifiedDateFrom,
            modifiedDateTo: this.filter.modifiedDateTo,
            searchString: this.filter.searchString,
            smart: this.filter.smart,
          },
        })
        .then((response) => {
          this.loading = false;
          this.totalItems = response.data.linesCount;
          this.tableData = response.data.bugTrackRecords;
          if (response.data.linesCount > 0 && response.data.bugTrackRecords[0].ftrank > 0) {
            this.ftHeader = true;
          } else {
            this.ftHeader = false;
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
      this.loading = false;
    },
    getListBoxFeatures() {
      axios
        .get(`api/v1/bt/listbox/features/1`)
        .then((response) => {
          this.listFeature = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxCategories() {
      if (this.filter.feature === undefined) {
        this.filter.feature = 0;
        this.filter.category = 0;
      }
      this.getDataFromApi();
      axios
        .get(`api/v1/bt/listbox/categories/${this.filter.feature}`)
        .then((response) => {
          this.listCategory = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxStatuses() {
      axios
        .get(`api/v1/bt/listbox/statuses/1`)
        .then((response) => {
          this.listStatus = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxPriorities() {
      axios
        .get(`api/v1/bt/listbox/priorities`)
        .then((response) => {
          this.listPriority = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxVersionReportedIn() {
      let catID; // If category filter deleted by X or default (get all versions)
      if (this.filter.category === undefined || this.filter.category === 0) {
        catID = '';
      } else {
        catID = this.filter.category;
      }
      axios
        .get(`api/v1/bt/listbox/reportedin/${catID}`)
        .then((response) => {
          this.listVersionReported = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxVersionResolvedIn() {
      let catID; // If category filter deleted by X or default (get all versions)
      if (this.filter.category === undefined || this.filter.category === 0) {
        catID = '';
      } else {
        catID = this.filter.category;
      }
      this.listVersionResolved = [];
      axios
        .get(`api/v1/bt/listbox/resolvedin/${catID}`)
        .then((response) => {
          for (const version of response.data) {
            if (version.value !== 0) {
              this.listVersionResolved.push(version);
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListBoxes() {
      axios
        .get('api/v1/bt/listboxes')
        .then((response) => {
          this.listStatus = response.data.status;
          this.listCategory = response.data.category;
          this.listVersion = response.data.version;
          this.listPriority = response.data.priority;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    // Workaround because check on blur/change not respond on clear
    clearedCreatedDateFrom() {
      this.filter.createdDateFrom = '';
      this.getDataFromApi();
    },
    clearedCreatedDateTo() {
      this.filter.createdDateTo = '';
      this.getDataFromApi();
    },
    clearedModifiedDateFrom() {
      this.filter.modifiedDateFrom = '';
      this.getDataFromApi();
    },
    clearedModifiedDateTo() {
      this.filter.modifiedDateTo = '';
      this.getDataFromApi();
    },
    setLineColor(item: any) {
      // console.log('col: ', item.statusColor.toString(16));
      if (this.$vuetify.theme.dark) {
        switch (item.statusColor.toString(16)) {
          case 'c0c0c0': // Normal line
            return '1E1E1E';
          case 'ff8080': // BUG
            return '924343';
          case 'ffff80': // Confirmed
            return '6F6F3C';
          case '80ffff':
            return '2E7D88';
          case '6fff6f':
            return '5A995A';
          default:
            return item.statusColor.toString(16);
        }
      } else {
        switch (item.statusColor.toString(16)) {
          case 'c0c0c0': // Normal line
            return 'FFFFFF';
          default:
            return item.statusColor.toString(16);
        }
      }
    },
  },
});
</script>
