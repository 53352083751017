<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0 px-0">
        <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
          :options="{ itemsPerPage: 40 }"
          :footer-props="{ itemsPerPageOptions: [20, 40, 60, 80] }"
          item-key="id"
          :height="$store.getters.windowSize.y - 240"
          fixed-header
          dense
          class="tableSelectCursor"
        >
          <template v-slot:item="{ item }">
            <tr @click="showLog(item.id)">
              <td v-if="item.date">{{ item.date | moment('DD.MM.YYYY HH:mm:ss') }}</td>
              <td>{{ item.login }}</td>
              <td>{{ item.method }}</td>
              <td>{{ item.api }}</td>
              <td>{{ item.remoteAddr }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogViewLog" width="80%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Change log:
          <v-spacer />
          <v-icon large @click="dialogViewLog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-sheet style="overflow: auto" height="500">
            <json-viewer
              :value="changelog"
              :theme="this.$vuetify.theme.dark ? 'change-log-dark' : 'change-log-light'"
              :expand-depth="1"
              sort
            />
          </v-sheet>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import JsonViewer from 'vue-json-viewer';
import { mdiClose, mdiMagnify } from '@mdi/js';

Vue.use(JsonViewer);

export default Vue.extend({
  name: 'ChangeLog',
  data: () => ({
    tableData: [],
    changelog: {},
    search: '',
    dialogViewLog: false,
    headers: [
      { text: 'Date', value: 'date' },
      { text: 'Login', value: 'login' },
      { text: 'Method', value: 'method' },
      { text: 'API', value: 'api' },
      { text: 'Address', value: 'remoteAddr' },
    ],
    mdiClose: mdiClose,
    mdiMagnify: mdiMagnify,
  }),
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      axios
        .get('api/v1/changelog')
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    showLog(id: number) {
      axios
        .get(`api/v1/changelog/${id}`)
        .then((response) => {
          if (response.data[0].data != '') {
            this.changelog = JSON.parse(response.data[0].data);
          }
          this.dialogViewLog = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>

<style lang="scss">
.change-log-dark {
  background: #1f1f1f;
  white-space: nowrap;
  color: #525252;
  font-size: 22px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: rgb(104, 104, 104);
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #bdb2b2;
  }
  .jv-item {
    &.jv-array {
      color: #506d5b;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-number-float {
      color: #fc1e70;
    }
    &.jv-number-integer {
      color: #708cce;
    }
    &.jv-object {
      color: #818080;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #42b983;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}

.change-log-light {
  background: #ffffff;
  white-space: nowrap;
  color: #525252;
  font-size: 22px;
  font-family: Consolas, Menlo, Courier, monospace;

  .jv-ellipsis {
    color: #999;
    background-color: #eee;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #272626;
  }
  .jv-item {
    &.jv-array {
      color: #111111;
    }
    &.jv-boolean {
      color: #fc1e70;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #fc1e70;
    }
    &.jv-number-float {
      color: #fc1e70;
    }
    &.jv-number-integer {
      color: #1e22fc;
    }
    &.jv-object {
      color: #111111;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #1fa368;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
