<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
          <div style="min-height: 2px"></div>

          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 230"
            :search="search"
            @click:row="rowClicked"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td>{{ item.entity_id }}</td>
                <td>{{ item.product }}</td>
                <td>{{ item.start_date | moment($store.getters.dateFormat) }}</td>
                <td>{{ item.validity }}</td>
                <td>{{ item.end_date | moment($store.getters.dateFormat) }}</td>
                <td v-bind:class="expireColorSet(item)">
                  {{ item.days_toExpire }}
                </td>
                <td v-if="item.bcStatus" class="text-center">
                  <v-icon color="btnColorConfirm">{{ mdiLinkLock }}</v-icon>
                </td>
                <td v-if="!item.bcStatus">
                  <v-icon color="red">{{ mdiAlertCircle }}</v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="tableSlotBackground pa-3" :colspan="headers.length">
                <v-row>
                  <v-col cols="1">
                    <v-btn
                      v-if="$store.getters.userSettings.accSerialisations.patch && !item.closed"
                      @click="(modalEdit = true), (serialisation = { ...item }), (serialisationTemporary = false)"
                      block
                      small
                      tile
                      class="mb-2"
                      color="btnColorNew"
                      >New</v-btn
                    >
                    <v-btn
                      block
                      @click="(modalemail = true), (serialisation = { ...item })"
                      small
                      tile
                      color="btnColorEmail"
                      >mail</v-btn
                    >
                  </v-col>

                  <v-col cols="6">
                    <v-row>
                      <v-col>
                        <span>{{ item.serial_1 }}-{{ item.entity_id }}-{{ item.serial_2 }}</span>
                        <v-divider />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <pre style="max-height: 150px; max-width: 100%; overflow: auto; white-space: pre-line">
                          {{ item.notes }}
                        </pre>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="px-0" cols="2">
                    <v-row>
                      <v-col cols="5"><span>Product:</span></v-col>
                      <v-col cols="7">{{ item.product }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>Start:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.start_date | moment($store.getters.dateFormat) }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>Validity:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.validity }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>PeriodACC:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.period_acc }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>PeriodTSH:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.period_tsh }}</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="1">
                    <v-row>
                      <v-col cols="5">TSH:</v-col>
                      <v-col cols="7">{{ item.users_tsh }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>FRM:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.users_frm }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>CSW:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.users_csv }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="5"><span>APP:</span></v-col>
                      <v-col class="pt-0" cols="7">{{ item.users_app }}</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2">
                    <v-row>
                      <v-col>
                        <v-icon
                          class="mb-1"
                          small
                          v-bind:color="item.mod_invoicing ? 'btnColorConfirm' : 'btnColorDelete'"
                        >
                          {{ mdiCircle }}
                        </v-icon>
                        <span> Invoicing</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-icon
                          class="mb-1"
                          small
                          v-bind:color="item.mod_planning ? 'btnColorConfirm' : 'btnColorDelete'"
                        >
                          {{ mdiCircle }}
                        </v-icon>
                        <span> Planning</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-icon
                          class="mb-1"
                          small
                          v-bind:color="item.mod_international ? 'btnColorConfirm' : 'btnColorDelete'"
                        >
                          {{ mdiCircle }}
                        </v-icon>
                        <span> International</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-icon
                          class="mb-1"
                          small
                          v-bind:color="item.mod_webServices ? 'btnColorConfirm' : 'btnColorDelete'"
                        >
                          {{ mdiCircle }}
                        </v-icon>
                        <span> WEB services</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-1">
        <v-col cols="4">
          <span>
            <v-btn
              v-if="!$store.getters.selectedClient.id"
              @click.stop="newSerialisation"
              block
              tile
              disabled
              color="btnColorNew"
            >
              NEW (Select client)
            </v-btn>
          </span>
          <v-btn
            v-if="$store.getters.userSettings.accSerialisations.write && $store.getters.selectedClient.id != null"
            @click.stop="newSerialisation"
            block
            tile
            color="btnColorNew"
          >
            NEW
          </v-btn>
        </v-col>
        <v-col cols="6" />
        <v-col cols="2">
          <v-switch
            v-model="showClosed"
            label="Closed"
            class="ma-0"
            color="btnColorCancel"
            @change="loadSerialisations"
            hide-details
          />
          <!--v-btn block tile class="mb-1" color="btnColorReport" @click="getReport">Report</v-btn-->
        </v-col>
      </v-row>
    </v-col>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="modalEdit" width="70%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Serialisation:
          <v-spacer />
          <v-icon large @click="modalEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="serialisationForm">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-bind:disabled="serialisation.id != 0 && !$store.getters.userSettings.accSerialisations.update"
                  v-model="serialisation.entity_id"
                  maxlength="60"
                  :counter="60"
                  :rules="[rules.required]"
                  label="Entity:"
                  required
                  hide-details
                />
                <v-text-field v-model="serialisation.client_serial" label="Client Serial:" disabled hide-details />
                <v-select
                  v-model="serialisation.product"
                  :rules="[rules.required]"
                  :items="productItems"
                  label="Product:"
                  hide-details
                />
                <v-select
                  v-model="serialisation.version"
                  :rules="[rules.required]"
                  :items="versionItems"
                  label="Version:"
                  hide-details
                />
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Start date:"
                      :append-icon="mdiCalendar"
                      @blur="serialisation.start_date = parseDate(dateFormatted)"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="serialisation.start_date" no-title @input="dateMenu = false"></v-date-picker>
                </v-menu>
                <v-text-field
                  v-model.number="serialisation.validity"
                  type="text"
                  maxlength="6"
                  :counter="6"
                  :rules="[rules.required, rules.number]"
                  label="Validity:"
                  hide-details
                />
                <v-text-field v-model="serialisation.serial_1" label="Serial1:" disabled hide-details />
                <v-text-field v-model="serialisation.serial_2" label="Serial1:" disabled hide-details />
              </v-col>

              <v-col cols="6">
                <v-row>
                  <v-col cols="6">
                    <v-switch
                      v-model="serialisation.mod_invoicing"
                      label="Invoicing:"
                      color="btnColorConfirm"
                      hide-details
                    />
                    <v-switch
                      v-model="serialisation.mod_webServices"
                      label="WEB Services:"
                      color="btnColorConfirm"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                      v-model="serialisation.mod_planning"
                      label="Planning:"
                      color="btnColorConfirm"
                      hide-details
                    />
                    <v-switch
                      v-model="serialisation.mod_international"
                      label="International:"
                      color="btnColorConfirm"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model.number="serialisation.period_acc"
                      type="text"
                      maxlength="2"
                      hide-details
                      :rules="[rules.number]"
                      label="ACC period:"
                    />
                    <v-text-field
                      v-model.number="serialisation.period_tsh"
                      type="text"
                      maxlength="2"
                      hide-details
                      :rules="[rules.number]"
                      label="TSH period:"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model.number="serialisation.users_tsh"
                      type="text"
                      maxlength="4"
                      hide-details
                      :rules="[rules.number]"
                      label="Users Timesheets:"
                    />
                    <v-text-field
                      v-model.number="serialisation.users_frm"
                      type="text"
                      maxlength="4"
                      hide-details
                      :rules="[rules.number]"
                      label="Users Forms:"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model.number="serialisation.users_csv"
                      type="text"
                      maxlength="4"
                      hide-details
                      :rules="[rules.number]"
                      label="Users CSW:"
                    />
                    <v-text-field
                      v-model.number="serialisation.users_app"
                      type="text"
                      maxlength="4"
                      hide-details
                      :rules="[rules.number]"
                      label="Users APP:"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea v-model="serialisation.notes" label="Notes" outlined rows="8" no-resize hide-details />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-switch v-model="serialisation.closed" label="Closed:" color="red" hide-details class="my-0 mx-3" />
          <v-switch v-model="serialisationTemporary" label="Temporary:" color="blue" hide-details class="my-0 mx-3" />
          <v-btn
            v-if="$store.getters.userSettings.accSerialisations.update"
            @click="editSerialisation"
            tile
            color="btnColorEdit"
            >EDIT</v-btn
          >
          <v-spacer></v-spacer>
          <h3 class="px-2 pb-1 red--text">{{ modalEditMessage }}</h3>
          <v-btn tile @click="modalConfirmSave = true" color="btnColorConfirm">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalConfirmSave" max-width="40vw">
      <v-card>
        <v-card-title class="headline blue lighten-3" primary-title>Save serialisation:</v-card-title>
        <v-card-text>
          <h3>Do you really want save Serialisation??</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="btnColorConfirm" @click="saveSerialisation">Save</v-btn>
          <v-btn tile color="btnColorCancel" @click="modalConfirmSave = false">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalemail" max-width="980">
      <NotifyForm :sourceData="serialisation" @modalemail="modalemail = false" />
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import NotifyForm from '@/components/clients/notifyForm.vue';
import tableMixin from '../../mixins/table';
import dateFormatMixin from '../../mixins/dateFormat';
import { mdiMagnify, mdiLinkLock, mdiAlertCircle, mdiCircle, mdiClose, mdiCalendar } from '@mdi/js';

export default Vue.extend({
  name: 'tableSerialisations',
  mixins: [tableMixin, dateFormatMixin],
  components: {
    NotifyForm,
  },
  data: () => ({
    tableData: [],
    search: '',
    closed: false,
    modalEdit: false,
    modalEditMessage: '',
    modalConfirmSave: false,
    modalemail: false,
    validForm: false,
    dateFormatted: '',
    dateMenu: false,
    serialisationTemporary: false,
    showClosed: false,
    serialisation: {
      id: 0,
      client_id: 0,
      entity_id: '',
      client_serial: '',
      client_name: '',
      serial_1: '',
      serial_2: '',
      start_date: new Date().toISOString().substr(0, 10),
      end_date: null,
      validity: 0,
      mod_invoicing: false,
      mod_planning: false,
      mod_international: false,
      mod_webServices: false,
      product: 'TAW',
      version: '6.0.2',
      period_tsh: 0,
      period_acc: 0,
      users_tsh: 0,
      users_frm: 0,
      users_csv: 0,
      users_app: 0,
      closed: false,
      notes: '',
      date_created: '',
      bcStatus: true,
    },
    productItems: ['TAW', 'EXP', 'FRM'],
    versionItems: ['6.0.2'],
    headers: [
      { text: 'Entity', value: 'entity_id', width: '40%' },
      { text: 'Product', value: 'product', width: '10%' },
      { text: 'Start date', value: 'start_date', width: '10%' },
      { text: 'Validity', value: 'validity', width: '10%' },
      { text: 'End date', value: 'end_date', width: '10%' },
      { text: 'To Expire', value: 'days_toExpire', width: '10%' },
      { text: 'blockchain', value: 'bcStatus', width: '10%' },
    ],
    rules: {
      required: (value: any) => !!value || 'Required.',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'Must be numeric!!!';
      },
    },
    mdiMagnify: mdiMagnify,
    mdiLinkLock: mdiLinkLock,
    mdiAlertCircle: mdiAlertCircle,
    mdiCircle: mdiCircle,
    mdiClose: mdiClose,
    mdiCalendar: mdiCalendar,
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadSerialisations();
    },
    'serialisation.start_date'() {
      this.dateFormatted = this.$moment(this.serialisation.start_date).format(this.$store.getters.dateFormat);
    },
  },
  mounted() {
    this.loadSerialisations();
  },
  methods: {
    expireColorSet(item: any) {
      if (item.days_toExpire < 0) {
        return 'toExpireSerialisation';
      } else if (item.days_toExpire >= 0 && item.days_toExpire <= 30) {
        return 'toExpireSerialisation30days';
      } else {
        return '';
      }
    },
    newSerialisation() {
      const getDefaults: any = this.$options;
      this.serialisation = { ...getDefaults.data().serialisation };
      this.serialisation.client_serial = this.$store.getters.selectedClient.serial;
      this.serialisation.client_id = this.$store.getters.selectedClient.id;
      this.modalEditMessage = '';
      this.modalEdit = true;
    },
    saveSerialisation() {
      const form: any = this.$refs.serialisationForm;
      if (!form.validate()) {
        this.modalEditMessage = 'Form is not filled correctly!!!';
      } else {
        // If temporary serialisation is requested
        if (this.serialisationTemporary) {
          this.serialisation.id = 0;
          this.serialisation.closed = true;
        }
        if (this.serialisation.id === 0) {
          axios
            .post('api/v1/serialisations', this.serialisation)
            .then((response) => {
              this.loadSerialisations();
              this.modalEdit = false;
              this.modalConfirmSave = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .patch(`api/v1/serialisations/${this.serialisation.id}/close`)
            .then(() => {
              this.serialisation.id = 0;
              if (!this.serialisation.closed) {
                // in case of close switch just close serial
                this.saveSerialisation();
              } else {
                this.loadSerialisations();
                this.modalEdit = false;
                this.modalConfirmSave = false;
              }
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    editSerialisation() {
      // this.serialisation.end_date = null;
      const form: any = this.$refs.serialisationForm;
      if (form.validate() === true) {
        axios
          .put('api/v1/serialisations', this.serialisation)
          .then(() => {
            this.loadSerialisations();
            this.modalEdit = false;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    async loadSerialisations() {
      if (this.$store.getters.selectedClient.id != null) {
        await axios
          .get('api/v1/serialisations', {
            params: {
              client_id: this.$store.getters.selectedClient.id,
              closed: this.showClosed,
            },
          })
          .then((response) => {
            axios
              .get(`/api/v1/bc/serialisations/verify/${this.$store.getters.selectedClient.id}`)
              .then((bcResponse) => {
                for (const serialisation of response.data) {
                  for (const record of bcResponse.data) {
                    if (serialisation.id === record.recordID) {
                      serialisation.bcStatus = record.status;
                    }
                  }
                }
                this.tableData = response.data;
              })
              .catch((err) => {
                this.$store.dispatch('catchErrHandler', err);
              });
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .get('api/v1/serialisations', {
            params: {
              filter_top: 100,
              closed: this.closed,
            },
          })
          .then((response) => {
            for (const serialisation of response.data) {
              serialisation.bcStatus = true;
            }
            this.tableData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    getReport() {
      let row: any = {
        start_date: '',
        end_date: '',
      };
      for (row of this.tableData) {
        row.start_date = new Date(row.start_date).toISOString();
        row.end_date = new Date(row.end_date).toISOString();
      }
      axios
        .post(`/api/v1/templates/serialisations/serialisationsTableReport.html`, this.tableData)
        .then((response) => {
          window.open(`/api/v1/templates/${response.data.uuid}/pdf`);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
