import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTabs,{attrs:{"background-color":"tabsBackground","slider-color":"light-blue accent-2"}},[(_vm.$store.getters.userSettings.accUsers.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("users")]):_vm._e(),(_vm.$store.getters.userSettings.accNotifications.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("notifications")]):_vm._e(),(_vm.$store.getters.userSettings.accSerialisations.special)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("Change Log")]):_vm._e(),(_vm.$store.getters.userSettings.accUsers.read)?_c(VTabItem,[_c('SettingsTableUsers')],1):_vm._e(),(_vm.$store.getters.userSettings.accNotifications.read)?_c(VTabItem,[_c('SettingsAdditional')],1):_vm._e(),(_vm.$store.getters.userSettings.accSerialisations.special)?_c(VTabItem,[_c('TableChangeLog')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }