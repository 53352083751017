import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.showClients),expression:"showClients"}],staticClass:"clientsBackground",attrs:{"cols":"3"}},[_c('TableClients')],1),(
        _vm.$store.getters.userSettings.accSerialisations.read ||
        _vm.$store.getters.userSettings.accContacts.read ||
        _vm.$store.getters.userSettings.accAppLog.read ||
        _vm.$store.getters.userSettings.accSecrets.read ||
        _vm.$store.getters.userSettings.accClientNotes.read
      )?_c(VCol,[_c(VTabs,{attrs:{"background-color":"tabsBackground"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"icon":""},on:{"click":function($event){_vm.showClients = !_vm.showClients}}},[(_vm.showClients)?_c(VIcon,{attrs:{"large":"","color":"iconColorDefault"}},[_vm._v(_vm._s(_vm.mdiArrowLeftCircle))]):_c(VIcon,{attrs:{"large":"","color":"iconColorDefault"}},[_vm._v(_vm._s(_vm.mdiArrowRightCircle))])],1),(_vm.$store.getters.userSettings.accSerialisations.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("Serialisations")]):_vm._e(),(_vm.$store.getters.userSettings.accContacts.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("Contacts")]):_vm._e(),(_vm.$store.getters.userSettings.accAppLog.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("AppLog")]):_vm._e(),(_vm.$store.getters.userSettings.accSecrets.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("Secrets")]):_vm._e(),(_vm.$store.getters.userSettings.accClientNotes.read)?_c(VTab,{attrs:{"ripple":""}},[_vm._v("Notes")]):_vm._e(),_c(VTab,{attrs:{"ripple":""}},[_vm._v("Kanban")])],1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.$store.getters.userSettings.accSerialisations.read)?_c(VTabItem,[_c('TableSerialisations')],1):_vm._e(),(_vm.$store.getters.userSettings.accContacts.read)?_c(VTabItem,[_c('TableContacts')],1):_vm._e(),(_vm.$store.getters.userSettings.accAppLog.read)?_c(VTabItem,[_c('TableAppLog')],1):_vm._e(),(_vm.$store.getters.userSettings.accSecrets.read)?_c(VTabItem,[_c('ClientSecrets')],1):_vm._e(),(_vm.$store.getters.userSettings.accClientNotes.read)?_c(VTabItem,[_c('ClientNotes')],1):_vm._e(),_c(VTabItem,[_c('ClientKanban')],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }