<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="3">
          <v-text-field class="mr-3" v-model="webSite.name" hide-details label="Name" required />
        </v-col>
        <v-col cols="7">
          <v-text-field v-model="webSite.url" hide-details label="URL" required />
        </v-col>
        <v-col cols="2">
          <v-btn tile class="mt-2" block color="btnColorNew" @click="addURL">Add</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items="tableData"
            hide-default-header
            disable-pagination
            hide-default-footer
            class="elevation-1"
            height="385"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.url }}</td>
                <td v-if="$store.getters.userSettings.accHosting.update">
                  <v-icon left color="red" @click="deleteURL(item.id)">{{ mdiDeleteEmpty }}</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'TableKnowledgeBaseFiles',
  props: {
    server: Object,
  },
  data: () => ({
    tableData: [],
    webSite: {
      id: 0,
      hostingserverid: 0,
      name: '',
      url: '',
    },
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    'server.id'() {
      this.loadURLs();
    },
  },
  mounted() {
    this.loadURLs();
  },
  methods: {
    loadURLs() {
      if (this.server.id !== 0) {
        axios
          .get(`api/v1/hostingwebsites/${this.server.uid}`)
          .then((response) => {
            this.tableData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.tableData = [];
      }
    },
    addURL() {
      this.webSite.hostingserverid = this.server.id;
      axios
        .post(`/api/v1/hostingwebsites`, this.webSite)
        .then(() => {
          this.loadURLs();
          const getDefaults: any = this.$options;
          this.webSite = { ...getDefaults.data().webSite };
          this.$emit('update-links', true);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteURL(itemID: any) {
      axios
        .delete(`/api/v1/hostingwebsites/${itemID}`)
        .then((response) => {
          this.loadURLs();
          this.$emit('update-links', true);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
