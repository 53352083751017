<template>
  <v-card>
    <v-card-title class="headline headerColorStandard"
      >My settings: &nbsp;&nbsp;&nbsp;{{ $store.getters.user }}
      <v-spacer />
      <v-icon large @click="$emit('update:openDialog', false)">{{ mdiClose }}</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field v-model="user.oldPassword" label="Old password:" type="password" required></v-text-field>
        <v-text-field v-model="user.newPassword" label="New password:" type="password" required></v-text-field>
        <v-text-field v-model="user.newPasswordConfirm" label="Confirm password:" type="password" required />
      </v-form>
      Password must be eight characters or longer <br />Password must contain at least 1 lowercase alphabetical
      character <br />Password must contain at least 1 uppercase alphabetical character <br />Password must contain at
      least 1 special character (#,%,@,....) <br />Password must contain at least 1 numeric character
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-switch v-model="userSettings.darkMode" label="Dark mode" @change="changeSettings" hide-details />
      <v-btn class="ml-3 mt-3" color="btnColorCancel" small @click="loadMobileQR"> show mobile QR code </v-btn>
      <v-spacer />
      <h3 class="px-2 pb-1 red--text">{{ modalMessage }}</h3>
      <v-btn tile color="btnColorConfirm" @click="changePassword">Update password</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogQRcode" width="600px">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          QRcode:
          <v-spacer />
          <v-icon large @click="dialogQRcode = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text> <img :src="`data:image/png;base64, ${qrToken}`" /> </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'change-password',
  props: {
    openDialog: Boolean,
  },
  data: () => ({
    modalMessage: '',
    qrToken: '',
    dialogQRcode: false,
    userSettings: {
      darkMode: false,
    },
    user: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    mdiClose: mdiClose,
  }),
  mounted() {
    this.userSettings.darkMode = this.$store.state.userSettings.darkMode;
  },
  methods: {
    loadMobileQR() {
      axios
        .get('/api/v1/user/mobileqr')
        .then((response) => {
          this.qrToken = response.data.token;
          this.dialogQRcode = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    changeSettings() {
      axios
        .put('/api/v1/user/settings', this.userSettings)
        .then(() => {
          this.$store.dispatch('getUserSettings');
          this.$vuetify.theme.dark = this.userSettings.darkMode;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    changePassword() {
      const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
      if (this.user.newPassword === this.user.newPasswordConfirm) {
        if (strongRegex.test(this.user.newPassword) === false) {
          this.modalMessage = 'Password does not meet security policy';
          return;
        }
        this.user.newPasswordConfirm = '';
        axios
          .post('/api/v1/password', this.user)
          .then((response) => {
            switch (response.data.message) {
              case 'wrongOldPassword':
                this.modalMessage = 'Old Password is incorrect';
                break;
              default:
                this.user.oldPassword = '';
                this.user.newPassword = '';
                this.user.newPasswordConfirm = '';
                this.modalMessage = '';
                this.$emit('update:openDialog', false);
            }
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
            this.$emit('update:openDialog', false);
          });
      } else {
        this.modalMessage = 'Password and Confirm password does not match';
      }
    },
  },
});
</script>
