import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardTitle,{staticClass:"headline headerColorStandard"},[_vm._v("My settings:    "+_vm._s(_vm.$store.getters.user)+" "),_c(VSpacer),_c(VIcon,{attrs:{"large":""},on:{"click":function($event){return _vm.$emit('update:openDialog', false)}}},[_vm._v(_vm._s(_vm.mdiClose))])],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"px-0"},[_c(VDataTable,{staticClass:"tableCursor elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"item-key":"id","group-by":"app","show-group-by":"","expanded":_vm.expandedRow,"height":_vm.$store.getters.windowSize.y - 260,"fixed-header":"","disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:`group.summary`,fn:function(item){return [_c('tr',[_c('td',[_vm._v("Time summary: "+_vm._s(_vm.sumTimeSpend(item)))])])]}},{key:"item",fn:function({ item }){return [_c('tr',{class:{ rowColorSelected: item.id == _vm.selectedRowID },on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.timeEnd,_vm.$store.getters.dateTimeShortFormat)))]),_c('td',[_vm._v(_vm._s(_vm.toHHMMSS(item.timeSpend)))]),_c('td',[_vm._v(_vm._s(item.app))]),_c('td',[_vm._v(_vm._s(item.device))])])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"pa-3 tableSlotBackground",attrs:{"colspan":headers.length}},[_c(VDataTable,{staticClass:"tableCursor",attrs:{"headers":_vm.headersActions,"items":item.actions,"height":190,"fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.timeEnd,_vm.$store.getters.dateTimeShortFormat)))]),_c('td',[_vm._v(_vm._s(_vm.toHHMMSS(item.timeSpend)))]),_c('td',[_vm._v(_vm._s(item.text))])])]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }