<template>
  <v-row>
    <v-col class="pb-0">
      <v-row
        align="center"
        style="height: 46px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
        class="ma-0 tabsBackground"
      >
        <h2 style="overflow: hidden; text-overflow: ellipsis" class="tabsBackground">
          <span class="pl-1" v-if="$store.getters.selectedClient.name === undefined">Select Client:</span>
          <span v-else class="pl-1">{{ $store.getters.selectedClient.name }}</span>
        </h2>
      </v-row>

      <v-row>
        <v-col class="py-2">
          <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details dense />
          <div style="min-height: 2px"></div>

          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 238"
            :search="search"
            @click:row="rowClicked"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td>{{ item.name }}</td>
                <td>{{ item.serial }}</td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <tr>
                <td class="tableSlotBackground" :colspan="headers.length">
                  <v-spacer class="py-2" />
                  <v-row v-for="item in clientProducts" :key="item.id">
                    <v-col class="py-0" cols="5">{{ item.productName }}</v-col>
                    <v-col class="py-0" cols="4">{{ item.subproductName }}</v-col>
                    <v-col class="py-0" cols="3">{{ item.versionName }}</v-col>
                  </v-row>
                  <v-spacer class="py-2" />
                  <v-divider />
                  <v-row>
                    <v-col> Serial: {{ item.serial }} </v-col>
                  </v-row>
                  <v-divider />
                  <v-row>
                    <v-col>
                      <pre style="max-height: 150px; max-width: 100%; overflow: auto; white-space: pre-line">
                        {{ item.notes }}
                      </pre>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-btn v-if="$store.getters.userSettings.accClients.write" block tile color="btnColorNew" @click="newClient"
            >NEW</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-btn
            v-if="$store.getters.userSettings.accClients.update && $store.getters.selectedClient.id != null"
            @click="editClient"
            block
            tile
            color="btnColorEdit"
            >Edit</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-switch class="ma-0" v-model="closed" label="closed" color="red" @change="loadClients" hide-details />
        </v-col>
      </v-row>
    </v-col>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="modalEdit" width="980">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Client: <span v-if="client.azuid"> (AZUID: {{ client.azuid }}) </span>
          <v-spacer />
          <v-icon large @click="modalEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-form v-model="validForm" ref="clientForm">
                <v-text-field
                  v-model="client.name"
                  :rules="[rules.required]"
                  :counter="60"
                  maxlength="60"
                  label="Name"
                  required
                />
                <v-text-field
                  v-model="client.code"
                  :rules="[rules.required]"
                  :counter="60"
                  maxlength="60"
                  label="Code"
                  required
                />
                <v-text-field v-model="client.serial" hide-details label="Serial" disabled></v-text-field>
                <v-textarea v-model="client.notes" label="Notes" hint="Aditional client notes" no-resize />
              </v-form>
            </v-col>
            <v-col v-if="client.id > 0" cols="6">
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="product.idProduct"
                    label="Product"
                    :items="listProducts"
                    item-text="shortName"
                    item-value="id"
                    v-on:change="loadSubproducts"
                    hide-details
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="product.idSubproduct"
                    label="Subproduct"
                    :items="listSubproducts"
                    item-text="shortName"
                    item-value="id"
                    :disabled="listSubproducts.length == 0"
                    hide-details
                  />
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="product.idVersion"
                    label="Version"
                    :items="listVersions"
                    item-text="version"
                    item-value="id"
                    :disabled="!product.idProduct"
                    hide-details
                  />
                </v-col>
                <v-col class="pr-2" cols="2">
                  <v-btn @click="addClientProduct" color="btnColorConfirm" rounded class="mt-3">
                    <v-icon>{{ mdiPlus }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersProducts"
                    :items="clientProducts"
                    item-key="id"
                    :height="320"
                    fixed-header
                    dense
                    disable-pagination
                    hide-default-footer
                    class="tableCursor"
                  >
                    <template v-slot:item="{ item }">
                      <tr style="max-width: 100%">
                        <td width="30%">{{ item.productName }}</td>
                        <td width="30%">{{ item.subproductName }}</td>
                        <td width="30%">
                          <v-edit-dialog
                            :return-value.sync="item.versionName"
                            @open="loadVersions(item.idProduct)"
                            @close="changeProductVersion(item)"
                          >
                            {{ item.versionName }}
                            <template v-slot:input>
                              <v-sheet height="60" width="120">
                                <v-autocomplete
                                  v-model="item.idVersion"
                                  label="Version"
                                  :items="listVersions"
                                  item-text="version"
                                  item-value="id"
                                  hide-details
                                />
                              </v-sheet>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td width="10%">
                          <v-btn icon @click="(confirmDeleteDialog = true), (deleteProductID = item.id)">
                            <v-icon color="red">{{ mdiDeleteEmpty }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-checkbox class="my-0 ml-3" v-model="client.closed" label="Closed" color="red" hide-details></v-checkbox>
          <v-spacer></v-spacer>
          <h3 class="px-2 pb-1 red--text">{{ modalEditMessage }}</h3>
          <v-btn tile color="btnColorConfirm" @click="saveClient">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete product?"
      body="Do you really want delete product?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteClientProduct"
    />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiMagnify, mdiClose, mdiPlus, mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'tableClients',
  components: { ConfirmDialog },
  data: () => ({
    tableData: [],
    search: '',
    expandedRow: [] as any,
    selectedRowID: 0,
    modalEdit: false,
    modalEditMessage: '',
    confirmDeleteDialog: false,
    deleteProductID: 0,
    validForm: false,
    versionItems: [],
    listProducts: [] as any,
    listSubproducts: [] as any,
    listVersions: [] as any,
    closed: false, // Clients - request only closed rows
    clientProducts: [] as any,
    product: {
      id: 0,
      idClient: 0,
      idProduct: 0,
      idSubproduct: 0,
      idVersion: 0,
    },
    client: {
      id: 0,
      name: '',
      code: '',
      serial: '',
      notes: '',
      closed: false,
      azuid: '',
    },
    rules: {
      required: (value: any) => !!value || 'Required.',
    },
    headers: [
      { text: 'Client', value: 'name', width: '70%' },
      { text: 'Serial', value: 'serial', width: '30%' },
    ],
    headersProducts: [
      { text: 'Product', value: 'productName', width: '40%' },
      { text: 'Subproduct', value: 'subproductName', width: '30%' },
      { text: 'Version', value: 'versionName', width: '20%' },
      { text: '', value: '', width: '1%' },
    ],
    mdiMagnify: mdiMagnify,
    mdiClose: mdiClose,
    mdiPlus: mdiPlus,
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    search() {
      if (this.search === null || this.search === '') {
        this.$store.commit('selectedClient', {});
      }
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'clients') {
        this.loadClients();
        this.$store.commit('reloadTable', '');
      }
      if (this.$store.getters.reloadTable.name === 'updateProducts') {
        this.loadClientProducts(this.selectedRowID);
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadClients();
    this.loadProducts();
  },
  methods: {
    loadClients() {
      axios
        .get('api/v1/clients', {
          params: {
            closed: this.closed,
          },
        })
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    newClient() {
      this.expandedRow.length > 0 ? this.rowClicked(this.expandedRow[0]) : ''; // Close expanded row
      this.product = {
        id: 0,
        idClient: 0,
        idProduct: 0,
        idSubproduct: 0,
        idVersion: 0,
      };
      const getDefaults: any = this.$options;
      this.client = { ...getDefaults.data().client };
      this.clientProducts = [];
      axios
        .get('api/v1/clientuniqueserial')
        .then((response) => {
          this.client.serial = response.data.unique_serial;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
      this.modalEdit = true;
    },
    editClient() {
      this.product = {
        id: 0,
        idClient: 0,
        idProduct: 0,
        idSubproduct: 0,
        idVersion: 0,
      };
      this.client = { ...this.$store.getters.selectedClient };
      this.modalEdit = true;
    },
    saveClient() {
      const form: any = this.$refs.clientForm;
      if (form.validate() === true) {
        if (this.client.id === 0) {
          // If new Client go to PUT
          axios
            .post('api/v1/clients', this.client)
            .then((response) => {
              this.client.id = response.data.newid;
              this.loadClients();
              this.$store.commit('selectedClient', this.client);
              // this.modalEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('api/v1/clients', this.client)
            .then(() => {
              this.loadClients();
              this.$store.commit('selectedClient', this.client);
              this.modalEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      } else {
        this.modalEditMessage = 'Form is not filled correctly!!!';
      }
    },
    addClientProduct() {
      this.product.idClient = this.client.id;
      axios
        .post('api/v1/clientproducts', this.product)
        .then(() => {
          this.loadClientProducts(this.client.id);
          this.product = {
            id: 0,
            idClient: 0,
            idProduct: 0,
            idSubproduct: 0,
            idVersion: 0,
          };
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadClientProducts(id: number) {
      axios
        .get(`api/v1/clientproducts/${id}`)
        .then((response) => {
          this.clientProducts = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    changeProductVersion(item: any) {
      axios
        .patch(`api/v1/clientproducts/`, item)
        .then(() => {
          this.loadClientProducts(this.client.id);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteClientProduct() {
      axios
        .delete(`api/v1/clientproducts/${this.deleteProductID}`)
        .then(() => {
          this.loadClientProducts(this.client.id);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadProducts() {
      axios
        .get(`api/v1/pm/product`)
        .then((response) => {
          this.listProducts = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadSubproducts(requestid: number) {
      this.product.idSubproduct = 0;
      this.loadVersions(requestid);
      axios
        .get(`api/v1/pm/subproduct/${requestid}`)
        .then((response) => {
          this.listSubproducts = response.data;
          this.listSubproducts.length > 0 ? (this.product.idSubproduct = this.listSubproducts[0].id) : '';
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadVersions(requestid: number) {
      axios
        .get(`api/v1/pm/version/${requestid}`)
        .then((response) => {
          for (const version of response.data) {
            if (version.released === false) {
              version.version = version.version + ' - NOT released';
            }
          }
          this.listVersions = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    rowClicked(value: any) {
      if (this.expandedRow.length > 0) {
        if (value.id === this.expandedRow[0].id) {
          this.expandedRow = [{}];
          this.selectedRowID = 0;
        } else {
          this.expandedRow = [value];
          this.selectedRowID = value.id;
          this.loadClientProducts(value.id);
        }
      } else {
        this.expandedRow.push(value);
        this.selectedRowID = value.id;
        this.loadClientProducts(value.id);
      }
      this.$store.commit('selectedClient', this.expandedRow[0]);
    },
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  /* .fade-leave-active below version 2.1.8 */
  opacity: 0;
}
</style>
