/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('sw ready');
    },
    registered() {
      console.log('sw registered');
    },
    cached() {
      console.log('sw cached');
    },
    updatefound() {
      console.log('sw update');
    },
    updated() {
      console.log('sw refresh needed');
      alert('Site has been updated - reload needed');
      document.location.href = '/clear';
      // window.location.reload();
    },
    offline() {
      console.log('sw offLine');
    },
    error(error) {
      console.error('sw register error:', error);
    },
  });
}
