<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-col cols="2">
        <v-navigation-drawer :height="$store.state.windowSize.y - 50" class="sideBarBackground" width="100%" permanent>
          <v-list>
            <v-list-item @click="swapComponent('bugtrack')" v-bind:class="{ primary: currentComponent == 'bugtrack' }">
              <v-list-item-icon>
                <v-icon>{{ mdiBug }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>BugTrack</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="swapComponent('knowledgebase')"
              v-bind:class="{ primary: currentComponent == 'knowledgebase' }"
            >
              <v-list-item-icon>
                <v-icon>{{ mdiBookOpenPageVariant }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Knowledgebase</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="swapComponent('products')" v-bind:class="{ primary: currentComponent == 'products' }">
              <v-list-item-icon>
                <v-icon>{{ mdiFloppy }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Products</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="swapComponent('enhancements')"
              v-bind:class="{ primary: currentComponent == 'enhancements' }"
            >
              <v-list-item-icon>
                <v-icon>{{ mdiHeadPlus }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Enhancements</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>

      <v-col cols="10">
        <div :is="currentComponent" :swap-component="swapComponent" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Products from '@/components/proman/products.vue';
import Enhancements from '@/components/proman/enhancements.vue';
import Knowledgebase from '@/components/proman/knowledgebase.vue';
import Bugtrack from '@/components/proman/bugtrack.vue';
import { mdiHeadPlus, mdiBookOpenPageVariant, mdiBug, mdiFloppy } from '@mdi/js';

export default Vue.extend({
  name: 'AzureView',
  components: {
    enhancements: Enhancements,
    products: Products,
    knowledgebase: Knowledgebase,
    bugtrack: Bugtrack,
  },
  data: () => ({
    currentComponent: 'bugtrack',
    mdiHeadPlus: mdiHeadPlus,
    mdiBookOpenPageVariant: mdiBookOpenPageVariant,
    mdiBug: mdiBug,
    mdiFloppy: mdiFloppy,
  }),
  methods: {
    swapComponent(component: string) {
      this.currentComponent = component;
    },
  },
});
</script>
