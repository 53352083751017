import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#FFFFFF',
        primary: '#90CAF9',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        toExpireSerialisation: '#FE7777',
        toExpireSerialisation30days: '#DBB75B',
        sideBarBackground: '#EBEBEB',
        tabsBackground: '#EBEBEB',
        clientsBackground: '#F1F1F1',
        rowColorSelected: '#A2D6F5',
        tableSlotBackground: '#EBEBEB',
        btnColorNew: '#95EDBB',
        btnColorEdit: '#DBBDE5',
        btnColorConfirm: '#95EDBB',
        btnColorCancel: '#F6D3AF',
        btnColorReport: '#FDDDAA',
        btnColorEmail: '#BAD8F0',
        btnColorDelete: '#EC6F86',
        iconColorDefault: '#858585',
        snackSuccess: '#81D280',
        headerColorStandard: '#90CAF9',
        headerColorDelete: '#EC6F86',
        headerKanban: '#DDDDDD',
        kanbanColor1: '#BAE1FF',
        kanbanColor2: '#BAFFC9',
        kanbanColor3: '#FFFFBA',
        kanbanColor4: '#FFDFBA',
        kanbanColor5: '#FFB3BA',
        kanbanColor6: '#ff1100',
        kanbanColor7: '#00FF33',
        kanbanColor8: '#0091FF',
      },
      dark: {
        background: '#1E1E1E',
        primary: '#33404C',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        toExpireSerialisation: '#48252C',
        toExpireSerialisation30days: '#655939',
        sideBarBackground: '#181A1B',
        tabsBackground: '#2D2D2D',
        clientsBackground: '#333333',
        rowColorSelected: '#404449',
        tableSlotBackground: '#2D2D2D',
        btnColorNew: '#7A9D90',
        btnColorEdit: '#8F6F99',
        btnColorConfirm: '#7A9D90',
        btnColorCancel: '#AD7D69',
        btnColorReport: '#D09292',
        btnColorEmail: '#5F7B90',
        btnColorDelete: '#B05959',
        iconColorDefault: '#C2C2C2',
        snackSuccess: '#81D280',
        headerColorStandard: '#3A5061',
        headerColorDelete: '#B05959',
        headerKanban: '#3A434B',
        kanbanColor1: '#94B4CC',
        kanbanColor2: '#6F9977',
        kanbanColor3: '#99996F',
        kanbanColor4: '#CCB294',
        kanbanColor5: '#CC8F94',
        kanbanColor6: '#FF1100',
        kanbanColor7: '#00c928',
        kanbanColor8: '#0091FF',
      },
    },
  },
  icons: {
    // https://vuetifyjs.com/en/features/icon-fonts/#material-design-icons---js-svg
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
