<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="products"
          :height="$store.getters.windowSize.y - 130"
          fixed-header
          disable-pagination
          hide-default-footer
          @click:row="openEditProduct"
          class="tableSelectCursor"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-btn
          v-if="$store.getters.userSettings.accProducts.write"
          tile
          block
          color="btnColorNew"
          @click="openNewProduct"
        >
          New
        </v-btn>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogNewProduct" width="60%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          New product:
          <v-spacer />
          <v-icon large @click="dialogNewProduct = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field v-model="product.name" maxlength="255" label="Name:" hide-details />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="product.shortName" maxlength="8" label="Short name:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="product.note" label="Note:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="postProduct">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditProduct" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Product: {{ product.name }}
          <v-spacer />
          <v-icon large @click="dialogEditProduct = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-sheet style="overflow-y: auto; overflow-x: hidden" :height="$store.getters.windowSize.y - 120">
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="8">
                    <v-text-field v-model="product.name" maxlength="255" label="Name:" hide-details />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="product.shortName" maxlength="15" label="Short name:" hide-details />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea v-model="product.note" rows="4" label="Note:" hide-details dense />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      v-if="$store.getters.userSettings.accProducts.update"
                      tile
                      block
                      small
                      color="btnColorConfirm"
                      @click="putProduct"
                    >
                      Save changes
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <Subproducts :product="product" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <Versions :product="product" />
              </v-col>
              <v-col cols="8">
                <Groups :product="product" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="$store.getters.userSettings.accProducts.delete"
            tile
            color="btnColorDelete"
            @click="confirmDeleteProduct = true"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete Product?"
      body="Do you really want delete Product?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteProduct"
      v-on:confirm="deleteProduct"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import Subproducts from '@/components/proman/productsSubproducts.vue';
import Versions from '@/components/proman/productsVersions.vue';
import Groups from '@/components/proman/productsGroups.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'pmProducts',
  components: { ConfirmDialog, Subproducts, Versions, Groups },
  data: () => ({
    products: [],
    subproducts: [],
    dialogNewProduct: false,
    dialogEditProduct: false,
    confirmDeleteProduct: false,
    product: {
      id: 0,
      name: '',
      shortName: '',
      note: '',
    },
    headers: [
      { text: 'Product', value: 'name', width: '300' },
      { text: 'Short name', value: 'shortName', width: '200' },
      { text: 'Note', value: 'note' },
    ],
    mdiClose: mdiClose,
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      axios
        .get(`api/v1/pm/product`)
        .then((response) => {
          this.products = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewProduct() {
      this.product.id = 0;
      this.product.name = '';
      this.product.shortName = '';
      this.product.note = '';
      this.dialogNewProduct = true;
    },
    postProduct() {
      axios
        .post(`api/v1/pm/product`, this.product)
        .then(() => {
          this.loadProducts();
          this.dialogNewProduct = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openEditProduct(item: any) {
      this.product = { ...item };
      this.dialogEditProduct = true;
    },
    putProduct() {
      axios
        .put(`api/v1/pm/product`, this.product)
        .then(() => {
          this.loadProducts();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteProduct() {
      axios
        .delete(`api/v1/pm/product/${this.product.id}`)
        .then(() => {
          this.loadProducts();
          this.dialogEditProduct = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
