<template>
  <v-container fluid>
    <v-row>
      <v-col v-show="showClients" cols="3" class="clientsBackground">
        <TableClients />
      </v-col>
      <v-col
        v-if="
          $store.getters.userSettings.accSerialisations.read ||
          $store.getters.userSettings.accContacts.read ||
          $store.getters.userSettings.accAppLog.read ||
          $store.getters.userSettings.accSecrets.read ||
          $store.getters.userSettings.accClientNotes.read
        "
      >
        <v-tabs v-model="tab" background-color="tabsBackground">
          <v-btn class="mt-2" icon @click="showClients = !showClients">
            <v-icon large v-if="showClients" color="iconColorDefault">{{ mdiArrowLeftCircle }}</v-icon>
            <v-icon large v-else color="iconColorDefault">{{ mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-tab v-if="$store.getters.userSettings.accSerialisations.read" ripple>Serialisations</v-tab>
          <v-tab v-if="$store.getters.userSettings.accContacts.read" ripple>Contacts</v-tab>
          <v-tab v-if="$store.getters.userSettings.accAppLog.read" ripple>AppLog</v-tab>
          <v-tab v-if="$store.getters.userSettings.accSecrets.read" ripple>Secrets</v-tab>
          <v-tab v-if="$store.getters.userSettings.accClientNotes.read" ripple>Notes</v-tab>
          <v-tab ripple>Kanban</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-if="$store.getters.userSettings.accSerialisations.read">
            <TableSerialisations />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.userSettings.accContacts.read">
            <TableContacts />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.userSettings.accAppLog.read">
            <TableAppLog />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.userSettings.accSecrets.read">
            <ClientSecrets />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.userSettings.accClientNotes.read">
            <ClientNotes />
          </v-tab-item>
          <v-tab-item>
            <ClientKanban />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import TableClients from '@/components/clients/tableClients.vue';
import TableSerialisations from '@/components/clients/tableSerialisations.vue';
import TableContacts from '@/components/clients/tableContacts.vue';
import TableAppLog from '@/components/clients/tableAppLog.vue';
import ClientNotes from '@/components/clients/clientNotes.vue';
import ClientSecrets from '@/components/clients/clientSecrets.vue';
import ClientKanban from '@/components/clients/clientKanban.vue';
import { mdiArrowLeftCircle, mdiArrowRightCircle } from '@mdi/js';

export default Vue.extend({
  name: 'viewClients',
  components: {
    TableClients,
    TableSerialisations,
    TableContacts,
    TableAppLog,
    ClientNotes,
    ClientSecrets,
    ClientKanban,
  },
  data: () => ({
    tab: 0,
    showClients: true,
    mdiArrowLeftCircle: mdiArrowLeftCircle,
    mdiArrowRightCircle: mdiArrowRightCircle,
  }),
  mounted() {},
  methods: {},
  beforeDestroy() {
    this.$store.commit('selectedClient', {});
  },
});
</script>
