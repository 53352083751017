<template>
  <v-row>
    <v-col>
      <v-row v-if="$store.getters.selectedClient.id != null">
        <v-col>
          <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
          <div style="min-height: 2px"></div>

          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 230"
            :search="search"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr @click="rowSelect(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td>{{ item.description }}</td>
                <td v-if="item.topSecret">
                  <v-icon color="green">{{ mdiShieldCheck }}</v-icon>
                </td>
                <td v-else></td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers }">
              <td class="tableSlotBackground" :colspan="headers.length">
                <v-row class="mx-0 my-1">
                  <v-col v-if="$store.getters.userSettings.accSecrets.update" cols="1">
                    <v-btn @click="dialogEdit = true" block small tile class="mb-2" color="btnColorNew"> View </v-btn>
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="4">
                    <v-row>
                      <v-col class="pt-0" cols="6">User name:</v-col>
                      <v-col class="pt-0" cols="6">
                        <v-btn
                          :disabled="secret.userName == ''"
                          color="btnColorNew"
                          tile
                          small
                          @click="copyToClipboard(secret.userName)"
                        >
                          <v-icon small>{{ mdiContentCopy }}</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="6">Secret:</v-col>
                      <v-col class="pt-0" cols="6">
                        <v-btn
                          :disabled="secret.secret == ''"
                          color="btnColorNew"
                          tile
                          small
                          @click="copyToClipboard(secret.secret)"
                        >
                          <v-icon small>{{ mdiContentCopy }}</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col class="pt-0">
                        <v-textarea
                          v-model="secret.note"
                          label="Note"
                          no-resize
                          disabled
                          rows="5"
                          hide-details
                          persistent-placeholder
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-1">
        <v-col cols="4">
          <span>
            <h3 v-if="!$store.getters.selectedClient.id">Please select client</h3>
          </span>
          <v-btn
            v-if="$store.getters.userSettings.accSecrets.write && $store.getters.selectedClient.id != null"
            @click.stop="newSecret"
            tile
            block
            color="btnColorNew"
            >NEW</v-btn
          >
        </v-col>
      </v-row>
    </v-col>

    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogEdit" persistent width="70%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Secret:
          <v-spacer />
          <v-icon large @click="(dialogEdit = false), (expandedRow = []), (selectedRowID = 0)">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="secretForm">
            <v-row class="py-0">
              <v-col>
                <v-text-field
                  v-model="secret.description"
                  maxlength="256"
                  label="Description:"
                  :rules="[rules.required]"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col>
                <v-text-field v-model="secret.userName" maxlength="256" label="User name:" hide-details />
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="10">
                <v-text-field
                  v-model="secret.secret"
                  :append-icon="mdiLockReset"
                  @click:append="loadGeneratedPassword"
                  maxlength="256"
                  label="Secret:"
                  hide-details
                />
              </v-col>
              <v-col cols="2" class="d-flex">
                <v-spacer />
                <v-checkbox v-model="secret.topSecret" label="Top secret" hide-details />
              </v-col>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col>
                <v-textarea v-model="secret.note" label="Note" rows="5" no-resize hide-details />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="secret.users"
                  label="Users that can access the secret"
                  :items="users"
                  item-text="fullName"
                  item-value="uuid"
                  :menu-props="{ minWidth: '500' }"
                  small-chips
                  eager
                  deletable-chips
                  multiple
                  dense
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeUser(data.item)"
                    >
                      <v-avatar v-if="data.item.picture" left>
                        <v-img :src="`data:image/png;base64, ${data.item.picture}`"></v-img>
                      </v-avatar>
                      {{ data.item.fullName }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="checkObject(data.item)">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar v-if="data.item.picture">
                        <img :src="`data:image/png;base64, ${data.item.picture}`" />
                      </v-list-item-avatar>
                      <v-list-item-avatar v-else>
                        <v-icon>{{ mdiAccount }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="secret.id > 0 && $store.getters.userSettings.accSecrets.delete"
            tile
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="
              ($store.getters.userSettings.accSecrets.write && secret.id == 0) ||
              $store.getters.userSettings.accSecrets.update
            "
            tile
            color="btnColorConfirm"
            @click="saveSecret"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOTP" persistent width="450">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Enter One Time Password:
          <v-spacer />
          <v-icon large @click="dialogOTP = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-otp-input
            ref="otpInput"
            v-model="otp"
            @finish="loadSecret"
            type="number"
            length="6"
            :autocomplete="Math.random()"
            :name="Math.random()"
          ></v-otp-input>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete secret?"
      body="Do you really want delete secret?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteSecret"
    />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiMagnify, mdiClose, mdiContentCopy, mdiShieldCheck, mdiLockReset, mdiAccount } from '@mdi/js';

export default Vue.extend({
  name: 'clientSecrets',
  components: { ConfirmDialog },
  data: () => ({
    dialogEdit: false,
    dialogOTP: false,
    tableData: [],
    expandedRow: [] as any,
    selectedRowID: 0,
    selectedItem: '' as any,
    otp: '',
    secret: {
      id: 0,
      clientID: 0,
      description: '',
      userName: '',
      secret: '',
      note: '',
      topSecret: false,
      users: [] as any,
    },
    confirmDeleteDialog: false,
    validForm: false,
    search: '',
    users: [],
    headers: [
      { text: 'Description', value: 'description', width: '90%' },
      { text: 'Top secret', value: 'topSecret' },
    ],
    rules: {
      required: (value: any) => !!value || 'Required.',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'Must be numeric!!!';
      },
    },
    mdiMagnify: mdiMagnify,
    mdiClose: mdiClose,
    mdiContentCopy: mdiContentCopy,
    mdiShieldCheck: mdiShieldCheck,
    mdiLockReset: mdiLockReset,
    mdiAccount: mdiAccount,
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadSecrets();
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'secrets') {
        this.loadSecrets();
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadSecrets();
    this.loadUsers();
  },
  methods: {
    rowSelect(value: any) {
      this.selectedItem = value;
      if (this.expandedRow.length > 0) {
        if (value.id === this.expandedRow[0].id) {
          this.rowChange(value);
        } else {
          value.topSecret ? this.openOTPdialog() : this.loadSecret();
        }
      } else {
        value.topSecret ? this.openOTPdialog() : this.loadSecret();
      }
    },
    openOTPdialog() {
      this.otp = '';
      this.dialogOTP = true;
      // TODO resolve focus
      // this.$nextTick(() => {
      // as HTMLInputElement
      // let otpIN = this.$refs.otpInput as any;
      // console.log(otpIN);
      // otpIN.focus();
      //});
    },
    rowChange(value: any) {
      if (this.expandedRow.length > 0) {
        if (value.id === this.expandedRow[0].id) {
          this.expandedRow = [];
          this.selectedRowID = 0;
        } else {
          this.expandedRow = [value];
          this.selectedRowID = value.id;
        }
      } else {
        this.expandedRow.push(value);
        this.selectedRowID = value.id;
      }
    },
    copyToClipboard(secret: string) {
      navigator.clipboard.writeText(secret);
    },
    loadSecrets() {
      if (this.$store.getters.selectedClient.id != null) {
        axios
          .get(`api/v1/clientsecrets/${this.$store.getters.selectedClient.id}`)
          .then((response) => {
            this.tableData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.tableData = [];
      }
    },
    loadGeneratedPassword() {
      axios
        .get(`api/v1/clientsecretgen`)
        .then((response) => {
          this.secret.secret = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadSecret() {
      this.dialogOTP = false;
      const getDefaults: any = this.$options;
      this.secret = { ...getDefaults.data().secret };
      axios
        .get(`api/v1/clientsecret/${this.selectedItem.id}`, {
          params: {
            otp: this.otp,
          },
        })
        .then((response) => {
          this.secret = response.data;
          this.rowChange(this.selectedItem);
        })
        .catch((err) => {
          this.expandedRow = [];
          this.selectedRowID = 0;
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    newSecret() {
      const getDefaults: any = this.$options;
      this.secret = { ...getDefaults.data().secret };
      this.secret.clientID = this.$store.getters.selectedClient.id;
      this.dialogEdit = true;
      this.$nextTick(function () {
        const form: any = this.$refs.secretForm;
        form.validate();
      });
    },
    saveSecret() {
      const form: any = this.$refs.secretForm;
      if (!form.validate()) {
        this.$store.commit('globalMessage', 'Form is not filled correctly!!!');
      } else {
        if (this.secret.id === 0) {
          axios
            .post('/api/v1/clientsecret', this.secret)
            .then(() => {
              this.loadSecrets();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('/api/v1/clientsecret', this.secret)
            .then(() => {
              this.loadSecrets();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    deleteSecret() {
      axios
        .delete(`/api/v1/clientsecret/${this.secret.id}`)
        .then(() => {
          this.loadSecrets();
          this.dialogEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadUsers() {
      axios
        .get('api/v1/usersbasic')
        .then((response) => {
          this.users = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    checkObject(item: any) {
      // workaround - prettier has a problem with formating if "typeof item ..." is used 20220408
      return typeof item !== 'object';
    },
    removeUser(item: any) {
      console.log('REM: ', item);
      const index = this.secret.users.indexOf(item.uuid);
      if (index >= 0) this.secret.users.splice(index, 1);
    },
  },
});
</script>
