import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VNavigationDrawer,{staticClass:"sideBarBackground",attrs:{"height":_vm.$store.state.windowSize.y - 50,"width":"100%","permanent":""}},[_c(VList,[(_vm.$store.getters.userSettings.accAzureGroups.read)?_c(VListItem,{class:{ primary: _vm.currentComponent == 'resourceGroups' },on:{"click":function($event){return _vm.swapComponent('resourceGroups')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiAccount))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Resource Groups")])],1)],1):_vm._e(),_c(VListItem,{class:{
              primary: _vm.currentComponent == 'tab1',
            },on:{"click":function($event){return _vm.swapComponent('tab1')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiAccountGroup))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Tab1")])],1)],1),_c(VListItem,{class:{
              primary: _vm.currentComponent == 'tab2',
            },on:{"click":function($event){return _vm.swapComponent('tab2')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiAccountMultipleCheck))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Tab2")])],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"10"}},[_c(_vm.currentComponent,{tag:"div",attrs:{"swap-component":_vm.swapComponent}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }