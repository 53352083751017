import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import store from '../store';
import router from '../router';
import db from '../db';
import version from '../../appVersion';
import VueNativeSock from 'vue-native-websocket';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    serverOnline: true,
    version,
    windowSize: {
      x: 0,
      y: 0,
    },
    dateFormat: 'DD.MM.YYYY',
    dateTimeShortFormat: 'DD.MM-HH:mm',
    globalMessage: '', // At the moment SessionExpired
    appNotifyMessage: '', // Notifications from app to user
    listSystemNotifications: [], // Notification from server
    user: '',
    selectedClient: {},
    reloadTable: '',
    wsUserMessage: {},
    wsOnlineUsers: {},
    reloadClients: false,
    socket: {
      isConnected: false,
      reconnectError: false,
      reconnectCount: 0,
    },
    userSettings: {
      fullName: '',
      userID: 0,
      admin: false,
      email: '',
      typeSpeed: 0,
      picture: '',
      darkMode: false,
      accClients: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accSerialisations: {
        read: false,
        write: false,
        update: false,
        delete: false,
        patch: false,
        special: false,
      },
      accKnowledgeBase: {
        read: false,
        write: false,
        update: false,
        delete: false,
        readFiles: false,
        writeFiles: false,
        downloadFiles: false,
        deleteFiles: false,
      },
      accContacts: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accUsers: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accAppLog: {
        read: false,
        write: false,
        update: false,
        delete: false,
        readFiles: false,
        writeFiles: false,
        downloadFiles: false,
        deleteFiles: false,
      },
      accProducts: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accNotifications: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accClientNotes: {
        read: false,
        update: false,
      },
      accHosting: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accSecrets: {
        read: false,
        write: false,
        update: false,
        delete: false,
      },
      accBugTrack: {
        read: false,
      },
      accAzureGroups: {
        read: false,
        write: false,
        update: false,
        delSet: false,
        delGroup: false,
      },
    },
  },
  mutations: {
    serverOnline(state, data) {
      state.serverOnline = data;
    },
    windowSize(state, data) {
      state.windowSize = data;
    },
    dateFormat(state, data) {
      state.dateFormat = data;
    },
    login(state, user) {
      state.user = user;
    },
    selectedClient(state, data) {
      state.selectedClient = data;
    },
    reloadClients(state, data) {
      state.reloadClients = data;
    },
    userSettings(state, data) {
      state.userSettings = data;
    },
    reloadTable(state, data) {
      state.reloadTable = data;
    },
    wsUserMessage(state, data) {
      state.wsUserMessage = data;
    },
    appNotifyMessage(state, data) {
      state.appNotifyMessage = data;
    },
    globalMessage(state, data) {
      state.globalMessage = data;
    },
    listSystemNotifications(state, data) {
      state.listSystemNotifications = data;
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      state.socket.isConnected = false;
    },
    SOCKET_ONMESSAGE(state, message) {
      //console.log('msg: ', message);
    },
    SOCKET_RECONNECT(state, count) {
      state.socket.reconnectCount = count;
      // console.log('reconnectingWebSocket', count);
    },
    SOCKET_RECONNECT_ERROR(state, event) {
      state.socket.reconnectError = true;
    },
  },
  getters: {
    serverOnline: (state) => state.serverOnline,
    windowSize: (state) => state.windowSize,
    dateFormat: (state) => state.dateFormat,
    dateTimeShortFormat: (state) => state.dateTimeShortFormat,
    user: (state) => state.user,
    appNotifyMessage: (state) => state.appNotifyMessage,
    globalMessage: (state) => state.globalMessage,
    listSystemNotifications: (state) => state.listSystemNotifications,
    reloadTable: (state) => state.reloadTable,
    wsUserMessage: (state) => state.wsUserMessage,
    wsOnlineUsers: (state) => state.wsOnlineUsers,
    selectedClient: (state) => state.selectedClient,
    reloadClients: (state) => state.reloadClients,
    userSettings: (state) => state.userSettings,
    socket: (state) => state.socket,
  },
  actions: {
    updateTable(context, data) {
      this.commit('reloadTable', data.table);
    },
    updateOnline(context, data) {
      this.state.wsOnlineUsers = data.online;
    },
    appUserNotify(context, data) {
      this.commit('appNotifyMessage', data.message);
      this.commit('reloadTable', data.table);
    },
    userMessage(context, data) {
      this.commit('wsUserMessage', data);
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/login', user)
          .then((response: any) => {
            commit('login', response.data.user);
            if (response.data.message === 'QRmailSend') {
              this.commit('appNotifyMessage', 'Please check your E-mail to get QR code for google Authenticator');
              reject('waitForQR');
              return;
            }
            if (response.data.message === 'activateQRpending') {
              this.commit('appNotifyMessage', 'Please check your E-mail and activate google authenticator');
              reject('waitForQR');
              return;
            }
            this.dispatch('getUserSettings').then(() => {
              db.table('settings').clear();
              db.table('settings').add({ id: 0, userSettings: store.state.userSettings });
              resolve(true);
            });
          })
          .catch((err: any) => {
            console.log('Action login(), Error');
            // commit('logout');
            reject(err);
          });
      });
    },
    checkLogin() {
      // console.log('checking session');
      return new Promise((resolve, reject) => {
        axios
          .get('/api/login')
          .then((response: any) => {
            if (response.data.user === null) {
              if (Vue.prototype.$socket) {
                Vue.prototype.$socket.close(1000);
                Vue.prototype.$disconnect();
              }
              if (router.currentRoute.path != '/') {
                router.push('/');
              }
              this.state.user = '';
              reject('noSession');
            } else {
              this.commit('login', response.data.user);
              this.dispatch('getUserSettings').then(() => resolve(true));
            }
          })
          .catch((err: any) => {
            this.dispatch('catchErrHandler', err).then(() => {
              reject('networkError');
            });
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        if (Vue.prototype.$socket) {
          Vue.prototype.$socket.close(1000);
          Vue.prototype.$disconnect();
        }
        const action = 'logout';
        this.state.selectedClient = {};
        this.state.user = '';
        this.state.userSettings.fullName = '';
        db.table('settings').update(0, { userSettings: this.state.userSettings });
        axios
          .post('/api/login', { action })
          .then(() => {
            this.state.user = '';
            resolve(true);
          })
          .catch((err: any) => {
            console.log('Action logout(), Error');
            reject(err);
          });
      });
    },
    webSocketOpen() {
      let websocketURL: string;
      websocketURL = '';
      if (window.location.protocol === 'http:') {
        websocketURL = 'ws://' + window.location.hostname + ':' + window.location.port + `/ws/notify`;
      }
      if (window.location.protocol === 'https:') {
        websocketURL = 'wss://' + window.location.hostname + ':' + window.location.port + `/ws/notify`;
      }
      Vue.use(VueNativeSock, websocketURL, {
        store,
        format: 'json',
        connectManually: true,
        reconnection: process.env.NODE_ENV !== 'development',
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
      });
      if (!Vue.prototype.$socket) {
        Vue.prototype.$connect();
      }
    },
    async catchErrHandler(value, err) {
      if (!err.response) {
        if (err.message.includes('timeout of')) {
          this.commit('appNotifyMessage', err.message);
          return;
        }
        this.commit('serverOnline', false);
        if (Vue.prototype.$socket) {
          Vue.prototype.$disconnect();
        }
        if (!this.state.user) {
          await db
            .table('settings')
            .get(0)
            .then((data) => {
              this.state.user = data.userSettings.fullName;
              this.state.userSettings = data.userSettings;
            });
        }
      } else {
        if (err.response.status === 401) {
          value.state.globalMessage = 'Unauthorised!';
          router.push('/');
          return;
        } else {
          console.log('errCheck: ', err.response);
          let x = err.response.statusText;
          err.response.data.message ? (x = x + ' - ' + err.response.data.message) : '';
          value.state.globalMessage = x;
        }
      }
    },
    getSystemNotifications() {
      axios
        .get('api/v1/sysnotify')
        .then((response) => {
          this.commit('listSystemNotifications', response.data);
        })
        .catch((err) => {
          this.dispatch('catchErrHandler', err);
        });
    },
    getUserSettings() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/user')
          .then((response: any) => {
            const FlagRead = 1;
            const FlagWrite = 2;
            const FlagUpdate = 4;
            const FlagDelete = 8;
            const Flag16 = 16; // Patch or delete group in Azure
            const FlagReadFiles = 32;
            const FlagWriteFiles = 64;
            const FlagDownloadFiles = 128;
            const FlagDeleteFiles = 256;
            const FlagSpecialLevel = 4096;
            this.getters.userSettings.fullName = response.data.fullName;
            this.getters.userSettings.userID = response.data.id;
            this.getters.userSettings.typeSpeed = response.data.typeSpeed;
            this.getters.userSettings.darkMode = response.data.darkMode;
            this.getters.userSettings.admin = response.data.admin;
            this.getters.userSettings.email = response.data.email;
            this.getters.userSettings.picture = response.data.picture;
            // Prepare clients access settings
            this.getters.userSettings.accClients.read = Boolean(response.data.accClients & FlagRead);
            this.getters.userSettings.accClients.write = Boolean(response.data.accClients & FlagWrite);
            this.getters.userSettings.accClients.update = Boolean(response.data.accClients & FlagUpdate);
            this.getters.userSettings.accClients.delete = Boolean(response.data.accClients & FlagDelete);
            // Prepare serialisations access settings
            this.getters.userSettings.accSerialisations.read = Boolean(response.data.accSerialisations & FlagRead);
            this.getters.userSettings.accSerialisations.write = Boolean(response.data.accSerialisations & FlagWrite);
            this.getters.userSettings.accSerialisations.update = Boolean(response.data.accSerialisations & FlagUpdate);
            this.getters.userSettings.accSerialisations.delete = Boolean(response.data.accSerialisations & FlagDelete);
            this.getters.userSettings.accSerialisations.patch = Boolean(response.data.accSerialisations & Flag16);
            this.getters.userSettings.accSerialisations.special = Boolean(
              response.data.accSerialisations & FlagSpecialLevel,
            );
            // Prepare knowledgeBase access settings
            this.getters.userSettings.accKnowledgeBase.read = Boolean(response.data.accKnowledgeBase & FlagRead);
            this.getters.userSettings.accKnowledgeBase.write = Boolean(response.data.accKnowledgeBase & FlagWrite);
            this.getters.userSettings.accKnowledgeBase.update = Boolean(response.data.accKnowledgeBase & FlagUpdate);
            this.getters.userSettings.accKnowledgeBase.delete = Boolean(response.data.accKnowledgeBase & FlagDelete);
            this.getters.userSettings.accKnowledgeBase.readFiles = Boolean(
              response.data.accKnowledgeBase & FlagReadFiles,
            );
            this.getters.userSettings.accKnowledgeBase.writeFiles = Boolean(
              response.data.accKnowledgeBase & FlagWriteFiles,
            );
            this.getters.userSettings.accKnowledgeBase.downloadFiles = Boolean(
              response.data.accKnowledgeBase & FlagDownloadFiles,
            );
            this.getters.userSettings.accKnowledgeBase.deleteFiles = Boolean(
              response.data.accKnowledgeBase & FlagDeleteFiles,
            );
            // Prepare contacts access settings
            this.getters.userSettings.accContacts.read = Boolean(response.data.accContacts & FlagRead);
            this.getters.userSettings.accContacts.write = Boolean(response.data.accContacts & FlagWrite);
            this.getters.userSettings.accContacts.update = Boolean(response.data.accContacts & FlagUpdate);
            this.getters.userSettings.accContacts.delete = Boolean(response.data.accContacts & FlagDelete);
            // Prepare users access settings
            this.getters.userSettings.accUsers.read = Boolean(response.data.accUsers & FlagRead);
            this.getters.userSettings.accUsers.write = Boolean(response.data.accUsers & FlagWrite);
            this.getters.userSettings.accUsers.update = Boolean(response.data.accUsers & FlagUpdate);
            this.getters.userSettings.accUsers.delete = Boolean(response.data.accUsers & FlagDelete);
            // Prepare appLog access settings
            this.getters.userSettings.accAppLog.read = Boolean(response.data.accAppLog & FlagRead);
            this.getters.userSettings.accAppLog.write = Boolean(response.data.accAppLog & FlagWrite);
            this.getters.userSettings.accAppLog.update = Boolean(response.data.accAppLog & FlagUpdate);
            this.getters.userSettings.accAppLog.delete = Boolean(response.data.accAppLog & FlagDelete);
            this.getters.userSettings.accAppLog.readFiles = Boolean(response.data.accAppLog & FlagReadFiles);
            this.getters.userSettings.accAppLog.writeFiles = Boolean(response.data.accAppLog & FlagWriteFiles);
            this.getters.userSettings.accAppLog.downloadFiles = Boolean(response.data.accAppLog & FlagDownloadFiles);
            this.getters.userSettings.accAppLog.deleteFiles = Boolean(response.data.accAppLog & FlagDeleteFiles);
            // Prepare Products access settings
            this.getters.userSettings.accProducts.read = Boolean(response.data.accProducts & FlagRead);
            this.getters.userSettings.accProducts.write = Boolean(response.data.accProducts & FlagWrite);
            this.getters.userSettings.accProducts.update = Boolean(response.data.accProducts & FlagUpdate);
            this.getters.userSettings.accProducts.delete = Boolean(response.data.accProducts & FlagDelete);
            // Prepare Notifications access settings
            this.getters.userSettings.accNotifications.read = Boolean(response.data.accNotifications & FlagRead);
            this.getters.userSettings.accNotifications.write = Boolean(response.data.accNotifications & FlagWrite);
            this.getters.userSettings.accNotifications.update = Boolean(response.data.accNotifications & FlagUpdate);
            this.getters.userSettings.accNotifications.delete = Boolean(response.data.accNotifications & FlagDelete);
            // Prepare clienNotes access settings
            this.getters.userSettings.accClientNotes.read = Boolean(response.data.accClientNotes & FlagRead);
            this.getters.userSettings.accClientNotes.update = Boolean(response.data.accClientNotes & FlagUpdate);
            // Prepare Hosting access settings
            this.getters.userSettings.accHosting.read = Boolean(response.data.accHosting & FlagRead);
            this.getters.userSettings.accHosting.write = Boolean(response.data.accHosting & FlagWrite);
            this.getters.userSettings.accHosting.update = Boolean(response.data.accHosting & FlagUpdate);
            this.getters.userSettings.accHosting.delete = Boolean(response.data.accHosting & FlagDelete);
            // Prepare Secrets access settings
            this.getters.userSettings.accSecrets.read = Boolean(response.data.accSecrets & FlagRead);
            this.getters.userSettings.accSecrets.write = Boolean(response.data.accSecrets & FlagWrite);
            this.getters.userSettings.accSecrets.update = Boolean(response.data.accSecrets & FlagUpdate);
            this.getters.userSettings.accSecrets.delete = Boolean(response.data.accSecrets & FlagDelete);
            // Prepare BugTrack access settings
            this.getters.userSettings.accBugTrack.read = Boolean(response.data.accBugTrack & FlagRead);
            // Prepare AzureGroups access settings
            this.getters.userSettings.accAzureGroups.read = Boolean(response.data.accAzureGroups & FlagRead);
            this.getters.userSettings.accAzureGroups.write = Boolean(response.data.accAzureGroups & FlagWrite);
            this.getters.userSettings.accAzureGroups.update = Boolean(response.data.accAzureGroups & FlagUpdate);
            this.getters.userSettings.accAzureGroups.delSet = Boolean(response.data.accAzureGroups & FlagDelete);
            this.getters.userSettings.accAzureGroups.delGroup = Boolean(response.data.accAzureGroups & Flag16);
            resolve(true);
          })
          .catch((err) => {
            console.log('usrSetingsErr: ', err);
            reject(true);
          });
      });
    },
  },
});
