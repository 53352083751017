<template>
  <div>
    <v-row>
      <v-col class="pb-0" v-cloak @drop.prevent="attachmentDropFile" @dragover.prevent>
        <v-data-table
          :headers="headers"
          fixed-header
          :items="filesTable"
          disable-pagination
          :height="$store.getters.windowSize.y - 510"
          hide-default-footer
          dense
          style="height: 100%; max-width: 100%"
          class="tableSelectCursor elevation-2"
        >
          <template v-slot:item="{ item }">
            <tr style="max-width: 100%">
              <td width="55%">{{ item.filename }}</td>
              <td width="25%">{{ item.size }}</td>
              <td width="10%" class="text-xs">
                <a :href="`/api/v1/pm/files/enhancement/${enhancement.id}/${item.filename}`">
                  <img width="30px" height="30px" :src="item.icon" />
                </a>
              </td>
              <td width="10%" class="text-xs">
                <v-icon left color="red" @click="(fileForDelete = item.filename), (confirmDeleteFile = true)">
                  {{ mdiDeleteEmpty }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-file-input height="25" v-model="chosenFiles" show-size counter clearable multiple hide-details />
      </v-col>
    </v-row>

    <v-dialog v-model="uploadLoader" persistent max-width="40vw">
      <v-card color="rgb(242, 242, 242, 0.6)">
        <v-card-title class="headline" primary-title>Uploading</v-card-title>
        <div style="min-height: 300px" class="text-md-center">
          <div style="min-height: 80px" />
          <v-progress-circular :size="160" :width="20" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete file?"
      body="Do you really want delete file?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteFile"
      v-on:confirm="deleteFile"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'enhancementFiles',
  props: { enhancement: Object },
  components: { ConfirmDialog },
  data: () => ({
    filesTable: [],
    chosenFiles: [],
    uploadLoader: false,
    confirmDeleteFile: false,
    fileForDelete: '',
    comments: [],
    headers: [
      { text: 'File', value: 'name' },
      { text: 'Size', value: 'size', width: '150' },
      { text: '', value: '' },
      { text: '', value: '' },
    ],
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    enhancement() {
      this.loadFiles();
    },
    chosenFiles() {
      if (this.chosenFiles.length > 0) {
        this.filesUpload();
      }
    },
  },
  mounted() {
    this.loadFiles();
  },
  methods: {
    loadFiles() {
      if (this.enhancement.id !== 0) {
        axios
          .get(`/api/v1/pm/files/enhancement/${this.enhancement.id}`)
          .then((response) => {
            for (const file of response.data) {
              file.size = Math.round(file.size / 1024);
              if (file.size >= 1024) {
                file.size = `${Math.round(file.size / 1024)} MB`;
              } else {
                file.size = `${file.size} kB`;
              }
              file.icon = `/api/v1/icon/${file.icon}`;
            }
            this.filesTable = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.filesTable = [];
      }
    },
    attachmentDropFile(inputFiles: { dataTransfer: { files: [] } }) {
      this.chosenFiles = Array.from(inputFiles.dataTransfer.files);
    },
    filesUpload() {
      this.uploadLoader = true;
      const requests = this.chosenFiles.reduce((promiseChain, item) => {
        return promiseChain.then(
          () =>
            new Promise((resolve) => {
              const formData = new FormData();
              formData.append('file', item);
              axios
                .post(`/api/v1/pm/files/enhancement/${this.enhancement.id}`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  // this.uploadStep++;
                  resolve();
                })
                .catch((err) => {
                  // this.uploadStep = 0;
                  this.$store.dispatch('catchErrHandler', err);
                });
            }),
        );
      }, Promise.resolve());
      requests.then(() => {
        this.chosenFiles = [];
        this.uploadLoader = false;
        this.loadFiles();
      });
    },
    deleteFile() {
      axios
        .delete(`/api/v1/pm/files/enhancement/${this.enhancement.id}/${this.fileForDelete}`)
        .then((response) => {
          this.loadFiles();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
