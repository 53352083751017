<template>
  <v-row>
    <v-col>
      <v-row v-if="$store.getters.selectedClient.id != null">
        <v-col>
          <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
          <div style="min-height: 2px"></div>

          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 230"
            :search="search"
            @click:row="rowClicked"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td class="text-xs">{{ item.name }}</td>
                <td class="text-xs">{{ item.surname }}</td>
                <td class="text-xs">{{ item.email }}</td>
                <td class="text-xs">{{ item.phone }}</td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="tableSlotBackground" :colspan="headers.length">
                <v-row class="mx-0 mt-1">
                  <v-col cols="1">
                    <v-btn
                      @click="(contact = { ...item }), (dialogEdit = true)"
                      block
                      small
                      tile
                      class="mb-2"
                      color="btnColorNew"
                      >View</v-btn
                    >
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="5">
                    <v-row>
                      <v-col class="pt-0" cols="4">Department:</v-col>
                      <v-col class="pt-0" cols="8">{{ item.department }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="4"><span>Position:</span></v-col>
                      <v-col class="pt-0" cols="8">{{ item.position }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-1">
        <v-col cols="4">
          <span>
            <h3 v-if="!$store.getters.selectedClient.id">Please select client</h3>
          </span>
          <v-btn
            v-if="$store.getters.userSettings.accContacts.write && $store.getters.selectedClient.id != null"
            @click.stop="newContact"
            tile
            block
            color="btnColorNew"
            >NEW</v-btn
          >
        </v-col>
      </v-row>
    </v-col>

    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogEdit" width="70%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Contact:
          <v-spacer />
          <v-icon large @click="dialogEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="contactForm">
            <v-row>
              <v-col cols="2">
                <v-text-field v-model="contact.title" maxlength="60" label="Title:" required></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="contact.name"
                  maxlength="60"
                  label="Name:"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="contact.surname"
                  maxlength="60"
                  label="Surname:"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="contact.department" maxlength="60" label="Department:"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="contact.position" maxlength="60" label="Position:"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="contact.email" maxlength="60" label="Email:"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="contact.phone" maxlength="60" label="Phone:"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="contact.id > 0 && $store.getters.userSettings.accContacts.delete"
            tile
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              ($store.getters.userSettings.accContacts.write && contact.id == 0) ||
              $store.getters.userSettings.accContacts.update
            "
            tile
            color="btnColorConfirm"
            @click="saveContact"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete contact?"
      body="Do you really want delete contact?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteContact"
    />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import tableMixin from '../../mixins/table';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiMagnify, mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'tableContacts',
  mixins: [tableMixin],
  components: { ConfirmDialog },
  data: () => ({
    dialogEdit: false,
    tableData: [],
    contact: {
      id: 0,
      clientID: 0,
      name: '',
      surname: '',
      title: '',
      department: '',
      position: '',
      phone: '',
      email: '',
      notes: '',
    },
    confirmDeleteDialog: false,
    validForm: false,
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Surname', value: 'surname' },
      { text: 'E-mail', value: 'email' },
      { text: 'Phone', value: 'phone' },
    ],
    rules: {
      required: (value: any) => !!value || 'Required.',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'Must be numeric!!!';
      },
    },
    mdiMagnify: mdiMagnify,
    mdiClose: mdiClose,
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadContacts();
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'contacts') {
        this.loadContacts();
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadContacts();
  },
  methods: {
    loadContacts() {
      if (this.$store.getters.selectedClient.id != null) {
        axios
          .get('api/v1/contacts', {
            params: {
              clientID: this.$store.getters.selectedClient.id,
            },
          })
          .then((response) => {
            this.tableData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.tableData = [];
      }
    },
    newContact() {
      const getDefaults: any = this.$options;
      this.contact = { ...getDefaults.data().contact };
      this.contact.clientID = this.$store.getters.selectedClient.id;
      this.dialogEdit = true;
      this.$nextTick(function () {
        const form: any = this.$refs.contactForm;
        form.validate();
      });
    },
    saveContact() {
      const form: any = this.$refs.contactForm;
      if (!form.validate()) {
        this.$store.commit('globalMessage', 'Form is not filled correctly!!!');
      } else {
        if (this.contact.id === 0) {
          axios
            .post('/api/v1/contacts', this.contact)
            .then((response) => {
              this.loadContacts();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('/api/v1/contacts', this.contact)
            .then((response) => {
              this.loadContacts();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    deleteContact() {
      axios
        .delete(`/api/v1/contacts/${this.contact.id}`)
        .then((response) => {
          this.loadContacts();
          this.dialogEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
