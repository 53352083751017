<template>
  <v-container fluid>
    <!--div id="widgetBox">
      <div id="widgetBoxHeader">Widget</div>
      <p>Prepare</p>
      <p>dragable</p>
      <p>widget</p>
    </div-->
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'AzureStats',
  data: () => ({}),
  watch: {},
  mounted() {
    // this.dragElement(document.getElementById('widgetBox'));
  },
  methods: {
    dragElement(elmnt: any) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      document!.getElementById('widgetBoxHeader')!.onmousedown = dragMouseDown;

      function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e: any) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
        if (elmnt.offsetTop - pos2 < 0) {
          elmnt.style.top = '0px';
        }
        if (elmnt.offsetLeft - pos1 < 0) {
          elmnt.style.left = '0px';
        }
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
});
</script>

<style lang="sass">
#widgetBox
  min-width: 15em
  position: absolute
  z-index: 9
  background-color: #f1f1f1
  border: 1px solid #d3d3d3
  text-align: center

#widgetBoxHeader
  height: 25px
  cursor: move
  z-index: 10
  background-color: #2196F3
  color: #fff
</style>
