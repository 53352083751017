<template>
  <v-card>
    <v-card-title class="headline headerColorStandard"
      >My settings: &nbsp;&nbsp;&nbsp;{{ $store.getters.user }}
      <v-spacer />
      <v-icon large @click="$emit('update:openDialog', false)">{{ mdiClose }}</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="px-0">
          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            @click:row="rowClicked"
            group-by="app"
            show-group-by
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 260"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor elevation-1"
          >
            <template v-slot:[`group.summary`]="item">
              <tr>
                <td>Time summary: {{ sumTimeSpend(item) }}</td>
              </tr>
            </template>

            <template v-slot:item="{ item }">
              <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td>{{ item.timeEnd | moment($store.getters.dateTimeShortFormat) }}</td>
                <td>{{ toHHMMSS(item.timeSpend) }}</td>
                <td>{{ item.app }}</td>
                <td>{{ item.device }}</td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-3 tableSlotBackground" :colspan="headers.length">
                <v-data-table
                  :headers="headersActions"
                  :items="item.actions"
                  :height="190"
                  fixed-header
                  disable-pagination
                  hide-default-footer
                  class="tableCursor"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.timeEnd | moment($store.getters.dateTimeShortFormat) }}</td>
                      <td>{{ toHHMMSS(item.timeSpend) }}</td>
                      <td>{{ item.text }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import tableMixin from '../mixins/table';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'tableActivities',
  mixins: [tableMixin],
  props: {
    openDialog: Boolean,
  },
  data: () => ({
    tableData: [],
    expandedRow: [] as any,
    selectedRowID: 0,
    headers: [
      { text: 'Time', value: 'timeEnd', width: '15%' },
      { text: 'Time spend', value: 'timeSpend', width: '15%' },
      { text: 'Application', value: 'app', width: '40%' },
      { text: 'Device', value: 'device', width: '40%' },
    ],
    headersActions: [
      { text: 'Time', value: 'text', width: '15%' },
      { text: 'Time Spend', value: 'timeSpend', width: '15%' },
      { text: 'Text', value: 'timeEnd', width: '70%' },
    ],
    mdiClose: mdiClose,
  }),
  watch: {
    openDialog() {
      this.openDialog ? this.loadActivities() : null;
    },
  },
  mounted() {
    this.loadActivities();
  },
  methods: {
    loadActivities() {
      axios
        .get(`/api/v1/activities`)
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    toHHMMSS(totalSeconds: number) {
      const totalMinutes = Math.floor(totalSeconds / 60);

      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // add zeroes
      const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
      const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;

      return `${hoursStr}:${minutesStr}:${secondsStr}`;
    },
    sumTimeSpend(item: any) {
      let sum = 0;
      item.items.forEach((element: any) => {
        sum += element.timeSpend;
      });
      return this.toHHMMSS(sum);
    },
  },
});
</script>
