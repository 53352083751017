<template>
  <v-dialog v-model="openComputed" max-width="50vw">
    <v-card>
      <v-card-title :class="`headline ${headerColor}`" primary-title>{{ header }}</v-card-title>
      <v-card-text>
        <h2 class="mt-4">{{ body }}</h2>
      </v-card-text>
      <v-card-actions>
        <v-btn tile block color="btnColorCancel" @click="$emit('update:openDialog', false)">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'confimDialog',
  props: {
    headerColor: String,
    header: String,
    body: String,
    openDialog: Boolean,
  },
  computed: {
    // v-model cannot use openDialog because it is immutable
    openComputed: function () {
      return this.openDialog;
    },
  },
});
</script>
