<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
        <div style="min-height: 2px"></div>
        <v-data-table
          :headers="headers"
          :items="tableData"
          item-key="id"
          :expanded="expandedRow"
          :height="$store.getters.windowSize.y - 180"
          :search="search"
          @click:row="rowClicked"
          fixed-header
          disable-pagination
          hide-default-footer
          class="tableSelectCursor"
        >
          <template v-slot:item="{ item }">
            <tr @click="(knowledgeBase = { ...item }), (dialogEdit = true), loadFiles()">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-btn
          tile
          v-if="$store.getters.userSettings.accKnowledgeBase.write"
          block
          color="btnColorNew"
          @click="newKnowledgeBase"
          >NEW</v-btn
        >
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogEdit" persistent width="80%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Knowledgebase: {{ knowledgeBase.name }}
          <v-spacer />
          <v-icon large @click="dialogEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="knowledgeBaseForm">
            <v-row>
              <v-col cols="6">
                <v-text-field label="Name:" v-model="knowledgeBase.name" :rules="[rules.required]" hide-details />
                <v-text-field
                  label="Description:"
                  v-model="knowledgeBase.description"
                  :rules="[rules.required]"
                  hide-details
                />
                <v-textarea label="Notes:" v-model="knowledgeBase.notes" rows="10" no-resize hide-details />
              </v-col>
              <v-col
                v-show="knowledgeBase.id != 0 && $store.getters.userSettings.accKnowledgeBase.readFiles"
                cols="6"
                v-cloak
                @drop.prevent="attachmentDropFile"
                @dragover.prevent
              >
                <v-row v-if="$store.getters.userSettings.accKnowledgeBase.writeFiles">
                  <v-col class="pt-0 mt-0">
                    <v-file-input v-model="chosenFiles" show-size counter clearable multiple label="Add attachment" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pr-0">
                    <v-data-table
                      :items="filesTable"
                      disable-pagination
                      height="320px"
                      hide-default-footer
                      style="height: 100%; max-width: 100%"
                      class="tableSelectCursor"
                    >
                      <template v-slot:item="{ item }">
                        <tr style="max-width: 100%">
                          <td width="55%">{{ item.filename }}</td>
                          <td width="25%">{{ item.size }}</td>
                          <td
                            v-if="$store.getters.userSettings.accKnowledgeBase.downloadFiles"
                            width="10%"
                            class="text-xs"
                          >
                            <a :href="`/api/v1/knowledgebasefiles/${knowledgeBase.id}/${item.filename}`">
                              <img width="30px" height="30px" :src="item.icon" />
                            </a>
                          </td>
                          <td
                            width="10%"
                            v-if="$store.getters.userSettings.accKnowledgeBase.deleteFiles"
                            class="text-xs"
                          >
                            <v-icon
                              left
                              color="red"
                              @click="(fileForDelete = item.filename), (confirmDeleteFileDialog = true)"
                              >{{ mdiDeleteEmpty }}</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            v-if="knowledgeBase.id > 0 && $store.getters.userSettings.accKnowledgeBase.delete"
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
            >Delete</v-btn
          >
          <v-spacer />
          <v-btn
            tile
            v-if="
              ($store.getters.userSettings.accKnowledgeBase.write && knowledgeBase.id == 0) ||
              $store.getters.userSettings.accKnowledgeBase.update
            "
            color="btnColorConfirm"
            @click="saveKnowledgeBase"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadLoader" persistent max-width="40vw">
      <v-card color="rgb(242, 242, 242, 0.6)">
        <v-card-title class="headline" primary-title>Uploading</v-card-title>
        <div style="min-height: 300px" class="text-md-center">
          <div style="min-height: 80px" />
          <v-progress-circular :size="160" :width="20" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete File?"
      :body="'Do you really want delete ' + fileForDelete + ' file?'"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteFileDialog"
      v-on:confirm="deleteFile"
    />

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete knowledgebase??"
      body="Do you really want delete knowledgebase record?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteKnowledgeBase"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import tableMixin from '../../mixins/table';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiClose, mdiMagnify, mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'TableKnowledgeBase',
  mixins: [tableMixin],
  components: { ConfirmDialog },
  data: () => ({
    tableData: [],
    search: '',
    dialogEdit: false,
    confirmDeleteDialog: false,
    confirmDeleteFileDialog: false,
    validForm: false,
    deleteButton: false,
    datePickerMenu: false,
    chosenFiles: [],
    filesTable: [],
    fileForDelete: '',
    uploadLoader: false,
    versionItems: [],
    knowledgeBase: {
      id: 0,
      name: '',
      description: '',
      notes: '',
    },
    rules: {
      required: (value: any) => !!value || 'Required!!!',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'MUST be number!!!';
      },
    },
    windowHeight: 0,
    headers: [
      { text: 'Name', value: 'name', width: '40%' },
      { text: 'Description', value: 'description', width: '60%' },
    ],
    mdiClose: mdiClose,
    mdiMagnify: mdiMagnify,
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'knowledgeBase') {
        this.loadTableData();
        this.$store.commit('reloadTable', '');
      }
    },
    'knowledgeBase.id'() {
      if (this.$store.getters.userSettings.accKnowledgeBase.readFiles) {
        this.loadFiles();
      }
    },
    chosenFiles() {
      if (this.chosenFiles.length > 0) {
        this.filesUpload();
      }
    },
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      axios
        .get('api/v1/knowledgebase')
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    newKnowledgeBase() {
      const getDefaults: any = this.$options;
      this.knowledgeBase = { ...getDefaults.data().knowledgeBase }; // assign defaut values
      this.dialogEdit = true;
      this.$nextTick(function () {
        const form: any = this.$refs.knowledgeBaseForm;
        form.validate();
      });
    },
    saveKnowledgeBase() {
      const form: any = this.$refs.knowledgeBaseForm;
      if (form.validate() === true) {
        if (this.knowledgeBase.id === 0) {
          axios
            .post('/api/v1/knowledgebase', this.knowledgeBase)
            .then((response) => {
              this.knowledgeBase.id = response.data.newknowledgebaseid;
              this.loadTableData();
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('/api/v1/knowledgebase', this.knowledgeBase)
            .then((response) => {
              this.loadTableData();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    deleteKnowledgeBase() {
      axios
        .delete(`/api/v1/knowledgebase/${this.knowledgeBase.id}`)
        .then((response) => {
          this.loadTableData();
          this.dialogEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadFiles() {
      if (this.knowledgeBase.id !== 0) {
        axios
          .get(`/api/v1/knowledgebasefiles/${this.knowledgeBase.id}`)
          .then((response) => {
            for (const file of response.data) {
              file.size = Math.round(file.size / 1024);
              if (file.size >= 1024) {
                file.size = `${Math.round(file.size / 1024)} MB`;
              } else {
                file.size = `${file.size} kB`;
              }
              file.icon = `/api/v1/icon/${file.icon}`;
            }
            this.filesTable = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.filesTable = [];
      }
    },
    attachmentDropFile(inputFiles: { dataTransfer: { files: [] } }) {
      this.chosenFiles = Array.from(inputFiles.dataTransfer.files);
    },
    filesUpload() {
      this.uploadLoader = true;
      const requests = this.chosenFiles.reduce((promiseChain, item) => {
        return promiseChain.then(
          () =>
            new Promise((resolve) => {
              const formData = new FormData();
              formData.append('file', item);
              axios
                .post(`/api/v1/knowledgebasefiles/${this.knowledgeBase.id}/file`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  // this.uploadStep++;
                  resolve();
                })
                .catch((err) => {
                  // this.uploadStep = 0;
                  this.$store.dispatch('catchErrHandler', err);
                });
            }),
        );
      }, Promise.resolve());
      requests.then(() => {
        this.chosenFiles = [];
        this.uploadLoader = false;
        this.loadFiles();
      });
    },
    deleteFile(file: any) {
      axios
        .delete(`/api/v1/knowledgebasefiles/${this.knowledgeBase.id}/file/${this.fileForDelete}`)
        .then((response) => {
          this.loadFiles();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
