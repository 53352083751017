<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-select
          v-model="filter.idProduct"
          label="Product"
          :items="products"
          item-text="name"
          item-value="id"
          v-on:change="changeProductFilter"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="filter.idSubproduct"
          label="Subproduct"
          :items="subproducts"
          item-text="name"
          item-value="id"
          hide-details
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="filter.idVersion"
          label="Version"
          :items="versions"
          item-text="version"
          item-value="id"
          hide-details
        />
      </v-col>
      <v-col cols="1">
        <v-select
          v-model="filter.idGroup"
          label="Group"
          :items="groups"
          item-text="name"
          item-value="id"
          v-on:change="loadFeatures"
          hide-details
        />
      </v-col>
      <v-col cols="1">
        <v-select
          v-model="filter.idFeature"
          label="Feature"
          :items="features"
          item-text="name"
          item-value="id"
          hide-details
        />
      </v-col>
      <v-col cols="1">
        <v-select
          v-model="filter.idStatus"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details
        />
      </v-col>
      <v-col cols="1">
        <v-select
          v-model="filter.idPriority"
          label="Priority"
          :items="priorities"
          item-text="name"
          item-value="id"
          hide-details
        />
      </v-col>
      <v-col cols="1" />
      <v-col cols="1">
        <v-btn tile block class="mt-2" color="btnColorEdit" @click="loadEnhancements('')">
          <v-icon>{{ mdiMagnify }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="enhancements"
          :height="$store.getters.windowSize.y - 202"
          fixed-header
          disable-pagination
          hide-default-footer
          class="tableSelectCursor"
          dense
        >
          <template v-slot:item="{ item }">
            <tr @click="openEditEnhancement(item)">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.product }}</td>
              <td>{{ item.subproduct }}</td>
              <td>{{ item.version }}</td>
              <td>{{ item.group }}</td>
              <td>{{ item.feature }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.priority }}</td>
              <td>
                <div style="max-height: 42px; overflow: hidden">{{ item.description }}</div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-btn tile block color="btnColorNew" @click="openNewEnhancement">New</v-btn>
      </v-col>
      <v-spacer />
      <v-col class="py-0" cols="2">
        <v-btn tile block color="btnColorEdit" @click="loadEnhancements('xlsx')">export</v-btn>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogNewEnhancement" width="60%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          New Enhancement:
          <v-spacer />
          <v-icon large @click="dialogNewEnhancement = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="enhancement.name" maxlength="255" label="Name:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="enhancement.description" label="Description:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="postEnhancement">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditEnhancement" transition="dialog-bottom-transition" fullscreen>
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Enhancement: {{ enhancement.name }}, Created by: {{ createdByUser }}, ID: {{ enhancement.id }}
          <v-spacer />
          <v-icon large @click="dialogEditEnhancement = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-sheet style="overflow-y: auto; overflow-x: hidden" :height="$store.getters.windowSize.y - 120">
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="enhancement.name" height="40" label="Name" hide-details />
                <v-textarea v-model="enhancement.description" rows="6" label="Description" />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="enhancement.idProduct"
                  label="Product"
                  :items="products"
                  item-text="name"
                  item-value="id"
                  v-on:change="changeProduct"
                  hide-details
                />
                <v-select
                  v-model="enhancement.idSubproduct"
                  label="Subproduct"
                  :items="subproducts"
                  item-text="name"
                  item-value="id"
                  hide-details
                />
                <v-select
                  v-model="enhancement.idGroup"
                  label="Group"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  v-on:change="loadFeatures"
                  hide-details
                />
                <v-select
                  v-model="enhancement.idFeature"
                  label="Feature"
                  :items="features"
                  item-text="name"
                  item-value="id"
                  hide-details
                />
                <v-select
                  v-model="enhancement.idVersion"
                  label="Version"
                  :items="versions"
                  item-text="version"
                  item-value="id"
                  v-bind:append-outer-icon="enhancement.idProduct !== 0 ? mdiPlusCircleOutline : ''"
                  @click:append-outer="openNewVersion"
                  hide-details
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="enhancement.raisedBy"
                  label="Raised By"
                  :items="users"
                  item-text="fullName"
                  item-value="id"
                  hide-details
                />
                <v-select
                  v-model="enhancement.idPriority"
                  label="Priority"
                  :items="priorities"
                  item-text="name"
                  item-value="id"
                  hide-details
                />
                <v-text-field type="number" v-model.number="enhancement.idBugchart" label="Bugchart ID" hide-details />
                <v-select
                  v-model="enhancement.idStatus"
                  label="Status"
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-autocomplete
                  v-model="enhancement.clients"
                  :items="clients"
                  label="Clients related:"
                  item-text="name"
                  item-value="id"
                  dense
                  chips
                  multiple
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7">
                <Comments :enhancement="enhancement" />
              </v-col>
              <v-col cols="5">
                <Files :enhancement="enhancement" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
        <v-divider />
        <v-card-actions>
          <v-btn tile color="btnColorDelete" @click="confirmDeleteEnhancement = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="putEnhancement">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNewVersion" width="60%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          New Verion:
          <v-spacer />
          <v-icon large @click="dialogNewVersion = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="newVersion.version" maxlength="32" label="Version:" hide-details />
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="dateMenu"
                v-model="versionDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="versionDateFormatted"
                    label="Release date:"
                    :append-icon="mdiCalendar"
                    @blur="newVersion.releaseDate = parseDate(versionDateFormatted)"
                    v-on="on"
                    hide-details
                    autocompete="new"
                  />
                </template>
                <v-date-picker v-model="newVersion.releaseDate" no-title @input="versionDateMenu = false" />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="newVersion.note" label="Note:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="newVersion.released" label="Released" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveVersion">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete Enhancement?"
      body="Do you really want delete Enhancement?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteEnhancement"
      v-on:confirm="deleteEnhancement"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import dateFormatMixin from '../../mixins/dateFormat';
import Comments from '@/components/proman/enhancementsComments.vue';
import Files from '@/components/proman/enhancementsFiles.vue';
import { mdiClose, mdiPlusCircleOutline, mdiCalendar, mdiMagnify } from '@mdi/js';

export default Vue.extend({
  name: 'pmEnhancements',
  components: { ConfirmDialog, Comments, Files },
  mixins: [dateFormatMixin],
  data: () => ({
    enhancements: [],
    products: [{ id: 0, name: '' }],
    subproducts: [{ id: 0, name: '' }],
    groups: [{ id: 0, name: '' }],
    features: [{ id: 0, name: '' }],
    versions: [{ id: 0, version: '' }],
    priorities: [{ id: 0, name: '' }],
    statuses: [{ id: 0, name: '' }],
    clients: [],
    users: [],
    createdByUser: '', // User who created the enhancement request
    dialogNewEnhancement: false,
    dialogEditEnhancement: false,
    dialogNewVersion: false,
    confirmDeleteEnhancement: false,
    enhancement: {
      id: 0,
      name: '',
      description: '',
      createdBy: 0,
      raisedBy: 0,
      idPriority: 0,
      idStatus: 0,
      idProduct: 0,
      idSubproduct: 0,
      idGroup: 0,
      idFeature: 0,
      idVersion: 0,
      idBugchart: 0,
      clients: [],
    },
    filter: {
      idPriority: 0,
      idStatus: 0,
      idProduct: 0,
      idSubproduct: 0,
      idGroup: 0,
      idFeature: 0,
      idVersion: 0,
    },
    versionDateFormatted: '',
    versionDateMenu: false,
    newVersion: {
      id: 0,
      idProduct: 0,
      version: '',
      releaseDate: '',
      released: false,
      note: '',
    },
    headers: [
      { text: 'ID', value: 'id', width: '60' },
      { text: 'Enhancement', value: 'name', width: '200' },
      { text: 'Product', value: 'product', width: '150' },
      { text: 'Subproduct', value: 'subproduct', width: '150' },
      { text: 'Version', value: 'version', width: '100' },
      { text: 'Group', value: 'group', width: '100' },
      { text: 'Feature', value: 'feature', width: '100' },
      { text: 'Status', value: 'status', width: '100' },
      { text: 'Priority', value: 'priority', width: '100' },
      { text: 'Description', value: 'description', width: '460' },
    ],
    mdiClose: mdiClose,
    mdiPlusCircleOutline: mdiPlusCircleOutline,
    mdiCalendar: mdiCalendar,
    mdiMagnify: mdiMagnify,
  }),
  watch: {
    'newVersion.releaseDate'() {
      this.versionDateFormatted = this.$moment(this.newVersion.releaseDate).format(this.$store.getters.dateFormat);
    },
  },
  mounted() {
    this.loadEnhancements('');
    this.loadProducts();
    this.loadClients();
    this.loadUsers();
    this.loadStatuses('enhstatus');
    this.loadStatuses('enhpriority');
  },
  methods: {
    async loadEnhancements(format: string) {
      let respType: any = 'json';
      if (format === 'xlsx') {
        respType = 'blob';
      }
      await axios
        .get('api/v1/pm/enhancement', {
          params: {
            idProduct: this.filter.idProduct,
            idSubproduct: this.filter.idSubproduct,
            idGroup: this.filter.idGroup,
            idFeature: this.filter.idFeature,
            idVersion: this.filter.idVersion,
            idPriority: this.filter.idPriority,
            idStatus: this.filter.idStatus,
            format: format,
          },
          responseType: respType,
        })
        .then((response) => {
          switch (format) {
            case 'xlsx': {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'enhancemets.xlsx');
              document.body.appendChild(link);
              link.click();
              break;
            }
            default: {
              this.enhancements = response.data;
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewEnhancement() {
      this.enhancement.id = 0;
      this.enhancement.idProduct = 0;
      this.enhancement.idSubproduct = 0;
      this.enhancement.idGroup = 0;
      this.enhancement.idFeature = 0;
      this.enhancement.idVersion = 0;
      this.enhancement.name = '';
      this.enhancement.createdBy = 0;
      this.enhancement.idStatus = 0;
      this.enhancement.description = '';
      this.dialogNewEnhancement = true;
    },
    postEnhancement() {
      axios
        .post(`api/v1/pm/enhancement`, this.enhancement)
        .then((response) => {
          this.loadEnhancements('').then(() => {
            let row: any;
            for (row of this.enhancements) {
              if (row.id === response.data.newid) {
                this.enhancement = { ...row };
                this.dialogEditEnhancement = true;
                break;
              }
            }
          });
          this.dialogNewEnhancement = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openEditEnhancement(item: any) {
      this.enhancement = { ...item };
      this.loadSubproducts(this.enhancement.idProduct);
      this.loadGroups(this.enhancement.idProduct);
      this.loadFeatures(this.enhancement.idGroup);
      this.loadVersions(this.enhancement.idProduct);
      let user: { id: 0; fullName: '' };
      for (user of this.users) {
        if (user.id === this.enhancement.createdBy) {
          this.createdByUser = user.fullName;
          break;
        }
      }
      this.dialogEditEnhancement = true;
    },
    putEnhancement() {
      axios
        .put(`api/v1/pm/enhancement`, this.enhancement)
        .then(() => {
          this.loadEnhancements('');
          this.dialogEditEnhancement = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteEnhancement() {
      axios
        .delete(`api/v1/pm/enhancement/${this.enhancement.id}`)
        .then(() => {
          this.loadEnhancements('');
          this.dialogEditEnhancement = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    changeProductFilter() {
      this.filter.idSubproduct = 0;
      this.filter.idGroup = 0;
      this.filter.idFeature = 0;
      this.filter.idVersion = 0;
      this.loadSubproducts(this.filter.idProduct);
      this.loadGroups(this.filter.idProduct);
      this.loadFeatures(this.filter.idGroup);
      this.loadVersions(this.filter.idProduct);
    },
    changeProduct() {
      this.enhancement.idSubproduct = 0;
      this.enhancement.idGroup = 0;
      this.enhancement.idFeature = 0;
      this.enhancement.idVersion = 0;
      this.loadSubproducts(this.enhancement.idProduct);
      this.loadGroups(this.enhancement.idProduct);
      this.loadFeatures(this.enhancement.idGroup);
      this.loadVersions(this.enhancement.idProduct);
    },
    loadProducts() {
      axios
        .get(`api/v1/pm/product`)
        .then((response) => {
          this.products = response.data;
          this.products.unshift({ id: 0, name: '' });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadSubproducts(requestid: number) {
      axios
        .get(`api/v1/pm/subproduct/${requestid}`)
        .then((response) => {
          this.subproducts = response.data;
          this.subproducts.unshift({ id: 0, name: '' });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadGroups(requestid: number) {
      axios
        .get(`api/v1/pm/group/${requestid}`)
        .then((response) => {
          this.groups = response.data;
          this.groups.unshift({ id: 0, name: '' });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadFeatures(requestid: number) {
      axios
        .get(`api/v1/pm/feature/${requestid}`)
        .then((response) => {
          this.features = response.data;
          this.features.unshift({ id: 0, name: '' });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadVersions(requestid: number) {
      axios
        .get(`api/v1/pm/version/${requestid}`)
        .then((response) => {
          for (const version of response.data) {
            if (version.released === false) {
              version.version = version.version + ' - NOT released';
            }
          }
          this.versions = response.data;
          this.versions.unshift({ id: 0, version: '' });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadClients() {
      axios
        .get('api/v1/clients', { params: { closed: false } })
        .then((response) => {
          this.clients = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadUsers() {
      axios
        .get('api/v1/usersbasic')
        .then((response) => {
          this.users = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadStatuses(type: string) {
      axios
        .get(`api/v1/pm/statuses/${type}`)
        .then((response) => {
          switch (type) {
            case 'enhstatus':
              this.statuses = response.data;
              this.statuses.unshift({ id: 0, name: '' });
              break;
            case 'enhpriority':
              this.priorities = response.data;
              this.priorities.unshift({ id: 0, name: '' });
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewVersion() {
      this.newVersion.id = 0;
      this.newVersion.version = '';
      this.newVersion.releaseDate = new Date().toISOString().substr(0, 10);
      this.newVersion.released = false;
      this.newVersion.note = '';
      this.dialogNewVersion = true;
    },
    saveVersion() {
      if (this.newVersion.version === '') {
        this.$store.commit('globalMessage', 'You must enter version name');
        return;
      }
      let dataForSave = { ...this.newVersion }; // Avoid interact with watcher on date
      dataForSave.releaseDate = new Date(dataForSave.releaseDate).toISOString();
      dataForSave.idProduct = this.enhancement.idProduct;
      axios
        .post(`api/v1/pm/version`, dataForSave)
        .then(() => {
          this.loadVersions(this.enhancement.idProduct);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
      this.dialogNewVersion = false;
    },
  },
});
</script>
