import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    expandedRow: [] as any,
    selectedRowID: 0,
  }),
  methods: {
    rowClicked(value: any) {
      if (this.expandedRow.length > 0) {
        if (value.id === this.expandedRow[0].id) {
          this.expandedRow = [{}];
          this.selectedRowID = 0;
        } else {
          this.expandedRow = [value];
          this.selectedRowID = value.id;
        }
      } else {
        this.expandedRow.push(value);
        this.selectedRowID = value.id;
      }
    },
  },
});
