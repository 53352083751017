import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pt-0 px-0"},[_c(VTextField,{attrs:{"clearable":"","append-icon":_vm.mdiMagnify,"label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"tableSelectCursor",attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"options":{ itemsPerPage: 40 },"footer-props":{ itemsPerPageOptions: [20, 40, 60, 80] },"item-key":"id","height":_vm.$store.getters.windowSize.y - 240,"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{on:{"click":function($event){return _vm.showLog(item.id)}}},[(item.date)?_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY HH:mm:ss')))]):_vm._e(),_c('td',[_vm._v(_vm._s(item.login))]),_c('td',[_vm._v(_vm._s(item.method))]),_c('td',[_vm._v(_vm._s(item.api))]),_c('td',[_vm._v(_vm._s(item.remoteAddr))])])]}}])})],1)],1),_c(VDialog,{attrs:{"width":"80%"},model:{value:(_vm.dialogViewLog),callback:function ($$v) {_vm.dialogViewLog=$$v},expression:"dialogViewLog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline headerColorStandard",attrs:{"primary-title":""}},[_vm._v(" Change log: "),_c(VSpacer),_c(VIcon,{attrs:{"large":""},on:{"click":function($event){_vm.dialogViewLog = false}}},[_vm._v(_vm._s(_vm.mdiClose))])],1),_c(VCardText,[_c(VSheet,{staticStyle:{"overflow":"auto"},attrs:{"height":"500"}},[_c('json-viewer',{attrs:{"value":_vm.changelog,"theme":this.$vuetify.theme.dark ? 'change-log-dark' : 'change-log-light',"expand-depth":1,"sort":""}})],1)],1),_c(VDivider)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }