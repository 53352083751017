<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="tableData"
              :loading="loading"
              item-key="id"
              :expanded="expandedRow"
              :height="$store.getters.windowSize.y - 135"
              fixed-header
              disable-pagination
              hide-default-footer
              class="tableCursor"
            >
              <template v-slot:item="{ item }">
                <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                  <td>{{ item.name }}</td>
                  <td>{{ item.namepublic }}</td>
                  <td>{{ item.clientname }}</td>
                  <td v-if="item.sumData">
                    <v-icon v-if="!item.sumStatusTS" color="green">{{ mdiCheckBold }}</v-icon>
                    <v-icon v-if="item.sumStatusTS" color="red">{{ mdiAlertCircle }}</v-icon>
                  </td>
                  <td v-else>
                    <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
                  </td>
                  <td v-if="item.sumData">
                    <v-icon v-if="!item.sumStatusHDD" color="green">{{ mdiCheckBold }}</v-icon>
                    <v-icon v-if="item.sumStatusHDD" color="red">{{ mdiAlertCircle }}</v-icon>
                  </td>
                  <td v-else>
                    <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
                  </td>
                  <td v-if="item.sumData">
                    <v-icon v-if="!item.sumStatus" color="green">{{ mdiCheckBold }}</v-icon>
                    <v-icon v-if="item.sumStatus" color="red">{{ mdiAlertCircle }}</v-icon>
                  </td>
                  <td v-else>
                    <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
                  </td>
                  <td v-if="item.sumData">
                    {{ item.sumData.timeStamp | moment($store.getters.dateTimeShortFormat) }}
                  </td>
                  <td v-else>
                    <v-icon>{{ mdiHelpCircleOutline }}</v-icon>
                  </td>
                </tr>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td v-if="item.sumData" class="tableSlotBackground" :colspan="headers.length">
                  <v-row class="mx-0 mt-1">
                    <v-col cols="1">
                      <v-btn
                        tile
                        v-if="$store.getters.userSettings.accHosting.update"
                        block
                        color="btnColorEdit"
                        @click="(server = { ...item }), (dialogEdit = true)"
                        >Edit</v-btn
                      >
                    </v-col>
                    <v-col cols="2">
                      <v-row class="my-1" v-for="site in item.webSites" v-bind:key="site.id">
                        <v-col>
                          <v-layout row>
                            <v-flex md2>
                              <v-icon v-if="site.currentStatus === 200" color="green">{{ mdiCheckBold }}</v-icon>
                              <v-icon v-if="site.currentStatus !== 200" color="red">{{ mdiAlertCircle }}</v-icon>
                            </v-flex>
                            <v-flex md10>
                              <h4>{{ site.name }}</h4>
                            </v-flex>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <b>Task scheduler logs:</b>
                      <div v-if="item.sumData !== null">
                        <div
                          v-for="taskscheduler in item.sumData.taskscheduler"
                          v-bind:key="taskscheduler.name"
                          v-bind:style="[
                            taskscheduler.statusShort == 'ER' || taskscheduler.timeErr
                              ? { color: 'red' }
                              : { color: 'green' },
                          ]"
                          @click="
                            (selectedTaskScheduler = taskscheduler),
                              (taskSchedulerLogModal = true),
                              (selectedServerUID = item.uid)
                          "
                        >
                          {{ taskscheduler.time }} - {{ taskscheduler.name }} - {{ taskscheduler.taskName }} -
                          {{ taskscheduler.statusShort }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <b>Disks:</b>
                      <div v-if="item.sumData !== null">
                        <div
                          v-for="disk in item.sumData.disks"
                          v-bind:key="disk.Drive"
                          v-bind:style="[disk.Free < 2000 ? { color: 'red' } : { color: 'green' }]"
                        >
                          {{ disk.drive }}&nbsp; {{ disk.free }} MB free
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <h3>Stats:</h3>
                      <div
                        v-if="item.sumData !== null"
                        @click="(serverStatsModal = true), (selectedServerUID = item.uid)"
                      >
                        <div>uptime: {{ item.sumData.uptime }} min</div>
                        <div>CPU: {{ item.sumData.cpu }}%</div>
                        <div>RAM: {{ item.sumData.ramused }}%</div>
                      </div>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="4">
            <v-btn tile v-if="$store.getters.userSettings.accHosting.write" block color="btnColorNew" @click="newServer"
              >New
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogEdit" width="70%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Server UID : &nbsp; {{ server.uid }}
          <v-spacer />
          <v-icon large @click="dialogEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm" ref="serverForm">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="server.name" :rules="[rules.required]" label="Name" hide-details />
                <v-text-field v-model="server.namepublic" label="Public Name" hide-details />
                <v-text-field v-model="server.ipinternal" label="Internal IP" hide-details />
                <v-text-field v-model="server.ippublic" label="Public IP" hide-details />
                <v-autocomplete
                  v-model.number="server.clientid"
                  :items="listClients"
                  no-data-text="no data - you may not have permissons read Clients"
                  eager
                  item-text="name"
                  item-value="id"
                  label="Client"
                  hide-details
                />
                <v-text-field v-model.number="server.hostingid" label="Hosting ID" type="number" hide-details />
                <v-textarea class="mr-2" v-model="server.notes" name="input-7-1" label="Notes" no-resize hide-details />
              </v-col>
              <v-col cols="6">
                <TableServersWebSites v-show="server.id != 0" @update-links="loadTableData" :server="server" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            v-show="server.id > 0 && $store.getters.userSettings.accHosting.delete"
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            tile
            v-if="
              ($store.getters.userSettings.accHosting.write && server.id == 0) ||
              $store.getters.userSettings.accHosting.update
            "
            color="btnColorConfirm"
            @click="saveServer"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="taskSchedulerLogModal" max-width="90%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          LOG : {{ selectedTaskScheduler.name }}
          <v-spacer />
          <v-icon large @click="taskSchedulerLogModal = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <TableServersTaskSchedulerLog
              :selectedTaskScheduler="selectedTaskScheduler"
              :selectedServerUID="selectedServerUID"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serverStatsModal" max-width="70%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Server stats : {{ selectedTaskScheduler.name }}
          <v-spacer />
          <v-icon large @click="serverStatsModal = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <TableServersStats :modalActive="serverStatsModal" :selectedServerUID="selectedServerUID" />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete Server?"
      body="Do you really want delete server?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteServer"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import tableMixin from '../mixins/table';
import dateFormatMixin from '../mixins/dateFormat';
import TableServersTaskSchedulerLog from '@/components/servers/tableTaskSchedulerLog.vue';
import TableServersStats from '@/components/servers/tableStats.vue';
import TableServersWebSites from '@/components/servers/tableWebSites.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiCheckBold, mdiAlertCircle, mdiClose, mdiHelpCircleOutline } from '@mdi/js';

export default Vue.extend({
  name: 'servers-page',
  mixins: [tableMixin, dateFormatMixin],
  components: {
    TableServersTaskSchedulerLog,
    TableServersStats,
    TableServersWebSites,
    ConfirmDialog,
  },
  data: () => ({
    tableData: [
      {
        uid: '',
        sumData: { timeStamp: '2020-01-01T01:00:00.0000000Z' },
        sumStatusTS: false,
        sumStatusHDD: false,
        webSites: [{ currentStatus: 0 }],
        sumStatus: false,
      },
    ],
    confirmDeleteDialog: false,
    listClients: [],
    dialogEdit: false,
    taskSchedulerLogModal: false,
    serverStatsModal: false,
    selectedTaskScheduler: {
      active: false, // By default do nothing
    },
    selectedServerUID: '',
    validForm: false,
    modalMessage: '',
    server: {
      id: 0,
      uid: '',
      clientid: 0,
      clientname: '',
      name: '',
      namepublic: '',
      ipinternal: '',
      ippublic: '',
      hostingid: 0,
      notes: '',
    },
    deleteButton: false,
    rules: {
      required: (value: any) => !!value || 'Required!!!',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'MUST be number!!!';
      },
    },
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Public name', value: 'namepublic' },
      { text: 'Client', value: 'clientid' },
      { text: 'TS status', value: 'sumStatusTS' },
      { text: 'HDD', value: 'sumStatusHDD' },
      { text: 'WEB', value: 'sumStatus' },
      { text: 'Updated', value: 'timeStamp' },
    ],
    loading: false,
    mdiCheckBold: mdiCheckBold,
    mdiAlertCircle: mdiAlertCircle,
    mdiClose: mdiClose,
    mdiHelpCircleOutline: mdiHelpCircleOutline,
  }),
  watch: {
    '$store.getters.reloadTable'() {
      // Reload server stats
      if (this.$store.getters.reloadTable.name === 'servers') {
        this.loadTableData();
        this.$store.commit('reloadTable', {});
      }

      if (this.$store.getters.reloadTable.name === 'serverStats') {
        this.reloadServerStats(this.$store.getters.reloadTable.record);
        this.$store.commit('reloadTable', { name: 'updateModalStats', record: this.$store.getters.reloadTable.record });
      }
      if (this.$store.getters.reloadTable.name === 'serverStatsWEB') {
        this.reloadServerStatsWEB(this.$store.getters.reloadTable.record);
        this.$store.commit('reloadTable', {});
      }
    },
    '$store.getters.userSettings.accClients.read'() {
      if (this.$store.getters.userSettings.accClients.read) {
        this.loadClients();
      }
    },
  },
  mounted() {
    this.loadTableData();
    if (this.$store.getters.userSettings.accClients.read) {
      this.loadClients();
    }
  },
  methods: {
    loadTableData() {
      this.loading = true;
      axios
        .get('api/v1/hostingservers')
        .then((response) => {
          this.tableData = response.data;
          this.processStatuses(response.data);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    async processStatuses(tableData: any) {
      for (const server of tableData) {
        await axios
          .get(`api/v1/hostingwebsites/${server.uid}`)
          .then((response) => {
            server.webSites = response.data;
            this.$set(server, 'sumStatus', false);
            for (const siteStatus of server.webSites) {
              if (siteStatus.currentStatus !== 200) {
                server.sumStatus = true;
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      for (const server of tableData) {
        await axios
          .get(`api/v1/serverstatusdata/${server.uid}`)
          .then((response) => {
            this.$set(server, 'sumStatusTS', false);
            this.$set(server, 'sumStatusHDD', false);
            this.$set(server, 'timeStamp', response.data.timeStamp);
            if (response.data.taskscheduler !== null) {
              for (const taskScheduler of response.data.taskscheduler) {
                this.$set(taskScheduler, 'timeErr', false);
                const lastRunTime: any = new Date(taskScheduler.timeStamp);
                const serverTime: any = new Date(taskScheduler.srvTime);
                if (taskScheduler.alertTime * 60 - (serverTime - lastRunTime) / 1000 < 0) {
                  taskScheduler.timeErr = true;
                  server.sumStatusTS = true;
                }
                if (taskScheduler.statusShort === 'ER') {
                  server.sumStatusTS = true;
                }
              }
            }
            if (response.data.disks !== null) {
              for (const disk of response.data.disks) {
                if (disk.Free < 2000) {
                  server.sumStatusHDD = true;
                }
              }
            }
            server.sumData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.tableData = tableData;
      this.loading = false;
    },
    reloadServerStatsWEB(serveruid: string) {
      axios
        .get(`api/v1/hostingwebsites/${serveruid}`)
        .then((response) => {
          for (const server of this.tableData) {
            if (server.uid === serveruid) {
              server.webSites = response.data;
              for (const site of server.webSites) {
                if (site.currentStatus !== 200) {
                  server.sumStatus = true;
                } else {
                  server.sumStatus = false;
                }
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    reloadServerStats(serveruid: string) {
      axios
        .get(`api/v1/serverstatusdata/${serveruid}`)
        .then((response) => {
          for (const server of this.tableData) {
            if (server.uid === serveruid) {
              server.sumStatusTS = false;
              if (response.data.taskscheduler !== null) {
                for (const taskScheduler of response.data.taskscheduler) {
                  taskScheduler.timeErr = false;
                  const lastRunTime: any = new Date(taskScheduler.timeStamp);
                  const serverTime: any = new Date(taskScheduler.srvTime);
                  if (taskScheduler.alertTime * 60 - (serverTime - lastRunTime) / 1000 < 0) {
                    taskScheduler.timeErr = true;
                    server.sumStatusTS = true;
                  }
                  if (taskScheduler.statusShort === 'ER') {
                    server.sumStatusTS = true;
                  }
                }
              }
              if (response.data.disks !== null) {
                for (const disk of response.data.disks) {
                  if (disk.Free < 2000) {
                    server.sumStatusHDD = true;
                  }
                }
              }
              server.sumData = response.data;
              return;
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadClients() {
      axios
        .get('api/v1/clients')
        .then((response) => {
          this.listClients = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    selectedRow(props: any) {
      if (props.expanded === true) {
        this.server = { ...props.item };
      } else {
        this.server = { ...this.server };
      }
    },
    newServer() {
      axios
        .get('api/v1/hostingserveruid')
        .then((response) => {
          const getDefaults: any = this.$options;
          this.server = { ...getDefaults.data().server };
          this.server.uid = response.data.uid;
          this.dialogEdit = true;
          this.$nextTick(function () {
            const form: any = this.$refs.serverForm;
            form.validate();
          });
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    saveServer() {
      const form: any = this.$refs.serverForm;
      if (form.validate() === true) {
        if (this.server.id === 0) {
          // If new Client go to PUT
          axios
            .post('api/v1/hostingservers', this.server)
            .then((response) => {
              this.server.id = response.data.newid;
              this.loadTableData();
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('api/v1/hostingservers', this.server)
            .then((response) => {
              this.loadTableData();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      } else {
        this.modalMessage = 'Form is not filled correctly!!!';
      }
    },
    deleteServer() {
      axios
        .delete(`api/v1/hostingservers/${this.server.id}`)
        .then((response) => {
          this.loadTableData();
          this.dialogEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
