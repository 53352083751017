import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardTitle,{staticClass:"headline headerColorStandard"},[_vm._v(" Email: "),_c(VSpacer),_c(VIcon,{attrs:{"large":""},on:{"click":function($event){return _vm.$emit('modalemail', false)}}},[_vm._v(_vm._s(_vm.mdiClose))])],1),_c(VCardText,[_c(VForm,[_c(VSelect,{attrs:{"items":_vm.contacts,"item-value":"email","item-text":"surname","label":"Client contact","hide-details":"","single-line":""},on:{"change":_vm.updateMessage},model:{value:(_vm.email.address),callback:function ($$v) {_vm.$set(_vm.email, "address", $$v)},expression:"email.address"}}),_c(VTextField,{attrs:{"hide-details":"","counter":60,"maxlength":"60","label":"Email","required":""},model:{value:(_vm.email.address),callback:function ($$v) {_vm.$set(_vm.email, "address", $$v)},expression:"email.address"}}),_c(VTextField,{attrs:{"hide-details":"","counter":60,"maxlength":"60","label":"Subject","required":""},model:{value:(_vm.email.header),callback:function ($$v) {_vm.$set(_vm.email, "header", $$v)},expression:"email.header"}}),_c(VTextarea,{staticClass:"mt-3",attrs:{"rows":"10","hide-details":"","no-resize":""},model:{value:(_vm.emailEdit.body),callback:function ($$v) {_vm.$set(_vm.emailEdit, "body", $$v)},expression:"emailEdit.body"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"tile":"","color":"btnColorConfirm"},on:{"click":_vm.sendMail}},[_vm._v("SEND")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }