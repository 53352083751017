<template>
  <v-card>
    <v-card-title class="headline headerColorStandard">
      Email:
      <v-spacer />
      <v-icon large @click="$emit('modalemail', false)">{{ mdiClose }}</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-select
          v-model="email.address"
          :items="contacts"
          item-value="email"
          item-text="surname"
          label="Client contact"
          v-on:change="updateMessage"
          hide-details
          single-line
        ></v-select>
        <v-text-field
          v-model="email.address"
          hide-details
          :counter="60"
          maxlength="60"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="email.header"
          hide-details
          :counter="60"
          maxlength="60"
          label="Subject"
          required
        ></v-text-field>
        <v-textarea v-model="emailEdit.body" rows="10" class="mt-3" hide-details no-resize></v-textarea>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn tile color="btnColorConfirm" @click="sendMail">SEND</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'notifyForm',
  props: {
    sourceData: {} as any,
  },
  components: {},
  data: () => ({
    contacts: [{}],
    selectedContact: {
      email: '',
      name: '',
      surname: '',
    },
    email: {
      address: '',
      header: '',
      body: '',
    },
    emailEdit: {
      body: '',
      header: '',
      address: '',
    },
    mdiClose: mdiClose,
  }),
  mounted() {
    this.processEmail();
  },
  watch: {
    sourceData() {
      this.processEmail();
    },
  },
  methods: {
    processEmail() {
      // let serialisation: any = { ...this.sourceData };
      let serialisation: any = Object.assign({}, this.sourceData); // Because typescript
      axios
        .get('api/v1/contacts', {
          params: {
            clientID: serialisation.client_id,
          },
        })
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((err) => {
          this.$emit('error', `err:${err.response.status}-${err.response.data.message}`);
        });
      axios
        .get('/api/v1/notifications', {
          params: {
            type: 'email',
            module: 'serialisations',
            event: 'new',
          },
        })
        .then((response) => {
          this.email = response.data;
          // const serialisation: any = { ...this.sourceData };
          this.email.body = this.email.body.replace(/%entity%/g, serialisation.entity_id);
          this.email.body = this.email.body.replace(/%serial1%/g, serialisation.serial_1);
          this.email.body = this.email.body.replace(/%serial2%/g, serialisation.serial_2);
          this.email.body = this.email.body.replace(/%serialno%/g, serialisation.client_serial);
          this.email.body = this.email.body.replace(/%mod_invoicing%/g, serialisation.mod_invoicing);
          this.email.body = this.email.body.replace(/%mod_planning%/g, serialisation.mod_planning);
          this.email.body = this.email.body.replace(/%mod_international%/g, serialisation.mod_international);
          this.email.body = this.email.body.replace(/%mod_webServices%/g, serialisation.mod_webServices);
          this.email.body = this.email.body.replace(/%users_tsh%/g, serialisation.users_tsh);
          this.email.body = this.email.body.replace(/%users_frm%/g, serialisation.users_frm);
          this.email.body = this.email.body.replace(/%users_csv%/g, serialisation.users_csv);
          this.email.body = this.email.body.replace(/%users_app%/g, serialisation.users_app);
          this.email.body = this.email.body.replace(/%period_acc%/g, serialisation.period_acc);
          this.email.body = this.email.body.replace(/%period_tsh%/g, serialisation.period_tsh);
          this.email.body = this.email.body.replace(/%validity%/g, serialisation.validity);
          this.email.body = this.email.body.replace(/%startDate%/g, serialisation.start_date);
          this.emailEdit = { ...this.email };
        })
        .catch((err) => {
          this.$emit('error', `err:${err.response.status}-${err.response.data.message}`);
        });
    },
    updateMessage() {
      let contact: any = {
        email: '',
        name: '',
        surname: '',
      };
      for (contact of this.contacts) {
        if (contact.email === this.email.address) {
          this.selectedContact = { ...contact };
          this.emailEdit = { ...this.email };
          this.emailEdit.body = this.emailEdit.body.replace(/%name%/g, this.selectedContact.name);
          this.emailEdit.body = this.emailEdit.body.replace(/%surname%/g, this.selectedContact.surname);
          return;
        }
      }
    },
    sendMail() {
      this.emailEdit.header = this.email.header;
      this.emailEdit.address = this.email.address;
      this.$emit('modalemail', false);
      axios
        .post('/api/v1/notify', this.emailEdit)
        .then((response) => console.log(response.data))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
