<template>
  <v-dialog v-if="openComputed" persistent max-width="50vw">
    <v-card color="rgb(242, 242, 242, 0.6)">
      <div style="min-height: 300px" class="text-md-center">
        <div style="min-height: 80px" />
        <v-progress-circular :size="160" :width="20" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'loaderDialog',
  props: {
    openDialog: Boolean,
  },
  computed: {
    // v-model cannot use opneDialog because it is immutable
    openComputed: function () {
      return this.openDialog;
    },
  },
});
</script>
