<template>
  <v-card height="400">
    <v-card-title class="primary">Container: </v-card-title>
    <v-card-text v-if="container.ram === 0 || loaderContainerData">
      <div v-if="loaderContainerData" style="min-height: 300px" class="text-md-center">
        <div style="min-height: 110px" />
        <v-progress-circular :size="160" :width="20" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <h3>Name: {{ container.name }}</h3>
      <span>{{ container.azureFqdn }}</span>
      <h3>
        Link:
        <a :href="`http://${container.domain}.sawcloud.net`" target="_blank"
          >http://{{ container.domain }}.sawcloud.net</a
        >
      </h3>
      <h3>Application Version: {{ container.appVersion }}</h3>
      <h3>IP: {{ container.ip }}, SSL: {{ container.ssl }}</h3>
      <h3>State: {{ container.state }}</h3>
      <h3>Prov.State: {{ container.provisioningState }}</h3>
      <v-row v-if="$store.getters.userSettings.accAzureGroups.write">
        <v-col cols="3">
          <v-btn
            block
            tile
            :disabled="container.state == 'Running' || container.state == 'Pending'"
            color="btnColorConfirm"
            @click="containerSwitchState('start')"
          >
            <v-icon large>{{ mdiPlay }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            tile
            :disabled="container.state == 'Stopped' || container.state == 'Pending'"
            color="btnColorDelete"
            @click="containerSwitchState('stop')"
          >
            <v-icon large>{{ mdiStop }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            tile
            :disabled="container.state == 'Stopped' || container.state == 'Pending'"
            color="btnColorCancel"
            @click="containerSwitchState('restart')"
          >
            <v-icon large>{{ mdiLockReset }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn block tile color="btnColorConfirm" @click="loadContainerDetails()">
            <v-icon large>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model.number="updateContainerData.containercpu"
            :items="containerCapabilitiesCPUList"
            label="Container CPU:"
            hide-details
            dense
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="updateContainerData.containerram"
            :items="containerCapabilitiesRAMList"
            label="Container RAM:"
            hide-details
            dense
          />
        </v-col>
        <v-col cols="4">
          <v-select v-model="updateContainerData.ssl" :items="sslItems" label="SSL:" hide-details dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn
            v-if="$store.getters.userSettings.accAzureGroups.write"
            block
            small
            tile
            color="btnColorConfirm"
            @click="updateContainer"
          >
            Update Config
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            v-if="$store.getters.userSettings.accAzureGroups.write"
            block
            small
            tile
            color="btnColorConfirm"
            @click="loadContainerImages(), (dialogChangeContainer = true)"
          >
            Update Container
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!---------DIALOGS----------------------------------------------------------------->
    <v-dialog v-model="dialogChangeContainer" width="400">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Change container
          <v-spacer />
          <v-icon large @click="dialogChangeContainer = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <h1>UPDATE</h1>
          <v-select
            v-model="containerImageChange.image"
            label="Image"
            :items="listContainerImages"
            item-text="version"
            item-value="image"
            hide-details
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="btnColorConfirm" @click="containerImagePut">Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoaderDialog :openDialog.sync="loaderDialog" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import LoaderDialog from '@/components/loaderDialog.vue';
import { mdiPlay, mdiStop, mdiLockReset, mdiRefresh, mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'resourceGroupsContainer',
  components: { LoaderDialog },
  props: {
    azuid: String,
    location: String,
    database: Object,
  },
  data: () => ({
    container: {
      ram: 0,
      cpu: 0,
      ssl: '',
      appVersion: '',
    },
    dialogChangeContainer: false,
    listContainerImages: [{}],
    containerImageChange: {
      azuid: '',
      domain: '',
      image: '',
    },
    containerCapabilitiesCPUList: [{}],
    containerCapabilitiesRAMList: [{}],
    sslItems: ['yes', 'no'],
    updateContainerData: {
      domain: '',
      location: '',
      product: '',
      azuid: '',
      containerram: 0,
      containercpu: 0,
      appVersion: '',
      containerImage: '',
      ssl: '',
    },
    loaderDialog: false,
    loaderContainerData: false,
    mdiPlay: mdiPlay,
    mdiStop: mdiStop,
    mdiLockReset: mdiLockReset,
    mdiRefresh: mdiRefresh,
    mdiClose: mdiClose,
  }),
  watch: {
    'database.name'() {
      if (this.database.name !== '') {
        this.container = { ram: 0, cpu: 0, ssl: '', appVersion: '' };
        this.loadContainerDetails();
      } else {
        this.container = { ram: 0, cpu: 0, ssl: '', appVersion: '' };
      }
    },
  },
  mounted() {},
  methods: {
    loadContainerImages() {
      axios
        .get(`/api/v1/azure/containerimages/${this.container.appVersion}`)
        .then((response) => {
          for (const image of response.data) {
            if (image.version === this.container.appVersion) {
              this.containerImageChange.image = image.image;
            }
          }
          this.listContainerImages = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    containerImagePut() {
      this.containerImageChange.azuid = this.azuid;
      this.containerImageChange.domain = this.database.domain;
      axios
        .put(`/api/v1/azure/container`, this.containerImageChange)
        .then(() => {})
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadContainerDetails() {
      this.loaderContainerData = true;
      axios
        .get(`/api/v1/azure/container/${this.azuid}/${this.database.domain}`)
        .then((response) => {
          for (let i = 1; i <= response.data.capabilities.maxCpu; i++) {
            this.containerCapabilitiesCPUList.push(i);
          }
          for (let i = 1; i <= response.data.capabilities.maxMemoryInGB; i++) {
            this.containerCapabilitiesRAMList.push(i);
          }
          this.container = response.data;
          this.updateContainerData.containerram = this.container.ram;
          this.updateContainerData.containercpu = this.container.cpu;
          this.updateContainerData.ssl = this.container.ssl;
          this.loaderContainerData = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
          this.loaderContainerData = false;
        });
    },
    containerSwitchState(action: string) {
      this.loaderContainerData = true;
      axios
        .get(`/api/v1/azure/container/${this.azuid}/${this.database.domain}/${action}`)
        .then((response) => {
          setTimeout(() => {
            this.loadContainerDetails();
          }, 10000);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
          this.loaderContainerData = false;
        });
    },
    updateContainer() {
      this.loaderContainerData = true;
      this.updateContainerData.azuid = this.azuid;
      this.updateContainerData.domain = this.database.domain;
      this.updateContainerData.location = this.location;
      this.updateContainerData.product = this.database.product;
      axios
        .put(`/api/v1/azure/resourceset/container`, this.updateContainerData)
        .then(() => {
          setTimeout(() => {
            this.loadContainerDetails();
          }, 5000);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
