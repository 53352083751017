import Vue from 'vue';
import store from '../store';
import VueRouter from 'vue-router';
import Login from '../views/login.vue';
import Clients from '../views/clients.vue';
import Settings from '../views/settings.vue';
import Servers from '../views/servers.vue';
import Azure from '../views/azure.vue';
import Proman from '../views/proman.vue';
import NoAccess from '../views/noaccess.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'noRoute',
    component: Login,
  },
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/servers',
    name: 'servers',
    component: Servers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/azure',
    name: 'azure',
    component: Azure,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/proman',
    name: 'proman',
    component: Proman,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/noaccess',
    name: 'noaccess',
    component: NoAccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
    //component: () => import('../views/About.vue'), - load on click
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store
      .dispatch('checkLogin')
      .then(() => {
        !store.state.serverOnline ? store.commit('serverOnline', true) : '';
        next();
      })
      .catch((err) => {
        switch (err) {
          case 'networkError':
            next(); // Allow navigation if network is down
            break;
          case 'noSession':
            if (router.currentRoute.path === from.path && to.path !== '') {
              router.push('/');
            }
            break;
          default:
            console.log('routerErr: ', err);
        }
      });
  } else {
    next();
  }
});

export default router;
