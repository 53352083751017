import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"mx-auto text-center"},[_c(VCardTitle,[_c('h2',[_vm._v("CPU")])]),_c(VSparkline,{attrs:{"value":_vm.cpuData,"smooth":10 || false,"height":150,"padding":"8","color":"rgba(50, 160, 230, 1)","line-width":"1","stroke-linecap":"round","fill":false,"type":"trend","auto-line-width":false,"auto-draw":"","auto-draw-duration":4000}})],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"mx-auto text-center"},[_c(VCardTitle,[_c('h2',[_vm._v("RAM")])]),_c(VSparkline,{attrs:{"value":_vm.ramData,"smooth":10 || false,"height":150,"padding":"8","color":"rgba(50, 160, 230, 1)","line-width":"1","stroke-linecap":"butt","fill":false,"type":"trend","auto-line-width":false,"auto-draw":"","auto-draw-duration":4000}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }