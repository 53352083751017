<template>
  <v-app v-resize="onResize">
    <offline @detected-condition="(val) => $store.commit('serverOnline', val)" />
    <v-app-bar dark fixed flat color="primary" v-if="$store.getters.user" app>
      <v-toolbar-title>
        <v-spacer />
        <span v-if="$store.getters.serverOnline">
          <v-avatar @click="activityMonitorDialog = true" color="primary" size="55">
            <img
              v-if="$store.getters.socket.isConnected && !$store.getters.userSettings.picture"
              src="./assets/statuses/online.png"
            />
            <img
              v-if="$store.getters.socket.isConnected && $store.getters.userSettings.picture"
              :src="`data:image/png;base64, ${$store.getters.userSettings.picture}`"
            />
            <img v-if="!$store.getters.socket.isConnected" src="./assets/statuses/offline.png" />
          </v-avatar>
        </span>
        <span v-else>
          <v-icon large color="red">{{ mdiAccessPointNetworkOff }}</v-icon>
        </span>
        <div style="min-width: 60px"></div>
        <v-spacer />
      </v-toolbar-title>

      <h2 class="pr-5">{{ $store.getters.userSettings.fullName }}</h2>
      <SystemNotifications />

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only">
        <v-btn v-if="$store.getters.userSettings.accClients.read" text to="/clients">
          <v-icon large>{{ mdiClipboardAccount }}</v-icon>
          <span style="width: 10px" />Clients
        </v-btn>
        <v-btn text to="/proman">
          <v-icon large>{{ mdiFloppy }}</v-icon>
          <span style="width: 10px" />Proman
        </v-btn>
        <v-btn v-if="$store.getters.userSettings.accAzureGroups.read" text to="/azure">
          <v-icon large>{{ mdiMicrosoftAzure }}</v-icon>
          <span style="width: 10px" />Azure
        </v-btn>
        <v-btn v-if="$store.getters.userSettings.accHosting.read" text to="/servers">
          <v-icon large>{{ mdiServer }}</v-icon>
          <span style="width: 10px" />Servers
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text dark v-on="on">
              <v-icon large>{{ mdiCogOutline }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="$store.getters.userSettings.accUsers.read || $store.getters.userSettings.accNotifications.read"
              to="/settings"
            >
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changePasswordDialog = true">
              <v-list-item-title>My Settings</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text @click="logout">
          <v-icon large>{{ mdiLogout }}</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-dialog v-model="wsFlashModal" max-width="40vw">
      <v-card>
        <v-card-title class="headline blue lighten-3" primary-title
          >Message from: {{ $store.getters.wsUserMessage.userfrom }}</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col>
              <h2>{{ $store.getters.wsUserMessage.message }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="btnColorCancel" @click="wsFlashModal = false">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="activityMonitorDialog" max-width="80vw">
      <ActivityMonitor :openDialog.sync="activityMonitorDialog" />
    </v-dialog>

    <v-dialog v-model="changePasswordDialog" max-width="60vw" persistent>
      <ChangePassword :openDialog.sync="changePasswordDialog" />
    </v-dialog>

    <v-snackbar v-model="errSnack" :timeout="4000" top color="red">{{ errMsg }}</v-snackbar>
    <v-snackbar v-model="appNotifySnack" :timeout="6000" top color="#EA9B1F">
      <h3>{{ appNotifyMessage }}</h3>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import VueMoment from 'vue-moment';
import offline from 'v-offline';
import axios from 'axios';
import ChangePassword from '@/components/changePassword.vue';
import SystemNotifications from '@/components/systemNotifications.vue';
import ActivityMonitor from '@/components/activityMonitor.vue';
import {
  mdiCogOutline,
  mdiServer,
  mdiMicrosoftAzure,
  mdiLogout,
  mdiClipboardAccount,
  mdiFloppy,
  mdiAccessPointNetworkOff,
} from '@mdi/js';

axios.defaults.timeout = 30000;
Vue.use(VueMoment);

export default Vue.extend({
  name: 'App',
  components: { ChangePassword, SystemNotifications, ActivityMonitor, offline },
  data: () => ({
    changePasswordDialog: false,
    activityMonitorDialog: false,
    wsFlashModal: false,
    appNotifySnack: false,
    appNotifyMessage: '',
    errSnack: false,
    errMsg: '',
    mdiLogout: mdiLogout,
    mdiClipboardAccount: mdiClipboardAccount,
    mdiFloppy: mdiFloppy,
    mdiMicrosoftAzure: mdiMicrosoftAzure,
    mdiServer: mdiServer,
    mdiCogOutline: mdiCogOutline,
    mdiAccessPointNetworkOff: mdiAccessPointNetworkOff,
  }),
  watch: {
    '$store.getters.serverOnline'() {
      this.handleConnectivityChange(this.$store.getters.serverOnline);
    },
    '$store.getters.wsUserMessage'() {
      this.wsFlashModal = true;
    },
    '$store.getters.globalMessage'() {
      if (this.$store.getters.globalMessage !== '') {
        this.errMsg = this.$store.getters.globalMessage;
        this.errSnack = true;
        this.$store.commit('globalMessage', '');
      }
    },
    '$store.getters.appNotifyMessage'() {
      if (this.$store.getters.appNotifyMessage !== '') {
        this.appNotifyMessage = this.$store.getters.appNotifyMessage;
        this.appNotifySnack = true;
        this.$store.commit('appNotifyMessage', '');
      }
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'sysnotify') {
        this.$store.dispatch('getSystemNotifications');
        this.$store.commit('reloadTable', '');
      }
      if (this.$store.getters.reloadTable.name === 'userPhoto') {
        this.$store.dispatch('checkLogin');
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.handleConnectivityChange(this.$store.getters.serverOnline).then(() => {
      this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;
    });
    this.onResize();
    // Check session every 2 minutes if not exists - logoff

    setInterval(() => {
      if (this.$store.getters.user !== '') {
        this.$store
          .dispatch('checkLogin')
          .then(() => {
            !this.$store.getters.serverOnline ? this.$store.commit('serverOnline', true) : '';
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    }, 120000);
  },
  methods: {
    async handleConnectivityChange(status: boolean) {
      console.log('online: ', status);
      await this.$store
        .dispatch('checkLogin')
        .then(() => {
          //this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;
          this.$store.dispatch('webSocketOpen');
          this.$store.dispatch('getSystemNotifications');
        })
        .catch((err) => {
          console.log('loginState: ', err);
        });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    onResize() {
      this.$store.commit('windowSize', { x: window.innerWidth, y: window.innerHeight });
    },
  },
});
</script>

<style>
.theme--dark.v-application {
  background-color: var(--v-background-base, #121212) !important;
}
.theme--light.v-application {
  background-color: var(--v-background-base, white) !important;
}

.tableCursor {
  cursor: context-menu;
}

.tableSelectCursor {
  cursor: pointer;
}

.moveCursor {
  cursor: move;
}

.textCursor {
  cursor: context-menu;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.v-dialog.v-dialog--active {
  border-radius: 10px;
  transition: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
}
.v-overlay.v-overlay--active {
  opacity: 0.1;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(127, 127, 127);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b8b8b8;
}

::-webkit-scrollbar-corner {
  background-color: #7b7b7bb8;
  border-radius: 4px;
}
</style>
