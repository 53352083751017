import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"40vw"},model:{value:(_vm.openComputed),callback:function ($$v) {_vm.openComputed=$$v},expression:"openComputed"}},[_c(VCard,[_c(VCardTitle,{class:`headline ${_vm.headerColor}`,attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.header))]),_c(VCardText,[_c('h2',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.body))])]),_c(VCardActions,[_c(VBtn,{attrs:{"tile":"","color":"btnColorDelete"},on:{"click":function($event){_vm.$emit('confirm', true), _vm.$emit('update:openDialog', false)}}},[_vm._v(_vm._s(_vm.confirmButton))]),_c(VSpacer),_c(VBtn,{attrs:{"tile":"","color":"btnColorCancel"},on:{"click":function($event){return _vm.$emit('update:openDialog', false)}}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }