import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VNavigationDrawer,{staticClass:"sideBarBackground",attrs:{"height":_vm.$store.state.windowSize.y - 50,"width":"100%","permanent":""}},[_c(VList,[_c(VListItem,{class:{ primary: _vm.currentComponent == 'bugtrack' },on:{"click":function($event){return _vm.swapComponent('bugtrack')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiBug))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("BugTrack")])],1)],1),_c(VListItem,{class:{ primary: _vm.currentComponent == 'knowledgebase' },on:{"click":function($event){return _vm.swapComponent('knowledgebase')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiBookOpenPageVariant))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Knowledgebase")])],1)],1),_c(VListItem,{class:{ primary: _vm.currentComponent == 'products' },on:{"click":function($event){return _vm.swapComponent('products')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiFloppy))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Products")])],1)],1),_c(VListItem,{class:{ primary: _vm.currentComponent == 'enhancements' },on:{"click":function($event){return _vm.swapComponent('enhancements')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.mdiHeadPlus))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Enhancements")])],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"10"}},[_c(_vm.currentComponent,{tag:"div",attrs:{"swap-component":_vm.swapComponent}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }