<template>
  <div>
    <v-icon v-if="$store.getters.listSystemNotifications.length == 0" class="pt-0" size="40" color="grey">
      {{ mdiChat }}
    </v-icon>
    <v-menu v-else v-model="systemNotifications" :close-on-content-click="false" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-badge offset-y="15" offset-x="10" color="blue" :content="$store.getters.listSystemNotifications.length">
          <v-icon
            class="pt-0"
            size="40"
            v-bind="attrs"
            v-on="on"
            v-bind:color="$store.getters.listSystemNotifications.length > 0 ? 'btnColorReport' : ''"
            >{{ mdiChatAlert }}</v-icon
          >
        </v-badge>
      </template>
      <v-card width="800">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Notifications: {{ $store.getters.listSystemNotifications.length }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn tile @click="deleteNotifications">
                <v-icon color="red">{{ mdiDeleteEmpty }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-text>
          <v-sheet style="overflow: auto" max-height="400">
            <ul id="example-1">
              <li v-for="item in $store.getters.listSystemNotifications" :key="item.ID">
                {{ item.timeStamp | moment('DD/MM - HH:mm') }} -
                <b :class="`${item.color}--text`" v-html="item.text"></b>
              </li>
            </ul>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import VueMoment from 'vue-moment';
import { mdiChat, mdiChatAlert, mdiDeleteEmpty } from '@mdi/js';

Vue.use(VueMoment);

export default Vue.extend({
  name: 'systemNotifications',
  data: () => ({
    systemNotifications: false,
    mdiChat: mdiChat,
    mdiChatAlert: mdiChatAlert,
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  methods: {
    deleteNotifications() {
      axios
        .delete('/api/v1/sysnotify')
        .then((response) => {
          this.$store.dispatch('getSystemNotifications');
          this.systemNotifications = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
