<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="4">
          <v-btn
            v-if="$store.getters.userSettings.accNotifications.update"
            color="btnColorConfirm"
            @click="saveNotification"
            block
            >SAVE</v-btn
          >
        </v-col>
      </v-row>

      <v-divider />
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title class="headline headerColorStandard">Serialisation Email :</v-card-title>
            <v-card-text>
              <v-form ref="clientForm">
                <v-text-field
                  v-model="notification.header"
                  :counter="120"
                  maxlength="120"
                  label="Subject"
                ></v-text-field>
                <v-textarea v-model="notification.body" name="input-7-3" label="Email message" no-resize></v-textarea>
                <p>
                  name, surname, serial1, serial2, entity, validity, startDate,serialno, mod_invoicing, mod_planning,
                  mod_international, mod_webServices, period_acc, period_tsh, users_tsh, users_frm, users_csv, users_app
                </p>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-btn v-if="$store.getters.userSettings.accSerialisations.special" @click="rebuildBlockChain">
            Rebuild blockChain
          </v-btn>
        </v-col>
        <v-col cols="9">
          <h2>{{ rebuildStatus }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-btn v-if="$store.getters.userSettings.accSerialisations.special" @click="getServerTime">
            Get time now
          </v-btn>
        </v-col>
        <v-col cols="6">
          <h2>{{ serverTime }}</h2>
        </v-col>
        <v-col cols="3">
          <v-btn v-if="$store.getters.userSettings.accSerialisations.special" @click="getReboot"> Reboot </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbarSave" :timeout="1500" top color="green accent-4">Save SUCCESS</v-snackbar>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'additional-settings',
  components: {},
  data() {
    return {
      snackbarSave: false,
      serverTime: '',
      notification: {
        type: '',
        module: '',
        event: '',
        header: '',
        body: '',
      },
      rebuildStatus: '',
    };
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    loadNotifications() {
      // TODO General notifications Load Update Delete
      axios
        .get('/api/v1/notifications', {
          params: {
            type: 'email',
            module: 'serialisations',
            event: 'new',
          },
        })
        .then((response) => {
          this.notification = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    saveNotification() {
      this.notification.type = 'email';
      this.notification.module = 'serialisations';
      this.notification.event = 'new';
      axios
        .post('/api/v1/notifications', this.notification)
        .then((response) => {
          this.loadNotifications();
          this.snackbarSave = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    rebuildBlockChain() {
      this.rebuildStatus = 'Working....';
      axios
        .get('/api/v1/bc/serialisations/rebuild')
        .then((response) => (this.rebuildStatus = response.data))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getServerTime() {
      axios
        .get('/api/v1/timenow')
        .then((response) => (this.serverTime = response.data.time))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getReboot() {
      axios
        .get('/api/v1/reboot')
        .then((response) => console.log('MSG: ', response.data.message))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
