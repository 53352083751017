<template>
  <v-container fluid class="py-0 px-1">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="azureGroups"
          :height="$store.getters.windowSize.y - 120"
          fixed-header
          disable-pagination
          hide-default-footer
          class="tableSelectCursor"
        >
          <template v-slot:item="{ item }">
            <tr :disabled="item.ready ? false : true" @click="item.ready ? openResourceGroupDialog(item) : ''">
              <td>{{ item.name }}</td>
              <td v-if="item.ready">{{ item.azuid }}</td>
              <td v-else><span class="red--text">Processing...</span></td>
              <td>{{ item.code }}</td>
              <td>{{ item.serial }}</td>
              <td>{{ item.location }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-btn
          v-if="$store.getters.userSettings.accAzureGroups.write"
          @click="prepareDialogNewResouceGroup"
          block
          tile
          color="btnColorNew"
          >New
        </v-btn>
      </v-col>
    </v-row>

    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogNewResouceGroup" width="50%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title
          >Create new resource group
          <v-spacer />
          <v-icon large @click="dialogNewResouceGroup = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="azureNewResourceGroup.azuretags"
                :items="clientsList"
                return-object
                item-text="name"
                item-value="azuid"
                label="Client"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="azureNewResourceGroup.location"
                :items="azureLocationsList"
                item-text="name"
                item-value="value"
                label="Location"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="btnColorConfirm" @click="createAzureResourceGroup">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResourceGroup" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="pr-5">
            Client:
            <b>{{ azureSelectedResourceGroup.name }}</b>
            , Azuid:
            <b>{{ azureSelectedResourceGroup.azuid }}</b>
          </v-toolbar-title>
          <SystemNotifications />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="dialogResourceGroup = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-sheet class="mt-2" min-height="600" :height="$store.getters.windowSize.y - 130">
            <v-row>
              <v-col class="pb-0" cols="2">
                <v-btn
                  v-if="$store.getters.userSettings.accAzureGroups.write"
                  block
                  small
                  tile
                  color="btnColorConfirm"
                  @click="newResourceSet"
                  >New Resource Set</v-btn
                >
              </v-col>
              <v-col class="pb-0" cols="2">
                <v-btn
                  v-if="$store.getters.userSettings.accAzureGroups.delSet"
                  block
                  small
                  tile
                  color="btnColorDelete"
                  @click="dialogResourceSetDelete = true"
                  >Delete Resource Set</v-btn
                >
              </v-col>
              <v-col class="pb-0" cols="2" />
              <v-col class="pb-0" cols="2">
                <v-btn
                  v-if="$store.getters.userSettings.accAzureGroups.delGroup"
                  block
                  small
                  tile
                  color="btnColorDelete"
                  @click="dialogResourceGroupDelete = true"
                >
                  DELETE GROUP
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :items="listResourceSets"
                  :headers="headersResourceSets"
                  hide-default-footer
                  disable-pagination
                  dense
                  :height="$store.getters.windowSize.y - 545"
                  class="elevation-2 tableSelectCursor"
                >
                  <template v-slot:item="{ item }">
                    <tr
                      @click="item.ready ? (azureSelectedResourceSet = item) : ''"
                      :disabled="item.ready ? false : true"
                      v-bind:class="{ rowColorSelected: azureSelectedResourceSet.domain == item.domain }"
                    >
                      <td>{{ item.domain }}</td>
                      <td>{{ item.database.product }}</td>
                      <td>{{ item.database.edition }}</td>
                      <td v-if="item.ready"><span class="green--text">ready</span></td>
                      <td v-else><span class="red--text">maintenance</span></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="4">
                <Database
                  :azuid="azureSelectedResourceGroup.azuid"
                  :location="azureSelectedResourceGroup.location"
                  :sqlCapabilities="sqlCapabilities"
                  :database="azureSelectedResourceSet.database"
                />
              </v-col>
              <v-col class="py-0" cols="4">
                <Files :azuid="azureSelectedResourceGroup.azuid" :database="azureSelectedResourceSet.database" />
              </v-col>
              <v-col class="py-0" cols="4">
                <Container
                  :azuid="azureSelectedResourceGroup.azuid"
                  :location="azureSelectedResourceGroup.location"
                  :database="azureSelectedResourceSet.database"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNewResourceSet" persistent width="700">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Create new resource set
          <v-spacer />
          <v-icon large @click="dialogNewResourceSet = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="10">
              <v-text-field
                v-model="azureNewResourceSet.domain"
                maxlength="128"
                label="Domain name:"
                hide-details
                dense
              />
            </v-col>
            <v-col class="mt-3 pl-0" cols="2">
              <b>.sawcloud.net</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="azureNewResourceSet.product"
                :items="productItems"
                label="Product:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="azureNewResourceSet.database"
                :items="listBlobDBFiles"
                item-text="name"
                item-value="name"
                label="Database:"
                :append-outer-icon="mdiFolderEdit"
                @click:append-outer="openBlobDBsDialog = true"
                hide-details
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="sqlCapabilitiesSelectedEdition"
                :items="sqlCapabilities"
                item-text="edition"
                return-object
                v-on:change="sqlCapabilitiesSelectedObjective = []"
                label="SQL edition:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="sqlCapabilitiesSelectedObjective"
                :items="sqlCapabilitiesSelectedEdition.objectives"
                item-text="perf.value"
                return-object
                label="SQL DTU:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="sqlCapabilitiesSelectedMaxSize"
                :items="sqlCapabilitiesSelectedObjective.sizes"
                item-text="limit"
                return-object
                label="Max DB size:"
                hide-details
                dense
              >
                <template slot="selection" slot-scope="data"> {{ data.item.limit }} {{ data.item.unit }} </template>
                <template slot="item" slot-scope="data">{{ data.item.limit }} {{ data.item.unit }} </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model.number="azureNewResourceSet.containercpu"
                :items="containerCapabilitiesCPUList"
                label="Container CPU:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="azureNewResourceSet.containerram"
                :items="containerCapabilitiesRAMList"
                label="Container RAM:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="4">
              <v-select v-model="azureNewResourceSet.ssl" :items="sslItems" label="SSL:" hide-details dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col><h3 class="red--text">Database MUST be version 6.3.0 or later!!!</h3></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn tile @click="createAzureResourceSet" color="btnColorConfirm">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResourceSetDelete" width="60%">
      <v-card>
        <v-card-title class="headline headerColorDelete" primary-title>Delete resource set?</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h2 class="red--text">
                Keep in mind this will delete ALL the data and backups!!!<br />
                This Operation is irreversible!!! <br />
                If you really want delete it please type DOMAIN name and press delete.
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="ResourceSetDeleteDomainConfirm" label="Domain name" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn tile color="btnColorDelete" @click="deleteAzureResourceSet"> Delete </v-btn>
          <v-spacer />
          <v-btn tile color="btnColorCancel" @click="dialogResourceSetDelete = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResourceGroupDelete" width="60%">
      <v-card>
        <v-card-title class="headline headerColorDelete" primary-title>Delete resource group?</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h2 class="red--text">
                Keep in mind this will delete ALL the data and backups for ALL resource sets!!!<br />
                This Operation is irreversible!!! <br />
                If you really want delete it please type AZUID and press delete.
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="ResourceGroupDeleteAzuidConfirm" label="AZUID" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn tile color="btnColorDelete" @click="deleteAzureResourceGroup(azureSelectedResourceGroup.azuid)">
            Delete
          </v-btn>
          <v-spacer />
          <v-btn tile color="btnColorCancel" @click="dialogResourceGroupDelete = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoaderDialog :openDialog.sync="loaderDialog" />

    <BlobDBsDialog
      :azuid="this.azureSelectedResourceGroup.azuid"
      :openDialog.sync="openBlobDBsDialog"
      v-on:reload="getBlobDatabases"
    />

    <WarningDialog
      headerColor="headerColorDelete"
      :header="warningHeaderText"
      :body="warningBodyText"
      :openDialog.sync="warningDialog"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import BlobDBsDialog from '@/components/azure/resourceGroupsBlobDBs.vue';
import Database from '@/components/azure/resourceGroupsDatabase.vue';
import Files from '@/components/azure/resourceGroupsFiles.vue';
import Container from '@/components/azure/resourceGroupsContainer.vue';
import SystemNotifications from '@/components/systemNotifications.vue';
import WarningDialog from '@/components/warningDialog.vue';
import LoaderDialog from '@/components/loaderDialog.vue';
import { mdiClose, mdiFolderEdit } from '@mdi/js';

export default Vue.extend({
  name: 'azureGroups',
  components: { BlobDBsDialog, SystemNotifications, Database, Files, Container, WarningDialog, LoaderDialog },
  data: () => ({
    azureGroups: [],
    azureLocationsList: [],
    clientsList: [
      {
        azuid: '',
      },
    ],
    listBlobDBFiles: [],
    listResourceSets: [],
    listDnsRecords: [],
    sqlCapabilities: [],
    sqlCapabilitiesSelectedEdition: {
      id: 0,
      name: '',
      objectives: [],
    },
    sqlCapabilitiesSelectedObjective: {
      uuid: 0,
      sizes: [],
    },
    sqlCapabilitiesSelectedMaxSize: {
      limit: 0,
      unit: '',
      status: '',
    },
    containerCapabilities: {},
    containerCapabilitiesCPUList: [{}],
    containerCapabilitiesRAMList: [{}],
    openBlobDBsDialog: false,
    productItems: ['TAW', 'FAW', 'EAW'],
    sslItems: ['yes', 'no'],
    dialogNewResouceGroup: false,
    dialogNewResourceSet: false,
    dialogResourceGroup: false,
    dialogResourceGroupDelete: false,
    ResourceGroupDeleteAzuidConfirm: '', //Confirmation of AZUID which should be deleted
    dialogResourceSetDelete: false,
    ResourceSetDeleteDomainConfirm: '', //Confirmation of Domain which should be deleted
    loaderDialog: false,
    azureSelectedResourceGroup: {
      azuid: '',
      name: '',
      location: '',
    },
    azureNewResourceGroup: {
      azuretags: {
        azuid: '',
      },
      location: '',
    },
    azureNewResourceSet: {
      domain: '',
      location: '',
      product: '',
      edition: '',
      objectiveid: 0,
      sizelimit: '',
      database: '',
      azuid: '',
      containerram: 2,
      containercpu: 2,
      ssl: '',
    },
    azureSelectedResourceSet: {
      domain: '',
      database: {
        name: '',
        collation: '',
        product: '',
      },
    },
    headers: [
      { text: 'Client', value: 'name', width: '100' },
      { text: 'Azuid', value: 'azuid', width: '100' },
      { text: 'Code', value: 'code', width: '200' },
      { text: 'Serial', value: 'serial', width: '200' },
      { text: 'Location', value: 'location', width: '200' },
    ],
    headersResourceSets: [
      { text: 'Domain', value: 'domain', width: '100' },
      { text: 'Product', value: 'database.product', width: '100' },
      { text: 'DB edition', value: 'database.edition', width: '200' },
      { text: 'State', value: 'ready', width: '100' },
    ],
    warningHeaderText: '',
    warningBodyText: '',
    warningDialog: false,
    mdiClose: mdiClose,
    mdiFolderEdit: mdiFolderEdit,
  }),
  watch: {
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'azureResourceGroups') {
        this.loadAzureGroups();
        this.$store.commit('reloadTable', '');
      }
      if (this.$store.getters.reloadTable.name === 'azureResourceSet') {
        this.loadResourceSets();
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadClients().then(() => {
      this.loadAzureGroups();
    });
    this.loadDnsRecords();
    this.loadAzureLocations();
  },
  methods: {
    newResourceSet() {
      this.azureNewResourceSet.domain = '';
      this.azureNewResourceSet.product = '';
      this.azureNewResourceSet.database = '';
      this.sqlCapabilitiesSelectedEdition = this.sqlCapabilities[0]; // Basic
      this.sqlCapabilitiesSelectedObjective = this.sqlCapabilitiesSelectedEdition.objectives[0]; // Lowest available
      this.$nextTick(function () {
        let size = {
          limit: 0,
          unit: '',
          status: '',
        };
        for (size of this.sqlCapabilitiesSelectedObjective.sizes) {
          if (size.status === 'Default') {
            this.sqlCapabilitiesSelectedMaxSize = size;
          }
        }
        this.dialogNewResourceSet = true;
      });
    },
    async loadAzureGroups() {
      await axios
        .get(`api/v1/azure/groups`)
        .then((response) => {
          this.azureGroups = response.data;
          let group = { azuid: '', ready: false };
          for (group of this.azureGroups) {
            let client = { azuid: '' };
            for (client of this.clientsList) {
              if (client.azuid === group.azuid || (client.azuid === group.azuid && group.ready === false)) {
                const removeIndex = this.clientsList
                  .map(function (item) {
                    return item.azuid;
                  })
                  .indexOf(client.azuid);
                this.clientsList.splice(removeIndex, 1);
                continue;
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadClients() {
      return new Promise((resolve) => {
        axios
          .get('api/v1/clients', {
            params: {
              closed: false,
            },
          })
          .then((response) => {
            this.clientsList = response.data;
            resolve(true);
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      });
    },
    loadAzureLocations() {
      axios
        .get('api/v1/azure/locations')
        .then((response) => {
          this.azureLocationsList = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    createAzureResourceGroup() {
      if (this.azureNewResourceGroup.azuretags.azuid === '' || this.azureNewResourceGroup.location === '') {
        this.$store.commit('globalMessage', 'Client and Location must be set');
      } else {
        this.dialogNewResouceGroup = false;
        axios
          .post('api/v1/azure/groups', this.azureNewResourceGroup)
          .then(() => {
            this.warningHeaderText = 'Creating resource group:';
            this.warningBodyText =
              'Creating resource group is in progress, this action can take up to 10min. You will get system notification once it will be ready';
            this.warningDialog = true;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    createAzureResourceSet() {
      this.azureNewResourceSet.azuid = this.azureSelectedResourceGroup.azuid;
      this.azureNewResourceSet.location = this.azureSelectedResourceGroup.location;
      this.azureNewResourceSet.edition = this.sqlCapabilitiesSelectedEdition.name;
      this.azureNewResourceSet.objectiveid = this.sqlCapabilitiesSelectedObjective.uuid;
      this.azureNewResourceSet.sizelimit = this.convertToBytes(
        this.sqlCapabilitiesSelectedMaxSize.limit,
        this.sqlCapabilitiesSelectedMaxSize.unit,
      ).toString();
      const nameCheck = new RegExp('^[a-z][a-z0-9-]+$');
      if (nameCheck.test(this.azureNewResourceSet.domain) === false) {
        this.$store.commit(
          'globalMessage',
          'domain name must not be empty. Only a-z,0-9 and dash are allowed. Name must begin with letter:',
        );
        return;
      }
      let record = { cname: '', azuid: '' };
      for (record of this.listDnsRecords) {
        if (record.cname === this.azureNewResourceSet.domain) {
          this.$store.commit('globalMessage', `Domain name already in use under resource group azuid: ${record.azuid}`);
          return;
        }
      }
      if (this.azureNewResourceSet.product === '') {
        this.$store.commit('globalMessage', `Product must be selected`);
      }
      if (this.azureNewResourceSet.database === '') {
        this.$store.commit('globalMessage', `Database must be selected`);
        return;
      }
      if (this.azureNewResourceSet.ssl === '') {
        this.$store.commit('globalMessage', `SSL must be selected`);
        return;
      }
      this.dialogNewResourceSet = false;
      axios
        .post('api/v1/azure/resourceset', this.azureNewResourceSet)
        .then((response) => {
          this.warningHeaderText = 'Creating resource set:';
          this.warningBodyText =
            'Creating resource set is in progress, this action can take very long time (half an hour or more). You will get system notification once it will be ready';
          this.warningDialog = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteAzureResourceSet() {
      if (this.ResourceSetDeleteDomainConfirm !== this.azureSelectedResourceSet.domain) {
        this.$store.commit('globalMessage', 'Wrong DOMAIN name');
      } else {
        this.$store.commit('globalMessage', 'Delete resource set in progress...');
        this.dialogResourceSetDelete = false;
        axios
          .delete(
            `api/v1/azure/resourceset/${this.azureSelectedResourceGroup.azuid}/${this.azureSelectedResourceSet.domain}`,
          )
          .then((response) => {
            this.loadDnsRecords();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    deleteAzureResourceGroup(azuid: string) {
      if (this.ResourceGroupDeleteAzuidConfirm !== azuid) {
        this.$store.commit('globalMessage', 'Wrong AZUID');
      } else {
        this.dialogResourceGroupDelete = false;
        this.loaderDialog = true;
        this.$store.commit('globalMessage', 'Delete resource group in progress...');
        axios
          .delete(`api/v1/azure/groups/${azuid}`)
          .then((response) => {
            if (response.data.message === 'deleteInProgress') {
              this.loadAzureGroups();
              this.dialogResourceGroup = false;
              this.loaderDialog = false;
            }
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
            this.loaderDialog = false;
          });
      }
    },
    getBlobDatabases() {
      axios
        .get(`/api/v1/azure/blob/database/${this.azureSelectedResourceGroup.azuid}`)
        .then((response) => {
          this.listBlobDBFiles = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openResourceGroupDialog(item: any) {
      this.listResourceSets = [];
      const getDefaults: any = this.$options;
      this.azureSelectedResourceSet = { ...getDefaults.data().azureSelectedResourceSet };
      this.azureSelectedResourceGroup = item;
      this.loadResourceSets();
      this.listBlobDBFiles = [];
      this.getBlobDatabases();
      this.loadSQLCapabilities();
      this.loadContainerCapabilities();
      this.dialogResourceGroup = true;
    },
    loadResourceSets() {
      this.loaderDialog = true;
      axios
        .get(`/api/v1/azure/resourceset/${this.azureSelectedResourceGroup.azuid}`)
        .then((response) => {
          this.loaderDialog = false;
          this.listResourceSets = response.data;
        })
        .catch((err) => {
          this.loaderDialog = false;
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadSQLCapabilities() {
      axios
        .get(`/api/v1/azure/capabilities/sql/${this.azureSelectedResourceGroup.location}`)
        .then((response) => {
          this.sqlCapabilities = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadContainerCapabilities() {
      axios
        .get(`/api/v1/azure/capabilities/container/${this.azureSelectedResourceGroup.location}`)
        .then((response) => {
          for (let i = 1; i <= response.data.maxCpu; i++) {
            this.containerCapabilitiesCPUList.push(i);
          }
          for (let i = 1; i <= response.data.maxMemoryInGB; i++) {
            this.containerCapabilitiesRAMList.push(i);
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadDnsRecords() {
      axios
        .get('/api/v1/azure/dns/')
        .then((response) => {
          this.listDnsRecords = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    convertToBytes(value: number, unit: string) {
      if (unit === 'Megabytes') {
        return value * 1024 * 1024;
      }
      if (unit === 'Gigabytes') {
        return value * 1024 * 1024 * 1024;
      }
      if (unit === 'Terabytes') {
        return value * 1024 * 1024 * 1024 * 1024;
      }
      return 0;
    },
    prepareDialogNewResouceGroup() {
      this.loadAzureGroups().then(() => {
        this.azureNewResourceGroup.azuretags = { azuid: '' };
        this.azureNewResourceGroup.location = '';
        this.dialogNewResouceGroup = true;
      });
    },
  },
});
</script>
