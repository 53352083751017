<template>
  <v-dialog v-model="openComputed" persistent max-width="60vw">
    <v-card>
      <v-card-title class="headline headerColorStandard" primary-title>
        Blob storage databases:
        <v-spacer />
        <v-icon large @click="$emit('update:openDialog', false), $emit('reload', true)">{{ mdiClose }}</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-2" v-if="$store.getters.userSettings.accAzureGroups.write">
          <v-col cols="9">
            <v-file-input v-model="fileUploadDB" append-icon dense show-size label="Add Database" />
          </v-col>
          <v-col cols="3">
            <v-btn tile dense block @click="filesDBUpload">Upload</v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :items="blobDBFiles"
          hide-default-footer
          disable-pagination
          dense
          :height="340"
          class="elevation-2 tableSelectCursor"
        >
          <template v-slot:item="{ item }">
            <tr style="max-width: 100%">
              <td width="55%">{{ item.name }}</td>
              <td width="25%">{{ item.size }}</td>
              <td width="10%" class="text-xs">
                <v-btn icon :href="`/api/v1/azure/blob/database/${azuid}/${item.name}`">
                  <v-icon>{{ mdiFileDownload }}</v-icon>
                </v-btn>
              </td>
              <td width="10%" class="text-xs">
                <v-icon
                  v-if="$store.getters.userSettings.accAzureGroups.write"
                  color="red"
                  @click="filesDBDelete(item.name)"
                  >{{ mdiDeleteEmpty }}</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiClose, mdiDeleteEmpty, mdiFileDownload } from '@mdi/js';

export default Vue.extend({
  name: 'resourceGroupsBlobDBs',
  props: {
    openDialog: Boolean,
    azuid: String,
  },
  data: () => ({
    blobDBFiles: [],
    fileUploadDB: [],
    mdiClose: mdiClose,
    mdiDeleteEmpty: mdiDeleteEmpty,
    mdiFileDownload: mdiFileDownload,
  }),
  watch: {
    openDialog() {
      if (this.openDialog === true) {
        this.getBlobDatabases();
      }
    },
  },
  computed: {
    // v-model cannot use opnDialog because it is immutable
    openComputed: function () {
      return this.openDialog;
    },
  },
  mounted() {
    // this.getBlobDatabases();
  },
  methods: {
    getBlobDatabases() {
      axios
        .get(`/api/v1/azure/blob/database/${this.azuid}`)
        .then((response) => {
          for (const file of response.data) {
            file.size = Math.round(file.size / 1024);
            if (file.size >= 1024) {
              file.size = `${Math.round(file.size / 1024)} MB`;
            } else {
              file.size = `${file.size} kB`;
            }
          }
          this.blobDBFiles = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    filesDBUpload() {
      if (this.fileUploadDB != undefined && this.fileUploadDB.length != 0) {
        const formData: any = new FormData();
        formData.append('file', this.fileUploadDB);
        axios
          .post(`/api/v1/azure/blob/database/${this.azuid}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            this.getBlobDatabases();
            this.fileUploadDB = [];
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
            this.fileUploadDB = [];
          });
      }
    },
    filesDBDelete(file: string) {
      axios
        .delete(`/api/v1/azure/blob/database/${this.azuid}/${file}`)
        .then((response) => {
          this.getBlobDatabases();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
