<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title>
            <h2>CPU</h2>
          </v-card-title>
          <v-sparkline
            :value="cpuData"
            :smooth="10 || false"
            :height="150"
            padding="8"
            color="rgba(50, 160, 230, 1)"
            line-width="1"
            stroke-linecap="round"
            :fill="false"
            type="trend"
            :auto-line-width="false"
            auto-draw
            :auto-draw-duration="4000"
          ></v-sparkline>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto text-center">
          <v-card-title>
            <h2>RAM</h2>
          </v-card-title>
          <v-sparkline
            :value="ramData"
            :smooth="10 || false"
            :height="150"
            padding="8"
            color="rgba(50, 160, 230, 1)"
            line-width="1"
            stroke-linecap="butt"
            :fill="false"
            type="trend"
            :auto-line-width="false"
            auto-draw
            :auto-draw-duration="4000"
          ></v-sparkline>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'tableServersStats',
  props: {
    selectedServerUID: String,
    modalActive: Boolean,
  },
  data: () => ({
    cpuData: [0, 100],
    ramData: [0, 0],
  }),
  watch: {
    modalActive() {
      if (this.modalActive) {
        this.loadTableData();
      }
    },
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      axios
        .get(`api/v1/serverstats/${this.selectedServerUID}`)
        .then((response) => {
          this.cpuData = [0]; // defines min value
          this.ramData = [0];
          for (const data of response.data) {
            this.cpuData.push(data.cpu);
          }
          this.cpuData.push(100); // defines max value

          this.ramData = [0]; // defines min value
          for (const data of response.data) {
            this.ramData.push(data.ram);
          }
          this.ramData.push(100); // defines max value
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
