<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="tableData"
          fixed-header
          disable-pagination
          hide-default-footer
          height="500"
          class="tableCursor"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-xs">{{ item.time }}</td>
              <td class="text-xs">{{ item.statusShort }}</td>
              <td class="text-xs">{{ item.taskName }}</td>
              <td class="text-xs">{{ item.taskType }}</td>
              <td class="text-xs">{{ item.status }}</td>
              <td class="text-xs">{{ item.note }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'tableTaskSchedulerLog',
  props: {
    selectedTaskScheduler: Object,
    selectedServerUID: String,
  },
  data: () => ({
    tableData: [],
    headers: [
      { text: 'time', value: 'time', width: '10%', sortable: false },
      { text: 'stat', value: 'statusShort', width: '5%', sortable: false },
      { text: 'taskName', value: 'taskName', width: '15%', sortable: false },
      { text: 'taskType', value: 'taskType', width: '10%', sortable: false },
      { text: 'status', value: 'status', width: '25%', sortable: false },
      { text: 'note', value: 'note', width: '35%', sortable: false },
    ],
  }),
  watch: {
    '$store.getters.reloadTable'() {
      // Reload server stats
      if (this.$store.getters.reloadTable.name === 'updateModalStats') {
        this.loadTableData();
      }
    },
    selectedTaskScheduler() {
      if (this.selectedTaskScheduler.active === true) {
        this.loadTableData();
      }
    },
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    loadTableData() {
      axios
        .get(`api/v1/servertaskscheduler`, {
          params: {
            name: this.selectedTaskScheduler.name,
            uid: this.selectedServerUID,
          },
        })
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
