<template>
  <v-card>
    <v-card-title class="primary">SQL server: </v-card-title>
    <v-card-text class="py-2">
      <v-row class="pt-2">
        <v-col cols="6">
          <v-btn tile block small color="btnColorEdit" @click="getFirewallRules(), (firewallDialog = true)"
            >Firewall
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn tile block small @click="openBlobDBsDialog = true" color="btnColorEdit">List backups </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="6">
          <v-btn
            v-if="database.name && $store.getters.userSettings.accAzureGroups.write"
            block
            small
            tile
            color="btnColorConfirm"
            @click="connectSQL"
          >
            Query
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            v-if="database.name && $store.getters.userSettings.accAzureGroups.write"
            block
            small
            tile
            color="btnColorConfirm"
            @click="createDatabaseBackup"
          >
            Backup now
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="database.name">
        <v-col>
          Product: {{ database.product }} <br />
          Database: {{ database.name }} <br />
          Collation: {{ database.collation }} <br />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="database.name">
        <v-col cols="4">
          <v-select
            v-model="sqlCapabilitiesSelectedEdition"
            :items="sqlCapabilities"
            item-text="edition"
            return-object
            hide-details
            v-on:change="sqlCapabilitiesSelectedObjective = []"
            label="Edition:"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="sqlCapabilitiesSelectedObjective"
            :items="sqlCapabilitiesSelectedEdition.objectives"
            item-text="perf.value"
            hide-details
            return-object
            label="DTU:"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="sqlCapabilitiesSelectedMaxSize"
            :items="sqlCapabilitiesSelectedObjective.sizes"
            item-text="limit"
            hide-details
            return-object
            label="size:"
          >
            <template slot="selection" slot-scope="data"> {{ data.item.limit }} {{ data.item.unit }} </template>
            <template slot="item" slot-scope="data">{{ data.item.limit }} {{ data.item.unit }} </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="database.name && $store.getters.userSettings.accAzureGroups.write">
        <v-col>
          <v-btn tile block small color="btnColorConfirm" @click="updateDatabase">Update configuration</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!---------DIALOGS----------------------------------------------------------------->
    <v-dialog v-model="queryEditorDialog" persistent fullscreen max-width="90vw">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Query editor: db: {{ database.name }} server: {{ database.prefix }}-sql-{{ azuid }}.database.windows.net
          <v-spacer />
          <v-icon large @click="closeSQL">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-1">
            <v-col cols="2">
              <v-sheet style="overflow: scroll" height="252">
                <div
                  v-for="table in sqlTables"
                  :key="table.name"
                  @click="newQuery = newQuery + table.name + ' '"
                  class="tableSelectCursor disable-select"
                  style="white-space: nowrap"
                >
                  {{ table.name }}
                </div>
              </v-sheet>
            </v-col>
            <v-col cols="10">
              <codemirror
                ref="codeMirrorBox"
                v-model="newQuery"
                :options="{
                  tabSize: 2,
                  mode: 'text/x-mssql',
                  theme: $vuetify.theme.dark ? 'moxer' : 'neo',
                  lineNumbers: false,
                  lineWrapping: false,
                  line: true,
                  border: true,
                }"
                style="font-size: 18px; border-width: 1px; border-style: solid"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pt-0">
              <v-btn tile block color="btnColorConfirm" @click="querySQL">query (CTRL+Q)</v-btn>
            </v-col>
            <v-col cols="2" class="pt-0">
              <v-btn tile block color="btnColorCancel" @click="(newQuery = ''), (selectedQuery = { id: 0 })">
                clear
              </v-btn>
            </v-col>
            <v-col cols="2" class="pt-0">
              <v-select
                v-model="selectedQuery"
                :items="listQueries"
                item-text="name"
                return-object
                v-on:change="newQuery = selectedQuery.query"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="2" class="pt-0">
              <v-text-field v-model="newQueryName" label="Name" outlined hide-details dense />
            </v-col>
            <v-col cols="1" class="pt-0">
              <v-btn tile block @click="postNewQuery" color="btnColorConfirm">Save</v-btn>
            </v-col>
            <v-col cols="2" />
            <v-col cols="1" class="pt-0">
              <v-btn tile block @click="confirmDeleteQueryDialog = true" color="btnColorDelete">Delete</v-btn>
            </v-col>
          </v-row>

          <div v-if="sqlResponseError">
            <v-sheet height="430">
              <h3 class="red--text">{{ sqlResponseError }}</h3>
            </v-sheet>
          </div>
          <v-data-table
            v-else
            :items="sqlResponseData"
            :headers="sqlResponseHeaders"
            :footer-props="{ itemsPerPageOptions: [20, 40, 60, 80, 100] }"
            :options="{ itemsPerPage: 40 }"
            fixed-header
            dense
            :height="$store.getters.windowSize.y - 450"
            class="elevation-2 tableSelectCursor"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="firewallDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Firewall:
          <v-spacer />
          <v-icon large @click="firewallDialog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-2" v-if="$store.getters.userSettings.accAzureGroups.write">
            <v-col cols="4">
              <v-text-field v-model="sqlNewFirewallRule.name" label="Name" hide-details dense />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="sqlNewFirewallRule.startip" label="Start IP" hide-details dense />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="sqlNewFirewallRule.endip" label="End IP" hide-details dense />
            </v-col>
            <v-col cols="2">
              <v-btn tile block small color="btnColorConfirm" @click="addFirewallRule">Add</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :items="sqlFirewallRules"
                :headers="headersFirewall"
                hide-default-footer
                disable-pagination
                dense
                :height="340"
                class="elevation-2 tableSelectCursor"
              >
                <template v-slot:item="{ item }">
                  <tr style="max-width: 100%">
                    <td width="30%">{{ item.name }}</td>
                    <td width="20%">{{ item.startip }}</td>
                    <td width="25%">{{ item.endip }}</td>
                    <td width="10%" class="text-xs">
                      <v-icon color="red" @click="deleteFirewallRule(item.name)">{{ mdiDeleteEmpty }}</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <WarningDialog
      headerColor="btnColorDelete"
      :header="warningHeaderText"
      :body="warningBodyText"
      :openDialog.sync="warningDialog"
    />
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete query?"
      :body="'Do you really want delete stored query: ' + selectedQuery.name + '?'"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteQueryDialog"
      v-on:confirm="deleteStoredQuery"
    />
    <LoaderDialog :openDialog.sync="loaderDialog" />

    <BlobDBsDialog :azuid="azuid" :openDialog.sync="openBlobDBsDialog" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import WarningDialog from '@/components/warningDialog.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import LoaderDialog from '@/components/loaderDialog.vue';
import BlobDBsDialog from '@/components/azure/resourceGroupsBlobDBs.vue';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/moxer.css';
import 'codemirror/theme/neo.css';
import 'codemirror/mode/sql/sql.js';

import { mdiClose, mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'resourceGroupsBlobDBs',
  components: { WarningDialog, ConfirmDialog, LoaderDialog, BlobDBsDialog, codemirror },
  props: {
    azuid: String,
    location: String,
    sqlCapabilities: Array,
    database: Object,
  },
  data: () => ({
    queryEditorDialog: false,
    firewallDialog: false,
    listQueries: [],
    sqlResponseData: [],
    sqlResponseHeaders: [{ text: '', value: '', width: 0 }],
    sqlResponseError: '',
    sqlFirewallRules: [],
    sqlTables: [],
    sqlCapabilitiesSelectedEdition: {
      id: 0,
      name: '',
      objectives: [],
    },
    sqlCapabilitiesSelectedEditionDetail: [],
    sqlCapabilitiesSelectedObjective: {
      uuid: 0,
      sizes: [],
    },
    sqlCapabilitiesSelectedMaxSize: {
      limit: 0,
      unit: '',
      status: '',
    },
    backupDatabase: {
      azuid: '',
      domain: '',
      product: '',
    },
    sqlNewFirewallRule: {
      azuid: '',
      name: '',
      startip: '',
      endip: '',
    },
    updateDatabaseData: {
      domain: '',
      location: '',
      product: '',
      edition: '',
      objectiveid: 0,
      sizelimit: '',
      database: '',
      azuid: '',
    },
    headersFirewall: [
      { text: 'Name', value: 'name' },
      { text: 'StartIP', value: 'startip' },
      { text: 'EndIP', value: 'endip' },
      { text: '', value: '' },
    ],
    selectedQuery: {
      id: 0,
    },
    newQueryName: '',
    newQuery: '',
    confirmDeleteQueryDialog: false,
    openBlobDBsDialog: false,
    listBlobDBFiles: [],
    warningHeaderText: '',
    warningBodyText: '',
    warningDialog: false,
    loaderDialog: false,
    mdiClose: mdiClose,
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    'database.name'() {
      let capability: any;
      for (capability of this.sqlCapabilities) {
        if (capability.edition === this.database.edition) {
          this.sqlCapabilitiesSelectedEdition = capability;
          let objective: any;
          for (objective of this.sqlCapabilitiesSelectedEdition.objectives) {
            if (objective.uuid === this.database.objectiveID) {
              this.sqlCapabilitiesSelectedObjective = objective;
              let size = { limit: 0, unit: '', status: '' };
              for (size of this.sqlCapabilitiesSelectedObjective.sizes) {
                if (parseInt(this.database.sizelimit) === this.convertToBytes(size.limit, size.unit)) {
                  this.sqlCapabilitiesSelectedMaxSize = size;
                }
              }
            }
          }
        }
      }
    },
  },
  mounted() {
    this.getListQueries();
  },
  methods: {
    connectSQL() {
      this.sqlResponseData = [];
      this.sqlResponseError = '';
      document.addEventListener('keydown', this.listenQueryKeys);
      axios
        .get(`/api/v1/azure/sql/connect/${this.azuid}/${this.database.domain}`)
        .then((response) => {
          this.sqlTables = response.data;
          this.queryEditorDialog = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    querySQL() {
      this.sqlResponseData = [];
      this.sqlResponseError = '';
      axios
        .post(`/api/v1/azure/sql/query`, { query: this.getQuerySelection() })
        .then((response) => {
          if (response.data.data.length === 0) {
            this.sqlResponseError = 'done!';
          } else {
            this.sqlResponseData = response.data.data;
            this.sqlResponseHeaders = [];
            for (const header of response.data.columns) {
              this.sqlResponseHeaders.push({ text: header, value: header, width: header.length * 15 });
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
          this.sqlResponseError = err.response.data.message;
        });
    },
    closeSQL() {
      document.removeEventListener('keydown', this.listenQueryKeys);
      axios
        .get(`/api/v1/azure/sql/close`)
        .then((response) => {
          this.sqlResponseData = [];
          this.sqlResponseError = '';
          this.queryEditorDialog = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getFirewallRules() {
      this.sqlNewFirewallRule.name = '';
      this.sqlNewFirewallRule.startip = '';
      this.sqlNewFirewallRule.endip = '';
      axios
        .get(`/api/v1/azure/sql/firewallrules/${this.azuid}`)
        .then((response) => {
          this.sqlFirewallRules = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    addFirewallRule() {
      const ipCheck = new RegExp('^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).?){4}$');
      const nameCheck = new RegExp('^[a-zA-Z0-9-_ ]+$');
      if (
        ipCheck.test(this.sqlNewFirewallRule.startip) === false ||
        ipCheck.test(this.sqlNewFirewallRule.endip) === false ||
        nameCheck.test(this.sqlNewFirewallRule.name) === false
      ) {
        this.warningHeaderText = 'Wrong Input';
        this.warningBodyText = `Please ensure that Name does not contain any special characters 
        and IP address is in correct format eg. 192.168.23.10`;
        this.warningDialog = true;
      } else {
        this.sqlNewFirewallRule.azuid = this.azuid;
        axios
          .post(`/api/v1/azure/sql/firewallrules`, this.sqlNewFirewallRule)
          .then(() => {
            this.getFirewallRules();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    deleteFirewallRule(name: string) {
      this.sqlNewFirewallRule.azuid = this.azuid;
      axios
        .delete(`/api/v1/azure/sql/firewallrules/${this.azuid}/${name}`)
        .then(() => {
          this.getFirewallRules();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getListQueries() {
      axios
        .get('/api/v1/azure/sql/querystore')
        .then((response) => {
          this.listQueries = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    postNewQuery() {
      let query: { name: String };
      for (query of this.listQueries) {
        if (this.newQueryName === query.name) {
          alert('query with the same name already exists');
          return;
        }
      }
      axios
        .post('/api/v1/azure/sql/querystore', { name: this.newQueryName, query: this.newQuery })
        .then(() => {
          this.newQueryName = '';
          this.getListQueries();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteStoredQuery() {
      if (this.selectedQuery.id != undefined) {
        axios
          .delete(`/api/v1/azure/sql/querystore/${this.selectedQuery.id}`)
          .then(() => {
            this.getListQueries();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    updateDatabase() {
      this.updateDatabaseData.azuid = this.azuid;
      this.updateDatabaseData.domain = this.database.domain;
      this.updateDatabaseData.location = this.location;
      this.updateDatabaseData.product = this.database.product;
      this.updateDatabaseData.edition = this.sqlCapabilitiesSelectedEdition.name;
      this.updateDatabaseData.objectiveid = this.sqlCapabilitiesSelectedObjective.uuid;
      this.updateDatabaseData.sizelimit = this.convertToBytes(
        this.sqlCapabilitiesSelectedMaxSize.limit,
        this.sqlCapabilitiesSelectedMaxSize.unit,
      ).toString();
      this.loaderDialog = true;
      axios
        .put(`/api/v1/azure/resourceset/sql`, this.updateDatabaseData)
        .then(() => {
          this.loaderDialog = false;
        })
        .catch((err) => {
          this.loaderDialog = false;
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    createDatabaseBackup() {
      this.backupDatabase.azuid = this.azuid;
      this.backupDatabase.domain = this.database.domain;
      this.backupDatabase.product = this.database.product;

      axios
        .post(`/api/v1/azure/sql/backup`, this.backupDatabase)
        .then(() => {
          this.warningHeaderText = 'Backup in progress';
          this.warningBodyText = `This action may take several minutes. You will get the notification when backup will be ready`;
          this.warningDialog = true;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    convertToBytes(value: number, unit: string) {
      if (unit === 'Megabytes') {
        return value * 1024 * 1024;
      }
      if (unit === 'Gigabytes') {
        return value * 1024 * 1024 * 1024;
      }
      if (unit === 'Terabytes') {
        return value * 1024 * 1024 * 1024 * 1024;
      }
      return 0;
    },
    listenQueryKeys(keyEvent: any) {
      if (keyEvent.ctrlKey && keyEvent.key.toLowerCase() === 'q') {
        this.querySQL();
      }
    },
    getQuerySelection() {
      const cmref: any = this.$refs['codeMirrorBox'];
      const select = cmref.codemirror.display.input.textarea;
      if (select.selectionStart !== select.selectionEnd) {
        return select.value;
      } else {
        return cmref.content;
      }
    },
  },
});
</script>

<style>
.CodeMirror-line {
  line-height: 22px !important;
}
.CodeMirror {
  height: 250px !important;
}
</style>
