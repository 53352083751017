<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" :class="setColClass(column.state)" v-for="column in columns" :key="column.state">
        <v-card tile class="mx-0 px-0 elevation-0">
          <v-card-title class="py-1 headline headerKanban">
            {{ column.title }}
          </v-card-title>
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable
            :sortable="true"
            @change="moveKanban($event, column.state)"
            :list="column.tasks"
            :animation="200"
            :sort="false"
            group="tasks"
            :style="`height: ${$store.getters.windowSize.y - 226}px; overflow-y: auto; overflow-x: hidden`"
          >
            <v-sheet v-for="task in column.tasks" :key="task.id" class="mt-3 moveCursor">
              <ClientKanbanTile :tileData="task" :clients="clients" :users="users" v-on:reload="loadKanbans" />
            </v-sheet>
          </draggable>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 my-2">
        <v-row>
          <v-col cols="4">
            <v-btn tile block color="btnColorConfirm" @click="newKanban">New</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn tile block color="btnColorConfirm" @click="getWeeklyReport(), (dialogWeekView = true)">
              Week report
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-switch v-model="showHidden" class="ma-0" @change="loadKanbans" label="Closed" hide-details />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-----------DIALOGS---------------------------------------------------->
    <v-dialog v-model="dialogEdit" width="1000px">
      <v-card>
        <v-card-title :class="`headline ${kanban.color}`" primary-title>
          Task:
          <v-spacer />
          <v-icon large @click="dialogEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="kanbanForm">
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  v-model="kanban.name"
                  maxlength="128"
                  label="Task name:"
                  :autocomplete="Math.random()"
                  :name="Math.random()"
                  required
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model.number="kanban.idClient"
                  :items="clients"
                  no-data-text="no data - you may not have permissons read Clients"
                  eager
                  :disabled="kanban.id > 0"
                  item-text="name"
                  item-value="id"
                  label="Client"
                  hide-details
                />
              </v-col>
              <v-col class="mt-2">
                <v-btn class="ml-6 mr-2" icon @click="kanban.color = 'kanbanColor1'">
                  <v-icon x-large color="kanbanColor1">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2" icon @click="kanban.color = 'kanbanColor2'">
                  <v-icon x-large color="kanbanColor2">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2" icon @click="kanban.color = 'kanbanColor3'">
                  <v-icon x-large color="kanbanColor3">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2 mr-2" icon @click="kanban.color = 'kanbanColor4'">
                  <v-icon x-large color="kanbanColor4">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2" icon @click="kanban.color = 'kanbanColor5'">
                  <v-icon x-large color="kanbanColor5">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2" icon @click="kanban.color = 'kanbanColor6'">
                  <v-icon x-large color="kanbanColor6">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2 mr-2" icon @click="kanban.color = 'kanbanColor7'">
                  <v-icon x-large color="kanbanColor7">{{ mdiCircle }}</v-icon>
                </v-btn>
                <v-btn class="mx-2" icon @click="kanban.color = 'kanbanColor8'">
                  <v-icon x-large color="kanbanColor8">{{ mdiCircle }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="kanban.users"
                  label="Users"
                  :items="users"
                  item-text="fullName"
                  item-value="id"
                  :menu-props="{ minWidth: '500' }"
                  chips
                  deletable-chips
                  eager
                  multiple
                  dense
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeUser(data.item)"
                    >
                      <v-avatar v-if="data.item.picture" left>
                        <v-img :src="`data:image/png;base64, ${data.item.picture}`"></v-img>
                      </v-avatar>
                      {{ data.item.fullName }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="checkObject(data.item)">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar v-if="data.item.picture">
                        <img :src="`data:image/png;base64, ${data.item.picture}`" />
                      </v-list-item-avatar>
                      <v-list-item-avatar v-else>
                        <v-icon>{{ mdiAccount }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-textarea
                  v-model="kanban.note"
                  label="Notes"
                  hint="Aditional task notes"
                  rows="8"
                  no-resize
                  hide-details
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn v-if="kanban.id > 0" tile color="btnColorDelete" @click="confirmDeleteDialog = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveKanban">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogWeekView" width="1000px">
      <v-card>
        <v-card-title :class="`headline headerColorStandard`" primary-title>
          Week view:
          <v-spacer />
          <v-icon large @click="dialogWeekView = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-spacer />
            <v-col cols="2">
              <v-select
                v-model="reportFilter.year"
                :items="reportYears"
                @change="generateWeeks(reportFilter.year), getWeeklyReport()"
                label="Year:"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="reportFilter.week"
                :items="reportWeeks"
                @change="getWeeklyReport"
                label="Week:"
                hide-details
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <v-data-table
                :headers="headers"
                :items="weeklyReportData"
                :height="500"
                fixed-header
                disable-pagination
                hide-default-footer
                dense
                class="px-0 elevation-1 tableCursor"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs">{{ item.client }}</td>
                    <td class="text-xs">{{ item.date | moment('DD.MM.YYYY') }}</td>
                    <td class="text-xs">{{ item.task }}</td>
                    <td class="text-xs">{{ item.note }}</td>
                    <td class="text-xs">{{ Math.floor(item.duration / 60) }}:{{ item.duration % 60 }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import draggable from 'vuedraggable';
import ClientKanbanTile from '@/components/clients/clientKanbanTile.vue';
import { mdiClose, mdiCircle, mdiAccount } from '@mdi/js';

export default Vue.extend({
  name: 'tableKanbans',
  components: { draggable, ClientKanbanTile },
  data: () => ({
    dialogEdit: false,
    dialogWeekView: false,
    validForm: false,
    confirmDeleteDialog: false,
    showHidden: false,
    clients: [],
    users: [],
    kanbans: [],
    kanban: {
      id: 0,
      idClient: 0,
      name: '',
      color: '',
      note: '',
      state: 0,
      users: [] as any,
      hidden: false,
      timestamp: new Date().toISOString(),
    },
    columns: [
      {
        title: 'New',
        state: 0,
        tasks: [] as any,
      },
      {
        title: 'In progress',
        state: 1,
        tasks: [] as any,
      },
      {
        title: 'Delivery',
        state: 2,
        tasks: [] as any,
      },
      {
        title: 'Done',
        state: 3,
        tasks: [] as any,
      },
    ],
    reportWeeks: [] as any,
    reportYears: [] as any,
    reportFilter: {
      year: 0,
      week: 0,
      startDate: '',
      endDate: '',
    },
    headers: [
      { text: 'Client', value: 'client', width: '30%' },
      { text: 'Date', value: 'date', width: '15%' },
      { text: 'Task', value: 'task', width: '20%' },
      { text: 'Note', value: 'note', width: '20%' },
      { text: 'Duration', value: 'duration', width: '15%' },
    ],
    weeklyReportData: {},
    mdiClose: mdiClose,
    mdiCircle: mdiCircle,
    mdiAccount: mdiAccount,
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadKanbans();
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'kanban') {
        this.loadKanbans();
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadKanbans();
    this.loadClients();
    this.loadUsers();
    for (let i = this.$moment().year() - 10; i <= this.$moment().year() + 1; i += 1) {
      this.reportYears.push(i);
    }
    this.reportFilter.year = this.$moment().year();
    this.reportFilter.week = this.$moment().week();
    this.generateWeeks(this.reportFilter.year);
    this.getWeeklyReport();
  },
  methods: {
    setColClass(state: number) {
      switch (state) {
        case 0:
          return 'py-1 pr-1 pl-0';
        case 1:
          return 'py-1 px-1';
        case 2:
          return 'py-1 px-1';
        case 3:
          return 'py-1 pl-1 pr-0';
        default:
          return 'py-1 px-1';
      }
    },
    checkObject(item: any) {
      // workaround - prettier has a problem with formating if "typeof item ..." is used 20220408
      return typeof item !== 'object';
    },
    removeUser(item: any) {
      const index = this.kanban.users.indexOf(item.id);
      if (index >= 0) this.kanban.users.splice(index, 1);
    },
    generateWeeks(year: number) {
      for (let i = 1; i < this.$moment().set('year', year).weeksInYear() + 1; i += 1) {
        this.reportWeeks.push(i);
      }
      if (this.$moment().year() !== year) {
        this.reportFilter.week = 1;
      } else {
        this.reportFilter.week = this.$moment().week();
      }
    },
    getWeeklyReport() {
      this.reportFilter.startDate = this.$moment()
        .day('Monday')
        .year(this.reportFilter.year)
        .week(this.reportFilter.week)
        .format('YYYY-MM-DD');
      this.reportFilter.endDate = this.$moment(this.reportFilter.startDate).add(6, 'days').format('YYYY-MM-DD');
      axios
        .get(`/api/v1/kanban/weekly`, {
          params: {
            startDate: this.reportFilter.startDate,
            endDate: this.reportFilter.endDate,
          },
        })
        .then((response) => {
          this.weeklyReportData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    newKanban() {
      const getDefaults: any = this.$options;
      this.kanban = { ...getDefaults.data().kanban };
      this.kanban.idClient = this.$store.getters.selectedClient.id;
      this.kanban.users.push(this.$store.getters.userSettings.userID);
      this.kanban.color = 'kanbanColor1';
      this.dialogEdit = true;
    },
    loadKanbans() {
      axios
        .get('api/v1/kanban', {
          params: {
            client: this.$store.getters.selectedClient.id,
            hidden: this.showHidden,
          },
        })
        .then((response) => {
          this.kanbans = response.data;
          let kanban: any;
          for (const column of this.columns) {
            column.tasks = [];
          }
          for (kanban of this.kanbans) {
            for (const column of this.columns) {
              if (kanban.state === column.state) {
                column.tasks.push(kanban);
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    saveKanban() {
      const form: any = this.$refs.kanbanForm;
      if (!form.validate()) {
        this.$store.commit('globalMessage', 'Form is not filled correctly!!!');
      } else {
        axios
          .post('/api/v1/kanban', this.kanban)
          .then((response) => {
            this.loadKanbans();
            this.dialogEdit = false;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    moveKanban({ added }: any, state: any) {
      if (added) {
        added.element.state = state;
        added.element.hidden = false;
        axios
          .put('/api/v1/kanban/state', added.element)
          .then(() => {
            this.loadKanbans();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    loadClients() {
      axios
        .get('api/v1/clients', {
          params: {
            closed: false,
          },
        })
        .then((response) => {
          this.clients = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadUsers() {
      axios
        .get('api/v1/usersbasic')
        .then((response) => {
          this.users = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
