<template>
  <v-card>
    <v-card-title class="headline headerColorStandard" primary-title>
      <span style="max-width: 95%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
        Bug number: {{ cbr.bugNumber }} : {{ cbr.summary }}
      </span>
      <v-spacer />
      <v-icon large @click="$emit('bugDetailClose', false)">{{ mdiClose }}</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-sheet :max-height="$store.getters.windowSize.y - 85" style="overflow: auto">
            <h2>Statuses</h2>
            <v-data-table
              :items="tableData"
              item-key="bugStatusID"
              height="280"
              class="tableSelectCursor"
              dense
              fixed-header
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr @click="(selectedStatus = item), (dialogStatusDetail = true)">
                  <td width="40%">{{ item.modified | moment('DD.MM.YYYY / HH:mm') }}</td>
                  <td width="60%">{{ item.status }}</td>
                </tr>
              </template>
            </v-data-table>

            <h2>Files:</h2>
            <v-data-table
              :items="tableAttachments"
              height="280"
              class="tableSelectCursor"
              dense
              fixed-header
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td width="90%">{{ item.filename }}</td>
                  <td>
                    <a :href="`/api/v1/bt/attachment/file/${item.attachedFileID}`">
                      <v-icon>{{ mdiFileDownload }}</v-icon>
                    </a>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
        <v-col cols="8">
          <v-sheet style="overflow-y: auto; overflow-x: hidden" :height="$store.getters.windowSize.y - 85">
            <v-row class="mt-2">
              <v-col class="py-0 text-h6" cols="4">Status:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.status }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Category:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.category }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Feature:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.feature }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Priority:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.priority }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Created:</v-col>
              <v-col class="py-0 text-h6" cols="8" v-if="cbr.created">
                {{ cbr.created | moment('DD.MM.YYYY / HH:mm') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Modified:</v-col>
              <v-col class="py-0 text-h6" cols="8" v-if="cbr.modified">
                {{ cbr.modified | moment('DD.MM.YYYY / HH:mm') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Reported version:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.reportedVersion }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Resolved version:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.resolvedVersion }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Reported by:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.reporterName }}</v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Assigned to:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.assignedToName }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Summary:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.summary }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Description:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.description }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Additional info:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.additionalInformation }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-row>
              <v-col class="py-0 text-h6" cols="4">Steps to reproduce:</v-col>
              <v-col class="py-0 text-h6" cols="8">{{ cbr.stepsToReproduce }}</v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialogStatusDetail" width="60%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Status: {{ selectedStatus.status }}
          <v-spacer />
          <v-icon large @click="dialogStatusDetail = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="4">Status ID:</v-col>
            <v-col cols="8">{{ selectedStatus.bugStatusID }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4">Reported by:</v-col>
            <v-col cols="8">{{ selectedStatus.reporterName }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4">Assigned to:</v-col>
            <v-col cols="8">{{ selectedStatus.assignedToName }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4">Description:</v-col>
            <v-col cols="8">{{ cbr.description }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="4">Info:</v-col>
            <v-col cols="8">{{ selectedStatus.additionalInformation }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiClose, mdiFileDownload } from '@mdi/js';

export default Vue.extend({
  name: 'TableVersions',
  props: {
    bugDetailRecord: Number,
  },
  data: () => ({
    dialogStatusDetail: false,
    selectedStatus: {},
    tableData: [],
    tableAttachments: [],
    cbr: {}, // currentBugRecord
    mdiClose: mdiClose,
    mdiFileDownload: mdiFileDownload,
  }),
  watch: {
    bugDetailRecord() {
      this.loadBugDetails();
    },
  },
  mounted() {
    this.loadBugDetails();
  },
  methods: {
    loadBugDetails() {
      this.cbr = {};
      axios
        .get(`/api/v1/bt/list/${this.bugDetailRecord}`)
        .then((response) => {
          this.tableData = response.data;
          this.cbr = this.tableData[0]; // Set latest record
          this.loadAttachments();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadAttachments() {
      axios
        .get(`/api/v1/bt/attachment/bug/${this.bugDetailRecord}`)
        .then((response) => {
          this.tableAttachments = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    getReport() {
      axios
        .post(`/api/v1/templates/bugtrack/bugReport.html`, this.cbr)
        .then((response) => {
          window.open(`/api/v1/templates/${response.data.uuid}/pdf`);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
