<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="comments"
          :height="$store.getters.windowSize.y - 510"
          fixed-header
          disable-pagination
          hide-default-footer
          dense
          class="tableSelectCursor elevation-2"
        >
          <template v-slot:item="{ item }">
            <tr @click="openComment(item)">
              <td>{{ item.comment }}</td>
              <td>{{ item.created | moment($store.getters.dateFormat + ' HH:mm') }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1 pb-0">
        <v-btn tile color="btnColorConfirm" small block @click="openNewComment">Create new</v-btn>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogComment" width="50%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Comment:
          <v-spacer />
          <v-icon large @click="dialogComment = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea rows="10" v-model="comment.comment" label="Comment:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn tile v-if="comment.id" color="btnColorDelete" @click="confirmDeleteComment = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveComment">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete comment?"
      body="Do you really want delete comment?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteComment"
      v-on:confirm="deletecomment"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'enhancementComments',
  props: { enhancement: Object },
  components: { ConfirmDialog },
  data: () => ({
    dialogComment: false,
    confirmDeleteComment: false,
    comments: [],
    comment: {
      id: 0,
      idEnhancement: 0,
      comment: '',
      created: '',
      createdBy: 0,
    },
    headers: [
      { text: 'comment', value: 'comment' },
      { text: 'Created', value: 'created', width: '150' },
    ],
    mdiClose: mdiClose,
  }),
  watch: {
    enhancement() {
      this.loadComments();
    },
  },
  mounted() {
    this.loadComments();
  },
  methods: {
    loadComments() {
      axios
        .get(`api/v1/pm/comment/enhancement/${this.enhancement.id}`)
        .then((response) => {
          this.comments = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewComment() {
      this.comment.id = 0;
      this.comment.comment = '';
      this.dialogComment = true;
    },
    openComment(item: any) {
      this.comment = { ...item };
      this.dialogComment = true;
    },
    saveComment() {
      this.comment.idEnhancement = this.enhancement.id;
      this.comment.created = new Date().toISOString();
      if (this.comment.id === 0) {
        axios
          .post(`api/v1/pm/comment/enhancement`, this.comment)
          .then(() => {
            this.loadComments();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .put(`api/v1/pm/comment/enhancement`, this.comment)
          .then(() => {
            this.loadComments();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.dialogComment = false;
    },
    deletecomment() {
      axios
        .delete(`api/v1/pm/comment/enhancement/${this.comment.id}`)
        .then(() => {
          this.loadComments();
          this.dialogComment = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
