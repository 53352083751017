<template>
  <v-container fluid fill-height class="loginBody">
    <v-layout v-if="$vuetify.breakpoint.xs" flex align-center justify-center>
      <v-flex class="mx-8">
        <v-btn class="my-8" large block rounded href="https://apps.apple.com/cz/app/samdac/id1614252205">
          <v-icon large class="mr-5">{{ mdiApple }}</v-icon> Download iPhone
        </v-btn>
        <v-btn class="my-8" large block rounded href="https://samdac.sawcloud.net/api/androidapp">
          <v-icon large class="mr-5">{{ mdiAndroid }}</v-icon> Download Android
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="appSettings.version && !$vuetify.breakpoint.xs" flex align-center justify-center>
      <v-flex class="rounded-lg" id="loginBox" sm8 md6 lg4 elevation-4>
        <v-form class="ma-4 login" @submit.prevent="login">
          <!--v-progress-circular v-if="!appSettings.version" :size="100" :width="20" color="primary" indeterminate /-->
          <v-text-field v-model="user" type="text" label="User Name" required hide-details></v-text-field>
          <v-text-field
            autocomplete="new-password"
            v-model="password"
            type="password"
            label="Password"
            required
            hide-details
          />
          <v-text-field v-if="this.appSettings.gauth" v-model="otp" type="text" label="Key" required hide-details />
          <v-btn tile class="mt-2" type="submit" block color="orange accent-1"> Login </v-btn>
          <v-row>
            <v-col cols="12" class="pb-0 ma-0">version: {{ $store.state.version }}</v-col>
          </v-row>
        </v-form>
      </v-flex>
      <v-flex v-if="appSettings.version == ''" id="loginBox" xs12 sm5 elevation-4>
        <h1 class="ml-4">Server unavialable</h1>
        <h2 class="ml-4 mb-2">
          Please try refresh
          <v-btn class="ml-5" icon @click="refreshSite">
            <v-icon x-large color="green">{{ mdiReload }}</v-icon>
          </v-btn>
        </h2>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackLoginError" :timeout="4000" top color="red">Incorrect login credentials</v-snackbar>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { mdiReload, mdiAndroid, mdiApple } from '@mdi/js';

export default Vue.extend({
  name: 'login-page',
  data: () => ({
    user: '',
    password: '',
    snackLoginError: false,
    dialogNewVersion: false,
    otp: '',
    appSettings: {
      version: '',
      versionDB: 0,
      gauth: false,
    },
    mdiReload: mdiReload,
    mdiAndroid: mdiAndroid,
    mdiApple: mdiApple,
  }),
  mounted() {
    this.$store.dispatch('logout');
    this.loadSettings();
    this.$vuetify.theme.dark = false;
  },
  methods: {
    login() {
      this.loadSettings().then(() => {
        const action = 'login';
        const user = this.user;
        const password = this.password;
        const otp = this.otp;
        this.$store
          .dispatch('login', {
            action,
            user,
            password,
            otp,
          })
          .then(() => this.onLoginSetup())
          .catch((err) => {
            if (err === 'waitForQR') {
              return;
            }
            if (err.response.status === 401) {
              this.snackLoginError = true;
            }
          });
      });
    },
    onLoginSetup() {
      if (this.$store.getters.userSettings.accClients.read) {
        this.$router.push('/clients');
      } else if (this.$store.getters.userSettings.accKnowledgeBase.read) {
        this.$router.push('/knowledgebase');
      } else if (this.$store.getters.userSettings.accHosting.read) {
        this.$router.push('/servers');
      } else {
        this.$router.push('/noaccess');
        return;
      }
      this.$store.dispatch('webSocketOpen');
      this.$store.dispatch('getSystemNotifications');
      this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;
    },
    async loadSettings() {
      await axios
        .get('api/appsettings')
        .then((response) => {
          setTimeout(() => {
            // PWA on iPad does not reload site on open, so loadSettings is called on login again
            // If version changed reload:
            // - Chrome, edge will clear cache by Clear-Site-Data on /clear api
            // - iPad will reload which calls updateServiceWorker which calls /clear again and it somehow works :/
            if (response.data.version !== this.$store.state.version) {
              document.location.href = '/clear';
            }
          }, 200);
          this.appSettings = response.data;
        })
        .catch((err) => {
          console.log('loginSettingsErr', err);
        });
    },
    refreshSite() {
      window.location.reload();
    },
  },
});

//
</script>

<style>
.loginBody {
  background-image: url('../assets/loginBackground.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#loginBox {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
