<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="subproductheaders"
          :items="subproducts"
          height="188"
          fixed-header
          disable-pagination
          hide-default-footer
          @click:row="openSubproduct"
          dense
          class="tableSelectCursor"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="$store.getters.userSettings.accProducts.update"
          tile
          small
          block
          color="btnColorConfirm"
          @click="openNewSubproduct"
          >Add subproduct</v-btn
        >
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogSubproduct" width="40%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Subproduct:
          <v-spacer />
          <v-icon large @click="dialogSubproduct = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="subproduct.name" maxlength="255" label="Name:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="subproduct.shortName" maxlength="8" label="Short name:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn v-if="subproduct.id" tile color="btnColorDelete" @click="confirmDeleteSubproduct = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveSubproduct">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete Subproduct?"
      body="Do you really want delete Subproduct?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteSubproduct"
      v-on:confirm="deleteSubproduct"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'productsSubproducts',
  props: { product: Object },
  components: { ConfirmDialog },
  data: () => ({
    dialogSubproduct: false,
    confirmDeleteSubproduct: false,
    subproducts: [],
    subproduct: {
      id: 0,
      idProduct: 0,
      name: '',
      shortName: '',
    },
    subproductheaders: [
      { text: 'Subproduct', value: 'name' },
      { text: 'Short name', value: 'shortName', width: '200' },
    ],
    mdiClose: mdiClose,
  }),
  mounted() {
    this.loadSubproducts();
  },
  watch: {
    product() {
      this.loadSubproducts();
    },
  },
  methods: {
    loadSubproducts() {
      axios
        .get(`api/v1/pm/subproduct/${this.product.id}`)
        .then((response) => {
          this.subproducts = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewSubproduct() {
      this.subproduct.id = 0;
      this.subproduct.name = '';
      this.subproduct.shortName = '';
      this.dialogSubproduct = true;
    },
    openSubproduct(item: any) {
      this.subproduct = { ...item };
      this.dialogSubproduct = true;
    },
    saveSubproduct() {
      this.subproduct.idProduct = this.product.id;
      if (this.subproduct.id === 0) {
        axios
          .post(`api/v1/pm/subproduct`, this.subproduct)
          .then(() => {
            this.loadSubproducts();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .put(`api/v1/pm/subproduct`, this.subproduct)
          .then(() => {
            this.loadSubproducts();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.dialogSubproduct = false;
    },
    deleteSubproduct() {
      axios
        .delete(`api/v1/pm/subproduct/${this.subproduct.id}`)
        .then(() => {
          this.loadSubproducts();
          this.dialogSubproduct = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
