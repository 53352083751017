import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{staticClass:"mr-3",attrs:{"hide-details":"","label":"Name","required":""},model:{value:(_vm.webSite.name),callback:function ($$v) {_vm.$set(_vm.webSite, "name", $$v)},expression:"webSite.name"}})],1),_c(VCol,{attrs:{"cols":"7"}},[_c(VTextField,{attrs:{"hide-details":"","label":"URL","required":""},model:{value:(_vm.webSite.url),callback:function ($$v) {_vm.$set(_vm.webSite, "url", $$v)},expression:"webSite.url"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"tile":"","block":"","color":"btnColorNew"},on:{"click":_vm.addURL}},[_vm._v("Add")])],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"items":_vm.tableData,"hide-default-header":"","disable-pagination":"","hide-default-footer":"","height":"385"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.url))]),(_vm.$store.getters.userSettings.accHosting.update)?_c('td',[_c(VIcon,{attrs:{"left":"","color":"red"},on:{"click":function($event){return _vm.deleteURL(item.id)}}},[_vm._v(_vm._s(_vm.mdiDeleteEmpty))])],1):_vm._e()])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }