<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-tabs background-color="tabsBackground" slider-color="light-blue accent-2">
        <v-tab v-if="$store.getters.userSettings.accUsers.read" ripple>users</v-tab>
        <v-tab ripple v-if="$store.getters.userSettings.accNotifications.read">notifications</v-tab>
        <v-tab ripple v-if="$store.getters.userSettings.accSerialisations.special">Change Log</v-tab>

        <v-tab-item v-if="$store.getters.userSettings.accUsers.read">
          <SettingsTableUsers />
        </v-tab-item>
        <v-tab-item v-if="$store.getters.userSettings.accNotifications.read">
          <SettingsAdditional />
        </v-tab-item>
        <v-tab-item v-if="$store.getters.userSettings.accSerialisations.special">
          <TableChangeLog />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import SettingsTableUsers from '@/components/settings/tableUsers.vue';
import SettingsAdditional from '@/components/settings/additional.vue';
import TableChangeLog from '@/components/settings/tableChangeLog.vue';

export default Vue.extend({
  name: 'settings-page',
  components: {
    SettingsTableUsers,
    SettingsAdditional,
    TableChangeLog,
  },
});
</script>
