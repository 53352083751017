<template>
  <v-card>
    <v-card-title :class="`py-0 pl-1 pr-0 text-caption ${tileData.color}`">
      <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
        {{ tileData.client }}
      </span>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-tooltip bottom open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-col v-bind="attrs" v-on="on" class="px-1 pb-0">
              {{ tileData.name }}
            </v-col>
          </template>
          <v-container>
            <div style="width: 600px; height: 400px">
              <v-row>
                <v-col class="py-0" cols="2">user</v-col>
                <v-col class="py-0" cols="6">note</v-col>
                <v-col class="py-0" cols="2">duration</v-col>
                <v-col class="py-0" cols="2">date</v-col>
              </v-row>
              <br />
              <hr />
              <br />
              <v-row v-for="change in tileData.changelog" :key="change.id">
                <v-col class="py-0" cols="2">{{ change.user }}</v-col>
                <v-col class="py-0" cols="6">{{ change.note }}</v-col>
                <v-col class="py-0" cols="2">{{ Math.floor(change.duration / 60) }}:{{ change.duration % 60 }}</v-col>
                <v-col class="py-0" cols="2">{{ change.date | moment('DD.MM.YYYY') }}</v-col>
              </v-row>
            </div>
          </v-container>
        </v-tooltip>
      </v-row>
      <v-row>
        <v-col class="px-0 py-0">
          <v-btn @click="showChangeLog" small icon>
            <v-icon small color="iconColorDefault">{{ mdiPlusBox }}</v-icon>
          </v-btn>
          <v-btn small icon @click="showTimer">
            <v-icon small color="iconColorDefault">{{ mdiAvTimer }}</v-icon>
          </v-btn>
          <v-btn small icon @click="(tileEdit = { ...tileData }), (dialogEditTile = true)">
            <v-icon small color="iconColorDefault">
              {{ mdiFileEdit }}
            </v-icon>
          </v-btn>
          <v-btn small icon @click="loadChangeLog(), (dialogChangeLog = true), (showSlotChangeLog = false)">
            <v-icon small color="iconColorDefault">
              {{ mdiFormatListBulleted }}
            </v-icon>
          </v-btn>
          <v-btn v-if="tileData.state === 3" @click="confirmHideTileDialog = true" small icon>
            <v-icon v-if="tileData.hidden" small color="iconColorDefault">{{ mdiEyeOff }}</v-icon>
            <v-icon v-else small color="iconColorDefault">{{ mdiEye }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-container class="pa-0" v-show="showSlotChangeLog" fluid>
          <v-row>
            <v-col class="px-0 pt-5 pb-0">
              <v-textarea
                v-model="changelog.note"
                v-on:keydown.ctrl.enter="saveChangeLog"
                maxlength="1024"
                rows="3"
                :autocomplete="Math.random()"
                :name="Math.random()"
                outlined
                required
                hide-details
                autofocus
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0" cols="5">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Date:"
                    @blur="changelog.date = parseDate(dateFormatted)"
                    v-on="on"
                    hide-details
                    readonly
                    :autocomplete="Math.random()"
                    :name="Math.random()"
                  />
                </template>
                <v-date-picker v-model="changelog.date" no-title @input="dateMenu = false" />
              </v-menu>
            </v-col>
            <v-col class="py-0 px-1" cols="2">
              <v-text-field v-model.number="durationHH" type="number" label="HH:" hide-details />
            </v-col>
            <v-col class="py-0 px-1" cols="2">
              <v-text-field v-model.number="durationMM" type="number" label="MM:" hide-details />
            </v-col>
            <v-col class="pb-0 px-1" cols="3">
              <v-btn icon block @click="saveChangeLog">
                <v-icon large color="iconColorDefault">{{ mdiCheckBold }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
      <v-expand-transition>
        <v-row v-show="showSlotTimer">
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col class="pb-0">
                    <h1 class="text-center">{{ formattedElapsedTime }}</h1>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col class="py-1" cols="3">
                  <v-btn icon block @click="timerStart">
                    <v-icon color="iconColorDefault">{{ mdiPlay }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-1" cols="3">
                  <v-btn icon block @click="timerPause">
                    <v-icon color="iconColorDefault">{{ mdiPause }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-1" cols="3">
                  <v-btn icon block @click="timerReset">
                    <v-icon color="iconColorDefault">{{ mdiStop }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-1" cols="3">
                  <v-btn icon block @click="timerSave">
                    <v-icon color="iconColorDefault">{{ mdiCheckBold }}</v-icon>
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-text>
    <!-----------DIALOGS---------------------------------------------------->
    <v-dialog v-model="dialogEditTile" width="1000px">
      <v-card>
        <v-card-title :class="`headline ${tileEdit.color}`" primary-title>
          Task:
          <v-spacer />
          <v-icon large @click="dialogEditTile = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                v-model="tileEdit.name"
                maxlength="128"
                label="Task name:"
                :autocomplete="Math.random()"
                :name="Math.random()"
                required
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model.number="tileEdit.idClient"
                :items="clients"
                no-data-text="no data - you may not have permissons read Clients"
                eager
                :disabled="tileEdit.id > 0"
                item-text="name"
                item-value="id"
                label="Client"
                hide-details
              />
            </v-col>
            <v-col>
              <v-btn class="ml-6 mr-2" icon @click="tileEdit.color = 'kanbanColor1'">
                <v-icon x-large color="kanbanColor1">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2" icon @click="tileEdit.color = 'kanbanColor2'">
                <v-icon x-large color="kanbanColor2">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2" icon @click="tileEdit.color = 'kanbanColor3'">
                <v-icon x-large color="kanbanColor3">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2 mr-2" icon @click="tileEdit.color = 'kanbanColor4'">
                <v-icon x-large color="kanbanColor4">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2" icon @click="tileEdit.color = 'kanbanColor5'">
                <v-icon x-large color="kanbanColor5">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2" icon @click="tileEdit.color = 'kanbanColor6'">
                <v-icon x-large color="kanbanColor6">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2 mr-2" icon @click="tileEdit.color = 'kanbanColor7'">
                <v-icon x-large color="kanbanColor7">{{ mdiCircle }}</v-icon>
              </v-btn>
              <v-btn class="mx-2" icon @click="tileEdit.color = 'kanbanColor8'">
                <v-icon x-large color="kanbanColor8">{{ mdiCircle }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="tileEdit.users"
                label="Users"
                :items="users"
                item-text="fullName"
                item-value="id"
                :menu-props="{ minWidth: '500' }"
                small-chips
                eager
                deletable-chips
                multiple
                dense
                hide-details
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeUser(data.item)"
                  >
                    <v-avatar v-if="data.item.picture" left>
                      <v-img :src="`data:image/png;base64, ${data.item.picture}`"></v-img>
                    </v-avatar>
                    {{ data.item.fullName }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="checkObject(data.item)">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar v-if="data.item.picture">
                      <img :src="`data:image/png;base64, ${data.item.picture}`" />
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                      <v-icon>{{ mdiAccount }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.fullName"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="tileEdit.note"
                label="Notes"
                hint="Aditional task notes"
                rows="8"
                no-resize
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn tile color="btnColorDelete" @click="confirmDeleteDialog = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="updateKanban">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete task?"
      body="Do you really want delete task?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteKanban"
    />
    <v-dialog v-model="dialogChangeLog" width="1000px">
      <v-card>
        <v-card-title :class="`headline ${tileData.color}`" primary-title>
          Change log:
          <v-spacer />
          <v-icon large @click="dialogChangeLog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <v-data-table
            :headers="headers"
            :items="listChangeLog"
            :height="500"
            fixed-header
            disable-pagination
            hide-default-footer
            dense
            class="px-0 elevation-1 tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-xs">{{ item.user }}</td>
                <td class="text-xs">{{ item.note }}</td>
                <td class="text-xs">{{ Math.floor(item.duration / 60) }}:{{ item.duration % 60 }}</td>
                <td class="text-xs">{{ item.date | moment('DD.MM.YYYY') }}</td>

                <td class="text-xs tableSelectCursor">
                  <v-btn icon @click="openEditChangeLog(item)">
                    <v-icon color="btnColorEdit">{{ mdiPencil }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditChangeLog" width="800px">
      <v-card>
        <v-card-title :class="`headline ${tileData.color}`" primary-title>
          Note:
          <v-spacer />
          <v-icon large @click="dialogEditChangeLog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea v-model="changelog.note" label="Note" rows="4" no-resize hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field v-model.number="durationHH" type="number" label="HH:" hide-details />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model.number="durationMM" type="number" label="MM:" hide-details />
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn icon block @click="updateChangeLog">
                <v-icon large color="btnColorConfirm">{{ mdiCheckBold }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn icon block @click="confirmDeleteChangeLogDialog = true">
                <v-icon large color="btnColorDelete">{{ mdiCloseThick }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete changelog record?"
      body="Do you really want delete changelog record?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteChangeLogDialog"
      v-on:confirm="deleteChangeLog"
    />
    <ConfirmDialog
      headerColor="btnColorDelete"
      :header="`${tileData.hidden ? 'Show' : 'Hide'} selected task?`"
      :body="`Do you really want ${tileData.hidden ? 'SHOW' : 'HIDE'} selected task?`"
      :confirmButton="tileData.hidden ? 'SHOW' : 'HIDE'"
      :openDialog.sync="confirmHideTileDialog"
      v-on:confirm="hideTile"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import dateFormatMixin from '../../mixins/dateFormat';
import ConfirmDialog from '@/components/confirmDialog.vue';
import {
  mdiFileEdit,
  mdiEye,
  mdiEyeOff,
  mdiPlusBox,
  mdiClose,
  mdiCloseThick,
  mdiCircle,
  mdiAvTimer,
  mdiPlay,
  mdiPause,
  mdiStop,
  mdiCheckBold,
  mdiCalendar,
  mdiPencil,
  mdiFormatListBulleted,
  mdiAccount,
} from '@mdi/js';

export default Vue.extend({
  name: 'clientKanbanTile',
  mixins: [dateFormatMixin],
  components: { ConfirmDialog },
  props: {
    tileData: Object,
    clients: Array,
    users: Array,
  },
  data: () => ({
    dialogEditTile: false,
    dialogChangeLog: false,
    dialogEditChangeLog: false,
    confirmDeleteDialog: false,
    confirmDeleteChangeLogDialog: false,
    confirmHideTileDialog: false,
    tileEdit: {
      id: 0,
      idClient: 0,
      name: '',
      color: '',
      note: '',
      state: 0,
      users: [] as any,
      hidden: false,
      timestamp: new Date().toISOString(),
    },
    showSlotChangeLog: false,
    showSlotTimer: false,
    changelog: {
      id: 0,
      idKanban: 0,
      idUser: 0,
      note: '',
      date: new Date().toISOString().substr(0, 10),
      duration: 0, //in minutes
    },
    listChangeLog: [],
    dateFormatted: '',
    dateMenu: false,
    durationHH: 0,
    durationMM: 0,
    timer: undefined as any,
    timerStartSecond: 0,
    elapsedTime: 0,
    headers: [
      { text: 'User', value: 'user', width: '15%' },
      { text: 'Note', value: 'note', width: '60%' },
      { text: 'Duration', value: 'duration', width: '15%' },
      { text: 'Date', value: 'date', width: '10%' },
      { text: '', width: '1%' },
    ],
    mdiEye: mdiEye,
    mdiEyeOff: mdiEyeOff,
    mdiPlusBox: mdiPlusBox,
    mdiFileEdit: mdiFileEdit,
    mdiClose: mdiClose,
    mdiCloseThick: mdiCloseThick,
    mdiCircle: mdiCircle,
    mdiAvTimer: mdiAvTimer,
    mdiPlay: mdiPlay,
    mdiPause: mdiPause,
    mdiStop: mdiStop,
    mdiCheckBold: mdiCheckBold,
    mdiCalendar: mdiCalendar,
    mdiPencil: mdiPencil,
    mdiFormatListBulleted: mdiFormatListBulleted,
    mdiAccount: mdiAccount,
  }),
  computed: {
    formattedElapsedTime() {
      let x: string | null;
      x = null;
      const date = new Date(x || 0);
      date.setSeconds(this.elapsedTime);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(':') - 2, 8);
    },
  },
  watch: {
    'changelog.date'() {
      this.dateFormatted = this.$moment(this.changelog.date).format(this.$store.getters.dateFormat);
    },
  },
  methods: {
    checkObject(item: any) {
      // workaround - prettier has a problem with formating if "typeof item ..." is used 20220408
      return typeof item !== 'object';
    },
    removeUser(item: any) {
      const index = this.tileEdit.users.indexOf(item.id);
      if (index >= 0) this.tileEdit.users.splice(index, 1);
    },
    showChangeLog() {
      const getDefaults: any = this.$options;
      this.changelog = { ...getDefaults.data().changelog };
      this.showSlotChangeLog = !this.showSlotChangeLog;
      this.dateFormatted = this.$moment(this.changelog.date).format(this.$store.getters.dateFormat);
      this.showSlotTimer = false;
    },
    openEditChangeLog(item: any) {
      this.durationHH = Math.floor(item.duration / 60);
      this.durationMM = item.duration % 60;
      this.changelog = { ...item };
      this.dialogEditChangeLog = true;
    },
    showTimer() {
      this.showSlotTimer = !this.showSlotTimer;
      this.timerReset();
      this.showSlotChangeLog = false;
      this.timerStart();
    },
    timerStart() {
      if (this.timer == undefined) {
        this.timerStartSecond = Math.floor(Date.now() / 1000) - this.elapsedTime;
        this.timer = setInterval(() => {
          this.elapsedTime = Math.floor(Date.now() / 1000) - this.timerStartSecond;
        }, 100);
      }
    },
    timerPause() {
      clearInterval(this.timer);
      this.timer = undefined;
    },
    timerReset() {
      this.timerPause();
      this.elapsedTime = 0;
    },
    timerSave() {
      // console.log('t1seconds: ', this.elapsedTime % 60);
      this.durationMM = Math.floor(this.elapsedTime / 60);
      this.durationHH = Math.floor(this.elapsedTime / 60 / 60);
      this.elapsedTime = 0;
      this.showChangeLog();
    },
    saveChangeLog() {
      this.changelog.duration = this.durationHH * 60 + this.durationMM;
      this.changelog.idKanban = this.tileData.id;
      axios
        .post('/api/v1/kanban/changelog', this.changelog)
        .then(() => {
          this.durationHH = 0;
          this.durationMM = 0;
          this.changelog.note = '';
          this.changelog.date = new Date().toISOString().substr(0, 10);
          this.showSlotChangeLog = false;
          this.$emit('reload', true);
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    hideTile() {
      let newTile = { ...this.tileData };
      newTile.hidden = !newTile.hidden;
      axios
        .put('/api/v1/kanban/hide', newTile)
        .then(() => {
          // this.loadKanbans();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadChangeLog() {
      axios
        .get('/api/v1/kanban/changelog/' + this.tileData.id)
        .then((response) => {
          this.listChangeLog = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    updateChangeLog() {
      this.changelog.duration = this.durationHH * 60 + this.durationMM;
      axios
        .put('/api/v1/kanban/changelog', this.changelog)
        .then(() => {
          this.loadChangeLog();
          this.dialogEditChangeLog = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteChangeLog() {
      axios
        .delete('/api/v1/kanban/changelog/' + this.changelog.id)
        .then(() => {
          this.loadChangeLog();
          this.dialogEditChangeLog = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    updateKanban() {
      axios
        .put('/api/v1/kanban', this.tileEdit)
        .then(() => {
          // this.loadKanbans();
          this.dialogEditTile = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteKanban() {
      axios
        .delete('/api/v1/kanban/' + this.tileEdit.id)
        .then(() => {
          // this.loadKanbans();
          this.dialogEditTile = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
