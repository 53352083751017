import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.$store.getters.listSystemNotifications.length == 0)?_c(VIcon,{staticClass:"pt-0",attrs:{"size":"40","color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiChat)+" ")]):_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBadge,{attrs:{"offset-y":"15","offset-x":"10","color":"blue","content":_vm.$store.getters.listSystemNotifications.length}},[_c(VIcon,_vm._g(_vm._b({staticClass:"pt-0",attrs:{"size":"40","color":_vm.$store.getters.listSystemNotifications.length > 0 ? 'btnColorReport' : ''}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiChatAlert))])],1)]}}]),model:{value:(_vm.systemNotifications),callback:function ($$v) {_vm.systemNotifications=$$v},expression:"systemNotifications"}},[_c(VCard,{attrs:{"width":"800"}},[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Notifications: "+_vm._s(_vm.$store.getters.listSystemNotifications.length))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"tile":""},on:{"click":_vm.deleteNotifications}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.mdiDeleteEmpty))])],1)],1)],1)],1),_c(VDivider),_c(VCardText,[_c(VSheet,{staticStyle:{"overflow":"auto"},attrs:{"max-height":"400"}},[_c('ul',{attrs:{"id":"example-1"}},_vm._l((_vm.$store.getters.listSystemNotifications),function(item){return _c('li',{key:item.ID},[_vm._v(" "+_vm._s(_vm._f("moment")(item.timeStamp,'DD/MM - HH:mm'))+" - "),_c('b',{class:`${item.color}--text`,domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }