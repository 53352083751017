<template>
  <v-row class="pt-2">
    <v-col>
      <v-row v-if="$store.getters.selectedClient.id != null" class="pb-10">
        <v-col>
          <quill-editor
            v-model="content"
            :style="`height: ${$store.getters.windowSize.y - 230}px;`"
            :options="editorOption"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-1">
        <v-col cols="4">
          <span>
            <h3 v-if="!$store.getters.selectedClient.id">Please select client</h3>
          </span>
          <v-btn
            tile
            block
            v-if="$store.getters.userSettings.accClientNotes.update && $store.getters.selectedClient.id != null"
            color="btnColorConfirm"
            @click="updateNotes"
            >update</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-snackbar v-model="saveSnack" :timeout="2000" top color="snackSuccess">Notes saved</v-snackbar>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import VueQuillEditor from 'vue-quill-editor';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor);

export default Vue.extend({
  name: 'ClientNotes',
  data: () => ({
    content: '',
    saveSnack: false,
    editorOption: {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', { header: 1 }, { header: 2 }, { size: ['small', false, 'large', 'huge'] }],
          [{ color: [] }, { background: [] }, { list: 'bullet' }, 'image'],
        ],
      },
    },
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadNotes();
    },
    '$store.getters.reloadTable'() {
      if (
        this.$store.getters.reloadTable.name === 'clientNotes' &&
        this.$store.getters.reloadTable.id === this.$store.getters.selectedClient.id
      ) {
        this.loadNotes();
        this.$store.commit('reloadTable', '');
      }
    },
  },
  mounted() {
    this.loadNotes();
  },
  computed: {
    editor() {
      const notesEditor: any = this.$refs.clientNotesEditor; // because typeScript
      return notesEditor.quill;
    },
  },
  methods: {
    loadNotes() {
      if (this.$store.getters.selectedClient.id !== undefined) {
        // dont try if clilent not selected
        axios
          .get(`/api/v1/clientnotes/${this.$store.getters.selectedClient.id}`)
          .then((response) => {
            this.content = response.data.notes;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
    },
    updateNotes() {
      axios
        .put(`/api/v1/clientnotes`, { clientid: this.$store.getters.selectedClient.id, notes: this.content })
        .then(() => (this.saveSnack = true))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
