<template>
  <div>
    <h1>You don't have an access to any evidence</h1>
    <h1>Please contact administrator</h1>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'no-access',
});
</script>
