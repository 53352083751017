<template>
  <v-row>
    <v-col>
      <v-row v-if="$store.getters.selectedClient.id != null">
        <v-col>
          <v-text-field v-model="search" clearable :append-icon="mdiMagnify" label="Search" hide-details></v-text-field>
          <div style="min-height: 2px"></div>

          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            :expanded="expandedRow"
            :height="$store.getters.windowSize.y - 230"
            :search="search"
            @click:row="rowClicked"
            fixed-header
            disable-pagination
            hide-default-footer
            class="tableCursor"
          >
            <template v-slot:item="{ item }">
              <tr @click="rowClicked(item)" v-bind:class="{ rowColorSelected: item.id == selectedRowID }">
                <td>{{ item.name }}</td>
                <td>{{ item.productName }}</td>
                <td>{{ item.subproductName }}</td>
                <td>{{ item.versionName }}</td>
                <td>{{ item.event }}</td>
                <td>{{ item.date | moment($store.getters.dateFormat) }}</td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="tableSlotBackground" :colspan="headers.length">
                <v-row class="mx-0 mt-1">
                  <v-col cols="1">
                    <v-btn @click="openEditDialog(item)" block small tile class="mb-2" color="btnColorNew">View </v-btn>
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="10">
                    <pre style="max-height: 150px; max-width: 100%; overflow: auto; white-space: pre-line">
                        {{ item.notes }}
                    </pre>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-1">
        <v-col cols="4">
          <span>
            <h3 v-if="!$store.getters.selectedClient.id">Please select client</h3>
          </span>
          <v-btn
            v-if="$store.getters.userSettings.accAppLog.write && $store.getters.selectedClient.id != null"
            @click="newAppLog"
            tile
            block
            color="btnColorNew"
            >NEW</v-btn
          >
        </v-col>
      </v-row>
    </v-col>

    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogEdit" width="980">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          AppLog:
          <v-spacer />
          <v-icon large @click="dialogEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm" ref="appLogForm">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col class="pb-0">
                    <v-text-field
                      v-model="appLog.name"
                      :rules="[rules.required]"
                      maxlength="128"
                      label="Name:"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-select
                      v-model="appLog.event"
                      :rules="[rules.required]"
                      :items="eventItems"
                      label="Event:"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="py-0">
                    <v-select
                      v-model="appLog.productID"
                      label="Product"
                      :items="listProducts"
                      item-text="shortName"
                      item-value="id"
                      v-on:change="changeProduct"
                      :rules="[rules.required]"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-select
                      v-model="appLog.subproductID"
                      label="Subproduct"
                      :items="listSubproducts"
                      item-text="shortName"
                      item-value="id"
                      :disabled="listSubproducts.length == 0"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="appLog.versionID"
                      label="Version"
                      :items="listVersions"
                      item-text="version"
                      item-value="id"
                      :disabled="!appLog.productID"
                      :rules="[rules.required]"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-menu
                      ref="dateMenu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateFormatted"
                          label="Date:"
                          :append-icon="mdiCalendar"
                          @blur="appLog.date = parseDate(dateFormatted)"
                          v-on="on"
                          hide-details
                        />
                      </template>
                      <v-date-picker v-model="appLog.date" no-title @input="dateMenu = false" />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-textarea
                      v-model="appLog.notes"
                      label="Notes"
                      hint="Aditional client notes"
                      rows="7"
                      no-resize
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-show="appLog.id != 0 && $store.getters.userSettings.accAppLog.readFiles"
                cols="6"
                v-cloak
                @drop.prevent="attachmentDropFile"
                @dragover.prevent
              >
                <v-row v-if="$store.getters.userSettings.accAppLog.writeFiles">
                  <v-col class="pt-0 mt-0">
                    <v-file-input v-model="chosenFiles" show-size counter clearable multiple label="Add attachment" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pr-0">
                    <v-data-table
                      :items="filesTable"
                      disable-pagination
                      height="320px"
                      hide-default-footer
                      style="height: 100%; max-width: 100%"
                      class="tableSelectCursor"
                    >
                      <template v-slot:item="{ item }">
                        <tr style="max-width: 100%">
                          <td width="55%">{{ item.filename }}</td>
                          <td width="25%">{{ item.size }}</td>
                          <td v-if="$store.getters.userSettings.accAppLog.downloadFiles" width="10%" class="text-xs">
                            <a :href="`/api/v1/applogfiles/${appLog.clientID}/${appLog.id}/${item.filename}`">
                              <img width="30px" height="30px" :src="item.icon" />
                            </a>
                          </td>
                          <td width="10%" v-if="$store.getters.userSettings.accAppLog.deleteFiles" class="text-xs">
                            <v-icon
                              left
                              color="red"
                              @click="(fileForDelete = item.filename), (confirmDeleteFileDialog = true)"
                              >{{ mdiDeleteEmpty }}</v-icon
                            >
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-show="appLog.id > 0 && $store.getters.userSettings.accAppLog.update"
            tile
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
          >
            Delete
          </v-btn>
          <v-btn
            tile
            v-if="$store.getters.userSettings.accAppLog.update && $store.getters.userSettings.accClients.update"
            color="btnColorConfirm"
            @click="saveAppLog(true)"
            >SAVE AND UPDATE PRODUCT
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            tile
            v-if="
              ($store.getters.userSettings.accAppLog.write && appLog.id == 0) ||
              $store.getters.userSettings.accAppLog.update
            "
            color="btnColorConfirm"
            @click="saveAppLog(false)"
            >SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadLoader" persistent max-width="40vw">
      <v-card color="rgb(242, 242, 242, 0.6)">
        <v-card-title class="headline" primary-title>Uploading</v-card-title>
        <div style="min-height: 300px" class="text-md-center">
          <div style="min-height: 80px" />
          <v-progress-circular :size="160" :width="20" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete File?"
      :body="'Do you really want delete ' + fileForDelete + ' file?'"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteFileDialog"
      v-on:confirm="deleteFile"
    />

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete appLog?"
      body="Do you really want delete appLog?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteAppLog"
    />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import tableMixin from '../../mixins/table';
import dateFormatMixin from '../../mixins/dateFormat';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiMagnify, mdiClose, mdiCalendar, mdiDeleteEmpty } from '@mdi/js';

export default Vue.extend({
  name: 'tableAppLog',
  mixins: [tableMixin, dateFormatMixin],
  components: { ConfirmDialog },
  data: () => ({
    dialogEdit: false,
    confirmDeleteFileDialog: false,
    confirmDeleteDialog: false,
    tableData: [],
    versionItems: [],
    dateFormatted: '',
    dateMenu: false,
    chosenFiles: [],
    filesTable: [],
    listProducts: [] as any,
    listSubproducts: [] as any,
    listVersions: [] as any,
    fileForDelete: '',
    uploadLoader: false,
    eventItems: ['Install', 'Upgrade', 'Change', 'Patch'],
    appLog: {
      id: 0,
      clientID: 0,
      productID: 0,
      productName: '',
      subproductID: 0,
      subproductName: '',
      versionID: 0,
      versionName: '',
      event: '',
      date: new Date().toISOString().substr(0, 10),
      notes: '',
    },
    validForm: false,
    search: '',
    headers: [
      { text: 'Name', value: 'name', width: '40%' },
      { text: 'Product', value: 'productName', width: '15%' },
      { text: 'Subproduct', value: 'subproductName', width: '15%' },
      { text: 'Version', value: 'versionName', width: '10%' },
      { text: 'Event', value: 'event', width: '10%' },
      { text: 'Date', value: 'date', width: '10%' },
    ],
    rules: {
      required: (value: any) => !!value || 'Required.',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'Must be numeric!!!';
      },
    },
    mdiMagnify: mdiMagnify,
    mdiClose: mdiClose,
    mdiCalendar: mdiCalendar,
    mdiDeleteEmpty: mdiDeleteEmpty,
  }),
  watch: {
    '$store.getters.selectedClient'() {
      this.loadAppLog();
    },
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'appLog') {
        this.loadAppLog();
        this.$store.commit('reloadTable', '');
      }
    },
    'appLog.date'() {
      this.dateFormatted = this.$moment(this.appLog.date).format(this.$store.getters.dateFormat);
    },
    'appLog.id'() {
      if (this.$store.getters.userSettings.accAppLog.readFiles) {
        this.loadFiles();
      }
    },
    chosenFiles() {
      if (this.chosenFiles.length > 0) {
        this.filesUpload();
      }
    },
  },
  mounted() {
    this.loadAppLog();
    this.loadProducts();
  },
  methods: {
    openEditDialog(item: any) {
      this.appLog = { ...item };
      this.loadSubproducts(this.appLog.productID);
      this.loadVersions(this.appLog.productID);
      this.dialogEdit = true;
    },
    changeProduct() {
      this.appLog.subproductID = 0;
      this.appLog.versionID = 0;
      this.loadSubproducts(this.appLog.productID).then(() => {
        this.listSubproducts.length > 0 ? (this.appLog.subproductID = this.listSubproducts[0].id) : '';
      });
      this.loadVersions(this.appLog.productID);
    },
    loadAppLog() {
      if (this.$store.getters.selectedClient.id != null) {
        axios
          .get('api/v1/applog', {
            params: {
              clientID: this.$store.getters.selectedClient.id,
            },
          })
          .then((response) => {
            this.tableData = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.tableData = [];
      }
    },
    newAppLog() {
      const getDefaults: any = this.$options;
      this.appLog = { ...getDefaults.data().appLog };
      this.appLog.clientID = this.$store.getters.selectedClient.id;
      this.listProducts.length > 0 ? (this.appLog.productID = this.listProducts[0].id) : '';
      this.loadSubproducts(this.listProducts[0].id).then(() => {
        this.listSubproducts.length > 0 ? (this.appLog.subproductID = this.listSubproducts[0].id) : '';
      });
      this.loadVersions(this.listProducts[0].id);
      this.dialogEdit = true;
      this.$nextTick(function () {
        const form: any = this.$refs.appLogForm;
        form.validate();
      });
    },
    saveAppLog(updateVersion: boolean) {
      const dataForSave = { ...this.appLog }; // Avoid interact with watcher on start_date
      dataForSave.date = new Date(dataForSave.date).toISOString();
      const form: any = this.$refs.appLogForm;
      if (form.validate() === true) {
        if (this.appLog.id === 0) {
          axios
            .post('/api/v1/applog', dataForSave)
            .then((response) => {
              this.appLog.id = response.data.newapplogid;
              updateVersion ? this.updateClientVersion() : '';
              this.loadAppLog();
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('/api/v1/applog', dataForSave)
            .then((response) => {
              updateVersion ? this.updateClientVersion() : '';
              this.loadAppLog();
              this.dialogEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    updateClientVersion() {
      axios
        .put(`/api/v1/clientproducts/`, {
          idClient: this.$store.getters.selectedClient.id,
          idProduct: this.appLog.productID,
          idSubproduct: this.appLog.subproductID,
          idVersion: this.appLog.versionID,
        })
        .then(() => this.$store.commit('reloadTable', 'clients'))
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteAppLog() {
      axios
        .delete(`/api/v1/applog/${this.appLog.id}`)
        .then((response) => {
          this.loadAppLog();
          this.dialogEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadFiles() {
      if (this.appLog.id !== 0) {
        axios
          .get(`/api/v1/applogfiles/${this.appLog.clientID}/${this.appLog.id}`)
          .then((response) => {
            for (const file of response.data) {
              file.size = Math.round(file.size / 1024);
              if (file.size >= 1024) {
                file.size = `${Math.round(file.size / 1024)} MB`;
              } else {
                file.size = `${file.size} kB`;
              }
              file.icon = `/api/v1/icon/${file.icon}`;
            }
            this.filesTable = response.data;
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        this.filesTable = [];
      }
    },
    attachmentDropFile(inputFiles: { dataTransfer: { files: [] } }) {
      this.chosenFiles = Array.from(inputFiles.dataTransfer.files);
    },
    filesUpload() {
      this.uploadLoader = true;
      const requests = this.chosenFiles.reduce((promiseChain, item) => {
        return promiseChain.then(
          () =>
            new Promise((resolve) => {
              const formData = new FormData();
              formData.append('file', item);
              axios
                .post(`/api/v1/applogfiles/${this.appLog.clientID}/${this.appLog.id}/file`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  // this.uploadStep++;
                  resolve();
                })
                .catch((err) => {
                  // this.uploadStep = 0;
                  this.$store.dispatch('catchErrHandler', err);
                });
            }),
        );
      }, Promise.resolve());
      requests.then(() => {
        this.chosenFiles = [];
        this.uploadLoader = false;
        this.loadFiles();
      });
    },
    deleteFile(file: any) {
      axios
        .delete(`/api/v1/applogfiles/${this.appLog.clientID}/${this.appLog.id}/file/${this.fileForDelete}`)
        .then((response) => {
          this.loadFiles();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadProducts() {
      axios
        .get(`api/v1/pm/product`)
        .then((response) => {
          this.listProducts = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    async loadSubproducts(requestid: number) {
      this.loadVersions(requestid);
      await axios
        .get(`api/v1/pm/subproduct/${requestid}`)
        .then((response) => {
          this.listSubproducts = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadVersions(requestid: number) {
      axios
        .get(`api/v1/pm/version/${requestid}`)
        .then((response) => {
          for (const version of response.data) {
            if (version.released === false) {
              version.version = version.version + ' - NOT released';
            }
          }
          this.listVersions = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
