<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-data-table
              :headers="groupheaders"
              :items="groups"
              :height="$store.getters.windowSize.y - 440"
              fixed-header
              disable-pagination
              hide-default-footer
              dense
              class="tableSelectCursor elevation-2"
            >
              <template v-slot:item="{ item }">
                <tr @click="selectGroup(item)" v-bind:class="{ rowColorSelected: selectedRowID == item.id }">
                  <td>{{ item.name }}</td>
                  <td>
                    <v-icon @click="openGroup(item)">{{ mdiPencil }}</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-btn
              v-if="$store.getters.userSettings.accProducts.update"
              tile
              small
              block
              color="btnColorConfirm"
              @click="openNewGroup"
              >Add group</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-data-table
              :headers="featureheaders"
              :items="features"
              :height="$store.getters.windowSize.y - 440"
              fixed-header
              @click:row="openFeature"
              disable-pagination
              hide-default-footer
              dense
              class="tableSelectCursor elevation-2"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-btn
              tile
              v-if="$store.getters.userSettings.accProducts.update && group.id"
              small
              block
              color="btnColorConfirm"
              @click="openNewFeature"
            >
              Add feature
            </v-btn>
            <span v-else>Select group to add feature </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogGroup" width="40%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Group:
          <v-spacer />
          <v-icon large @click="dialogGroup = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="group.name" maxlength="32" label="Group:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="group.note" label="Note:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn v-if="group.id" tile color="btnColorDelete" @click="confirmDeleteGroup = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveGroup">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFeature" width="40%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          Feature:
          <v-spacer />
          <v-icon large @click="dialogFeature = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="feature.name" maxlength="32" label="Feature:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="feature.note" label="Note:" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn v-if="feature.id" tile color="btnColorDelete" @click="confirmDeleteFeature = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveFeature">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete group?"
      body="Do you really want delete group?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteGroup"
      v-on:confirm="deleteGroup"
    />

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete feature?"
      body="Do you really want delete feature?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteFeature"
      v-on:confirm="deleteFeature"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import dateFormatMixin from '../../mixins/dateFormat';
import { mdiClose, mdiPencil } from '@mdi/js';

export default Vue.extend({
  name: 'products-groups',
  props: { product: Object },
  components: { ConfirmDialog },
  mixins: [dateFormatMixin],
  data: () => ({
    dialogGroup: false,
    dialogFeature: false,
    confirmDeleteGroup: false,
    confirmDeleteFeature: false,
    dateFormatted: '',
    dateMenu: false,
    selectedRowID: 0,
    groups: [],
    group: {
      id: 0,
      idProduct: 0,
      name: '',
      note: '',
    },
    groupheaders: [
      { text: 'Group name', value: 'name' },
      { text: 'Edit', width: '70' },
    ],
    features: [],
    feature: {
      id: 0,
      idGroup: 0,
      name: '',
      note: '',
    },
    featureheaders: [{ text: 'Feature name', value: 'name' }],
    mdiClose: mdiClose,
    mdiPencil: mdiPencil,
  }),
  mounted() {
    this.loadGroups();
  },
  watch: {
    product() {
      this.group.id = 0;
      this.selectedRowID = 0;
      this.groups = [];
      this.features = [];
      this.loadGroups();
    },
  },
  methods: {
    loadGroups() {
      axios
        .get(`api/v1/pm/group/${this.product.id}`)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    loadFeatures() {
      axios
        .get(`api/v1/pm/feature/${this.group.id}`)
        .then((response) => {
          this.features = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewGroup() {
      this.group.id = 0;
      this.group.name = '';
      this.group.note = '';
      this.dialogGroup = true;
    },
    openNewFeature() {
      this.feature.id = 0;
      this.feature.name = '';
      this.feature.note = '';
      this.dialogFeature = true;
    },
    selectGroup(item: any) {
      if (this.selectedRowID !== item.id) {
        this.selectedRowID = item.id;
      }
      this.group = { ...item };
      this.loadFeatures();
    },
    openGroup(item: any) {
      this.group = { ...item };
      this.dialogGroup = true;
    },
    openFeature(item: any) {
      this.feature = { ...item };
      this.dialogFeature = true;
    },
    saveGroup() {
      if (this.group.name === '') {
        this.$store.commit('globalMessage', 'You must enter group name');
        return;
      }
      this.group.idProduct = this.product.id;
      if (this.group.id === 0) {
        axios
          .post(`api/v1/pm/group`, this.group)
          .then(() => {
            this.loadGroups();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .put(`api/v1/pm/group`, this.group)
          .then(() => {
            this.loadGroups();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.dialogGroup = false;
    },
    saveFeature() {
      if (this.group.name === '') {
        this.$store.commit('globalMessage', 'You must enter feature name');
        return;
      }
      this.feature.idGroup = this.group.id;
      if (this.feature.id === 0) {
        axios
          .post(`api/v1/pm/feature`, this.feature)
          .then(() => {
            this.loadFeatures();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .put(`api/v1/pm/feature`, this.feature)
          .then(() => {
            this.loadFeatures();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.dialogFeature = false;
    },
    deleteGroup() {
      axios
        .delete(`api/v1/pm/group/${this.group.id}`)
        .then(() => {
          this.loadGroups();
          this.dialogGroup = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteFeature() {
      axios
        .delete(`api/v1/pm/feature/${this.feature.id}`)
        .then(() => {
          this.loadFeatures();
          this.dialogFeature = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
