<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="versionheaders"
          :items="versions"
          :height="$store.getters.windowSize.y - 440"
          fixed-header
          disable-pagination
          hide-default-footer
          @click:row="openVersion"
          dense
          class="tableSelectCursor elevation-2"
        >
          <template v-slot:item="{ item }">
            <tr @click="openVersion(item)">
              <td>{{ item.version }}</td>
              <td>{{ item.releaseDate | moment($store.getters.dateFormat) }}</td>
              <td v-if="item.released">
                <v-icon color="green">{{ mdiCheckBold }}</v-icon>
              </td>
              <td v-else>
                <v-icon>{{ mdiClose }}</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-btn
          v-if="$store.getters.userSettings.accProducts.update"
          tile
          small
          block
          color="btnColorConfirm"
          @click="openNewVersion"
          >Add version</v-btn
        >
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="dialogVersion" width="40%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          version:
          <v-spacer />
          <v-icon large @click="dialogVersion = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="version.version" maxlength="16" label="Version:" hide-details />
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Release date:"
                    :append-icon="mdiCalendar"
                    @blur="version.releaseDate = parseDate(dateFormatted)"
                    v-on="on"
                    hide-details
                    autocompete="new"
                  />
                </template>
                <v-date-picker v-model="version.releaseDate" no-title @input="dateMenu = false" />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="version.note" label="Note:" hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="version.released" label="Released" hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn v-if="version.id" tile color="btnColorDelete" @click="confirmDeleteVersion = true">Delete</v-btn>
          <v-spacer />
          <v-btn tile color="btnColorConfirm" @click="saveVersion">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete version?"
      body="Do you really want delete version?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteVersion"
      v-on:confirm="deleteVersion"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import dateFormatMixin from '../../mixins/dateFormat';
import { mdiCheckBold, mdiClose, mdiCalendar } from '@mdi/js';

export default Vue.extend({
  name: 'products-versions',
  props: { product: Object },
  components: { ConfirmDialog },
  mixins: [dateFormatMixin],
  data: () => ({
    dialogVersion: false,
    confirmDeleteVersion: false,
    dateFormatted: '',
    dateMenu: false,
    versions: [],
    version: {
      id: 0,
      idProduct: 0,
      version: '',
      releaseDate: '',
      released: false,
      note: '',
    },
    versionheaders: [
      { text: 'Version', value: 'version' },
      { text: 'Release date', value: 'releaseDate', width: '200' },
      { text: 'Released', value: 'released', width: '120' },
    ],
    mdiCheckBold: mdiCheckBold,
    mdiClose: mdiClose,
    mdiCalendar: mdiCalendar,
  }),
  mounted() {
    this.loadVersions();
  },
  watch: {
    product() {
      this.loadVersions();
    },
    'version.releaseDate'() {
      this.dateFormatted = this.$moment(this.version.releaseDate).format(this.$store.getters.dateFormat);
    },
  },
  methods: {
    loadVersions() {
      axios
        .get(`api/v1/pm/version/${this.product.id}`)
        .then((response) => {
          this.versions = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    openNewVersion() {
      this.version.id = 0;
      this.version.version = '';
      this.version.releaseDate = new Date().toISOString().substr(0, 10);
      this.version.released = false;
      this.version.note = '';
      this.dialogVersion = true;
    },
    openVersion(item: any) {
      this.version = { ...item };
      this.dialogVersion = true;
    },
    saveVersion() {
      if (this.version.version === '') {
        this.$store.commit('globalMessage', 'You must enter version name');
        return;
      }
      let dataForSave = { ...this.version }; // Avoid interact with watcher on date
      dataForSave.releaseDate = new Date(dataForSave.releaseDate).toISOString();
      dataForSave.idProduct = this.product.id;
      if (this.version.id === 0) {
        axios
          .post(`api/v1/pm/version`, dataForSave)
          .then(() => {
            this.loadVersions();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      } else {
        axios
          .put(`api/v1/pm/version`, dataForSave)
          .then(() => {
            this.loadVersions();
          })
          .catch((err) => {
            this.$store.dispatch('catchErrHandler', err);
          });
      }
      this.dialogVersion = false;
    },
    deleteVersion() {
      axios
        .delete(`api/v1/pm/version/${this.version.id}`)
        .then(() => {
          this.loadVersions();
          this.dialogVersion = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
