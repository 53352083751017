<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-col cols="2">
        <v-navigation-drawer :height="$store.state.windowSize.y - 50" class="sideBarBackground" width="100%" permanent>
          <v-list>
            <v-list-item
              v-if="$store.getters.userSettings.accAzureGroups.read"
              @click="swapComponent('resourceGroups')"
              v-bind:class="{ primary: currentComponent == 'resourceGroups' }"
            >
              <v-list-item-icon>
                <v-icon>{{ mdiAccount }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Resource Groups</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="swapComponent('tab1')"
              v-bind:class="{
                primary: currentComponent == 'tab1',
              }"
            >
              <v-list-item-icon>
                <v-icon>{{ mdiAccountGroup }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tab1</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="swapComponent('tab2')"
              v-bind:class="{
                primary: currentComponent == 'tab2',
              }"
            >
              <v-list-item-icon>
                <v-icon>{{ mdiAccountMultipleCheck }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tab2</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>

      <v-col cols="10">
        <div :is="currentComponent" :swap-component="swapComponent" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import ResourceGroups from '@/components/azure/resourceGroups.vue';
import Tab1 from '@/components/azure/tab1.vue';
import Tab2 from '@/components/azure/tab2.vue';
import { mdiAccountGroup, mdiAccountMultipleCheck, mdiAccount } from '@mdi/js';

export default Vue.extend({
  name: 'AzureView',
  components: {
    resourceGroups: ResourceGroups,
    tab1: Tab1,
    tab2: Tab2,
  },
  data: () => ({
    currentComponent: 'resourceGroups',
    mdiAccountGroup: mdiAccountGroup,
    mdiAccountMultipleCheck: mdiAccountMultipleCheck,
    mdiAccount: mdiAccount,
  }),
  methods: {
    swapComponent(component: string) {
      this.currentComponent = component;
    },
  },
});
</script>
