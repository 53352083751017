<template>
  <v-container fluid>
    <v-row>
      <v-col class="px-0">
        <v-data-table
          :headers="headers"
          :items="tableData"
          item-key="id"
          :height="$store.getters.windowSize.y - 190"
          fixed-header
          disable-pagination
          hide-default-footer
          class="tableCursor"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-xs">{{ item.name }}</td>
              <td class="text-xs">
                <v-avatar v-if="item.picture" class="mr-2" color="primary" size="35">
                  <img :src="`data:image/png;base64, ${item.picture}`" />
                </v-avatar>
                <v-avatar v-else color="primary" class="mr-2" size="35">
                  <v-icon>{{ mdiAccount }}</v-icon>
                </v-avatar>
                {{ item.fullName }}
              </td>
              <td class="text-xs">{{ item.accClients }}</td>
              <td class="text-xs">{{ item.accSerialisations }}</td>
              <td class="text-xs">{{ item.accKnowledgeBase }}</td>
              <td class="text-xs">{{ item.accContacts }}</td>
              <td class="text-xs">{{ item.accUsers }}</td>
              <td class="text-xs">{{ item.accAppLog }}</td>
              <td class="text-xs">{{ item.accProducts }}</td>
              <td class="text-xs tableSelectCursor">
                <v-icon
                  color="btnColorEdit"
                  v-if="$store.getters.userSettings.accUsers.update"
                  @click="(user = item), (modalEdit = true)"
                  >{{ mdiAccountEdit }}</v-icon
                >
              </td>
              <td class="text-xs">
                <v-icon
                  v-if="item.online"
                  @click="(modalSendMessage = true), (sendMessageUser = item.name)"
                  color="green"
                  >{{ mdiMessageText }}</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0 px-0" cols="4">
        <v-btn v-if="$store.getters.userSettings.accUsers.write" color="btnColorNew" tile block @click="newUser">
          New
        </v-btn>
      </v-col>
    </v-row>
    <!----------DIALOGS------------------------------>
    <v-dialog v-model="modalEdit" width="80%">
      <v-card>
        <v-card-title class="headline headerColorStandard" primary-title>
          <v-avatar v-if="user.picture" class="mr-2" color="primary" size="45">
            <img :src="`data:image/png;base64, ${user.picture}`" />
          </v-avatar>
          <v-avatar v-else color="primary" class="mr-2" size="45">
            <v-icon>{{ mdiAccount }}</v-icon>
          </v-avatar>
          User:
          <v-spacer />
          <v-icon large @click="modalEdit = false">{{ mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <v-form v-model="validForm" ref="userForm">
            <v-row no-gutters>
              <v-col cols="4">
                <v-text-field
                  label="User name"
                  :disabled="user.id > 0"
                  hide-details
                  v-model="user.name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field label="Full name" hide-details v-model="user.fullName"></v-text-field>
                <v-text-field label="Email" hide-details v-model="user.email"></v-text-field>
                <v-text-field
                  label="Password"
                  autocomplete="new-password"
                  hide-details
                  type="password"
                  v-model="user.password"
                ></v-text-field>
                <v-textarea
                  class="mt-3"
                  label="Note"
                  hide-details
                  v-model="user.note"
                  rows="8"
                  no-resize
                  outlined
                ></v-textarea>
              </v-col>
              <v-col class="pl-4" cols="8">
                <v-row no-gutters>
                  <v-col cols="3"></v-col>
                  <v-col cols="1">Read</v-col>
                  <v-col cols="1">Write</v-col>
                  <v-col cols="1">Update</v-col>
                  <v-col cols="1">Delete</v-col>
                  <v-col cols="1">Patch</v-col>
                  <v-col cols="1">F.Read</v-col>
                  <v-col cols="1">F.Write</v-col>
                  <v-col cols="1">F.Get</v-col>
                  <v-col cols="1">F.Del</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Clients:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accClientsRead"
                      v-bind:disabled="user.accClients > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accClientsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accClientsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accClientsDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Serialisations:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accSerialisationsRead"
                      v-bind:disabled="user.accSerialisations > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSerialisationsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSerialisationsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSerialisationsDelete" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSerialisationsPatch" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Contacts:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accContactsRead"
                      v-bind:disabled="user.accContacts > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accContactsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accContactsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accContactsDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>AppLog:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accAppLogRead"
                      v-bind:disabled="user.accAppLog > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogDelete" />
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogFilesRead" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogFilesWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogFilesGet" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAppLogFilesDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Secrets:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accSecretsRead"
                      v-bind:disabled="user.accSecrets > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSecretsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSecretsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accSecretsDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>KnowledgeBase:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accKnowledgeBaseRead"
                      v-bind:disabled="user.accKnowledgeBase > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseDelete" />
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseFilesRead" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseFilesWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseFilesGet" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accKnowledgeBaseFilesDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Users:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accUsersRead"
                      v-bind:disabled="user.accUsers > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accUsersWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accUsersUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accUsersDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Products:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accProductsRead"
                      v-bind:disabled="user.accProducts > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accProductsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accProductsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accProductsDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>ClientNotes:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accClientNotesRead"
                      v-bind:disabled="user.accClientNotes > 1"
                    />
                  </v-col>
                  <v-col cols="1" />
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accClientNotesUpdate" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Notifications:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accNotificationsRead"
                      v-bind:disabled="user.accNotifications > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accNotificationsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accNotificationsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accNotificationsDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Hosting:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accHostingRead"
                      v-bind:disabled="user.accHosting > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accHostingWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accHostingUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accHostingDelete" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>BugTrack:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accBugTrackRead"
                      v-bind:disabled="user.accBugTrack > 1"
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="3"><b>Azure</b></v-col>
                  <v-col cols="1">Read</v-col>
                  <v-col cols="1">Write</v-col>
                  <v-col cols="1">Update</v-col>
                  <v-col cols="1">Del.Set</v-col>
                  <v-col cols="1">Del.Group</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="3"><div>Resource groups:</div></v-col>
                  <v-col cols="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="accAzureGroupsRead"
                      v-bind:disabled="user.accAzureGroups > 1"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAzureGroupsWrite" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAzureGroupsUpdate" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAzureGroupsDelSet" />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox class="ma-0 pa-0" hide-details v-model="accAzureGroupsDelGroup" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            v-if="user.id > 0 && $store.getters.userSettings.accUsers.delete"
            color="btnColorDelete"
            @click="confirmDeleteDialog = true"
            >Delete</v-btn
          >
          <v-btn
            tile
            v-if="user.id > 0 && $store.getters.userSettings.accUsers.update"
            color="btnColorCancel"
            @click="deleteGauth(user.name)"
            >clear google authenticator</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            tile
            v-if="
              ($store.getters.userSettings.accUsers.write && user.id == 0) ||
              $store.getters.userSettings.accUsers.update
            "
            color="btnColorConfirm"
            @click="saveUser"
            >SAVE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalSendMessage" max-width="40vw">
      <v-card>
        <v-card-title class="headline blue lighten-3" primary-title>Message:</v-card-title>
        <v-card-text>
          <v-text-field v-model="sendMessageText" type="text" label="Write your Message:" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="btnColorConfirm" @click="sendMessage">Send</v-btn>
          <v-btn color="btnColorCancel" @click="modalSendMessage = false">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      headerColor="btnColorDelete"
      header="Delete user?"
      body="Do you really want delete user?"
      confirmButton="Delete"
      :openDialog.sync="confirmDeleteDialog"
      v-on:confirm="deleteUser"
    />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { mdiClose, mdiAccountEdit, mdiMessageText, mdiAccount } from '@mdi/js';

export default Vue.extend({
  name: 'tableSettingsUsers',
  components: { ConfirmDialog },
  data: () => ({
    $socket: {
      sendObj: Object,
    },
    confirmDeleteDialog: false,
    tableData: [],
    validForm: false,
    modalEdit: false,
    modalSendMessage: false,
    sendMessageUser: '',
    sendMessageText: '',
    user: {
      id: 0,
      name: '',
      password: null,
      fullName: null,
      email: null,
      admin: false,
      note: null,
      picture: null,
      accClients: 1,
      accSerialisations: 1,
      accContacts: 1,
      accAppLog: 1,
      accKnowledgeBase: 1,
      accUsers: 1,
      accProducts: 1,
      accClientNotes: 1,
      accNotifications: 0,
      accHosting: 0,
      accBugTrack: 0,
      accSecrets: 0,
      accAzureGroups: 0,
      online: false,
    },
    headers: [
      { text: 'Login name', value: 'name', width: '10%' },
      { text: 'Full Name', value: 'fullName', width: '25%' },
      { text: 'Clients', value: 'accClients', width: '5%' },
      { text: 'Serial.', value: 'accSerialisations', width: '5%' },
      { text: 'KB', value: 'accKnowledgeBase', width: '5%' },
      { text: 'Contacts', value: 'accContacts', width: '5%' },
      { text: 'Users', value: 'accUsers', width: '5%' },
      { text: 'appLog', value: 'accAppLog', width: '5%' },
      { text: 'Products', value: 'accProducts', width: '5%' },
      { text: 'Edit', value: '', width: '5%' },
      { text: 'Msg', value: 'online', width: '5%' },
    ],
    rules: {
      required: (value: any) => !!value || 'Required!!!',
      number: (value: any) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || 'MUST be number!!!';
      },
    },
    mdiClose: mdiClose,
    mdiAccountEdit: mdiAccountEdit,
    mdiMessageText: mdiMessageText,
    mdiAccount: mdiAccount,
  }),
  watch: {
    '$store.getters.reloadTable'() {
      if (this.$store.getters.reloadTable.name === 'users') {
        this.loadUsers();
        this.$store.commit('reloadTable', '');
      }
    },
    '$store.getters.wsOnlineUsers'() {
      // update online users in case of change
      let row = {
        online: false,
        name: '',
      };
      for (row of this.tableData) {
        row.online = false;
        for (const onlineUser of this.$store.getters.wsOnlineUsers) {
          if (onlineUser === row.name) {
            row.online = true;
          }
        }
      }
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      axios
        .get(`/api/v1/users`)
        .then((response) => {
          if (this.$store.getters.wsOnlineUsers.length !== undefined) {
            for (const row of response.data) {
              row.online = false;
              for (const onlineUser of this.$store.getters.wsOnlineUsers) {
                if (onlineUser === row.name) {
                  row.online = true;
                }
              }
            }
          } else {
            for (const row of response.data) {
              row.online = false;
            }
          }
          this.tableData = response.data;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    newUser() {
      const getDefaults: any = this.$options;
      this.user = { ...getDefaults.data().user };
      this.modalEdit = true;
      this.$nextTick(function () {
        const form: any = this.$refs.userForm;
        form.validate();
      });
    },
    saveUser() {
      const form: any = this.$refs.userForm;
      if (!form.validate()) {
        this.$store.commit('globalMessage', 'Form is not filled correctly!!!');
      } else {
        if (this.user.id === 0) {
          axios
            .post('/api/v1/users', this.user)
            .then((response) => {
              if (response.data.message === 'userExists') {
                this.$store.commit('globalMessage', 'User already exists!!!');
              } else {
                this.loadUsers();
                this.modalEdit = false;
              }
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        } else {
          axios
            .put('/api/v1/users', this.user)
            .then((response) => {
              this.loadUsers();
              this.modalEdit = false;
            })
            .catch((err) => {
              this.$store.dispatch('catchErrHandler', err);
            });
        }
      }
    },
    deleteUser() {
      axios
        .delete(`/api/v1/users/${this.user.id}`)
        .then((response) => {
          this.loadUsers();
          this.modalEdit = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    deleteGauth(user: String) {
      axios
        .patch(`/api/v1/users/gauth/${user}`)
        .then(() => {
          this.$store.commit('appNotifyMessage', 'google authenticator settings deleted');
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
    sendMessage() {
      this.$socket.sendObj({
        action: 'userMessage',
        message: this.sendMessageText,
        user: this.sendMessageUser,
        userfrom: this.$store.getters.user,
      });
      this.sendMessageText = '';
    },
  },
  computed: {
    accClientsRead: {
      get() {
        return (this.user.accClients & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accClients |= 1;
        } else {
          this.user.accClients &= ~1;
        }
      },
    },
    accClientsWrite: {
      get() {
        return (this.user.accClients & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accClients |= 3;
        } else {
          this.user.accClients &= ~2;
        }
      },
    },
    accClientsUpdate: {
      get() {
        return (this.user.accClients & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accClients |= 5;
        } else {
          this.user.accClients &= ~4;
        }
      },
    },
    accClientsDelete: {
      get() {
        return (this.user.accClients & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accClients |= 9;
        } else {
          this.user.accClients &= ~8;
        }
      },
    },
    accSerialisationsRead: {
      get() {
        return (this.user.accSerialisations & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accSerialisations |= 1;
        } else {
          this.user.accSerialisations &= ~1;
        }
      },
    },
    accSerialisationsWrite: {
      get() {
        return (this.user.accSerialisations & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accSerialisations |= 3;
        } else {
          this.user.accSerialisations &= ~2;
        }
      },
    },
    accSerialisationsUpdate: {
      get() {
        return (this.user.accSerialisations & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accSerialisations |= 5;
        } else {
          this.user.accSerialisations &= ~4;
        }
      },
    },
    accSerialisationsDelete: {
      get() {
        return (this.user.accSerialisations & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accSerialisations |= 9;
        } else {
          this.user.accSerialisations &= ~8;
        }
      },
    },
    accSerialisationsPatch: {
      get() {
        return (this.user.accSerialisations & 16) === 16;
      },
      set(x) {
        if (x) {
          this.user.accSerialisations |= 17;
        } else {
          this.user.accSerialisations &= ~16;
        }
      },
    },
    accContactsRead: {
      get() {
        return (this.user.accContacts & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accContacts |= 1;
        } else {
          this.user.accContacts &= ~1;
        }
      },
    },
    accContactsWrite: {
      get() {
        return (this.user.accContacts & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accContacts |= 3;
        } else {
          this.user.accContacts &= ~2;
        }
      },
    },
    accContactsUpdate: {
      get() {
        return (this.user.accContacts & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accContacts |= 5;
        } else {
          this.user.accContacts &= ~4;
        }
      },
    },
    accContactsDelete: {
      get() {
        return (this.user.accContacts & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accContacts |= 9;
        } else {
          this.user.accContacts &= ~8;
        }
      },
    },
    accAppLogRead: {
      get() {
        return (this.user.accAppLog & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 1;
        } else {
          this.user.accAppLog &= ~1;
        }
      },
    },
    accAppLogWrite: {
      get() {
        return (this.user.accAppLog & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 3;
        } else {
          this.user.accAppLog &= ~2;
        }
      },
    },
    accAppLogUpdate: {
      get() {
        return (this.user.accAppLog & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 5;
        } else {
          this.user.accAppLog &= ~4;
        }
      },
    },
    accAppLogDelete: {
      get() {
        return (this.user.accAppLog & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 9;
        } else {
          this.user.accAppLog &= ~8;
        }
      },
    },
    accAppLogFilesRead: {
      get() {
        return (this.user.accAppLog & 32) === 32;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 33;
        } else {
          this.user.accAppLog &= ~32;
        }
      },
    },
    accAppLogFilesWrite: {
      get() {
        return (this.user.accAppLog & 64) === 64;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 65;
        } else {
          this.user.accAppLog &= ~64;
        }
      },
    },
    accAppLogFilesGet: {
      get() {
        return (this.user.accAppLog & 128) === 128;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 129;
        } else {
          this.user.accAppLog &= ~128;
        }
      },
    },
    accAppLogFilesDelete: {
      get() {
        return (this.user.accAppLog & 256) === 256;
      },
      set(x) {
        if (x) {
          this.user.accAppLog |= 257;
        } else {
          this.user.accAppLog &= ~256;
        }
      },
    },
    accKnowledgeBaseRead: {
      get() {
        return (this.user.accKnowledgeBase & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 1;
        } else {
          this.user.accKnowledgeBase &= ~1;
        }
      },
    },
    accKnowledgeBaseWrite: {
      get() {
        return (this.user.accKnowledgeBase & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 3;
        } else {
          this.user.accKnowledgeBase &= ~2;
        }
      },
    },
    accKnowledgeBaseUpdate: {
      get() {
        return (this.user.accKnowledgeBase & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 5;
        } else {
          this.user.accKnowledgeBase &= ~4;
        }
      },
    },
    accKnowledgeBaseDelete: {
      get() {
        return (this.user.accKnowledgeBase & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 9;
        } else {
          this.user.accKnowledgeBase &= ~8;
        }
      },
    },
    accKnowledgeBaseFilesRead: {
      get() {
        return (this.user.accKnowledgeBase & 32) === 32;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 33;
        } else {
          this.user.accKnowledgeBase &= ~32;
        }
      },
    },
    accKnowledgeBaseFilesWrite: {
      get() {
        return (this.user.accKnowledgeBase & 64) === 64;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 65;
        } else {
          this.user.accKnowledgeBase &= ~64;
        }
      },
    },
    accKnowledgeBaseFilesGet: {
      get() {
        return (this.user.accKnowledgeBase & 128) === 128;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 129;
        } else {
          this.user.accKnowledgeBase &= ~128;
        }
      },
    },
    accKnowledgeBaseFilesDelete: {
      get() {
        return (this.user.accKnowledgeBase & 256) === 256;
      },
      set(x) {
        if (x) {
          this.user.accKnowledgeBase |= 257;
        } else {
          this.user.accKnowledgeBase &= ~256;
        }
      },
    },
    accUsersRead: {
      get() {
        return (this.user.accUsers & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accUsers |= 1;
        } else {
          this.user.accUsers &= ~1;
        }
      },
    },
    accUsersWrite: {
      get() {
        return (this.user.accUsers & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accUsers |= 3;
        } else {
          this.user.accUsers &= ~2;
        }
      },
    },
    accUsersUpdate: {
      get() {
        return (this.user.accUsers & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accUsers |= 5;
        } else {
          this.user.accUsers &= ~4;
        }
      },
    },
    accUsersDelete: {
      get() {
        return (this.user.accUsers & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accUsers |= 9;
        } else {
          this.user.accUsers &= ~8;
        }
      },
    },
    accProductsRead: {
      get() {
        return (this.user.accProducts & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accProducts |= 1;
        } else {
          this.user.accProducts &= ~1;
        }
      },
    },
    accProductsWrite: {
      get() {
        return (this.user.accProducts & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accProducts |= 3;
        } else {
          this.user.accProducts &= ~2;
        }
      },
    },
    accProductsUpdate: {
      get() {
        return (this.user.accProducts & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accProducts |= 5;
        } else {
          this.user.accProducts &= ~4;
        }
      },
    },
    accProductsDelete: {
      get() {
        return (this.user.accProducts & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accProducts |= 9;
        } else {
          this.user.accProducts &= ~8;
        }
      },
    },
    accClientNotesRead: {
      get() {
        return (this.user.accClientNotes & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accClientNotes |= 1;
        } else {
          this.user.accClientNotes &= ~1;
        }
      },
    },
    accClientNotesUpdate: {
      get() {
        return (this.user.accClientNotes & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accClientNotes |= 5;
        } else {
          this.user.accClientNotes &= ~4;
        }
      },
    },
    accNotificationsRead: {
      get() {
        return (this.user.accNotifications & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accNotifications |= 1;
        } else {
          this.user.accNotifications &= ~1;
        }
      },
    },
    accNotificationsWrite: {
      get() {
        return (this.user.accNotifications & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accNotifications |= 3;
        } else {
          this.user.accNotifications &= ~2;
        }
      },
    },
    accNotificationsUpdate: {
      get() {
        return (this.user.accNotifications & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accNotifications |= 5;
        } else {
          this.user.accNotifications &= ~4;
        }
      },
    },
    accNotificationsDelete: {
      get() {
        return (this.user.accNotifications & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accNotifications |= 9;
        } else {
          this.user.accNotifications &= ~8;
        }
      },
    },
    accHostingRead: {
      get() {
        return (this.user.accHosting & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accHosting |= 1;
        } else {
          this.user.accHosting &= ~1;
        }
      },
    },
    accHostingWrite: {
      get() {
        return (this.user.accHosting & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accHosting |= 3;
        } else {
          this.user.accHosting &= ~2;
        }
      },
    },
    accHostingUpdate: {
      get() {
        return (this.user.accHosting & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accHosting |= 5;
        } else {
          this.user.accHosting &= ~4;
        }
      },
    },
    accHostingDelete: {
      get() {
        return (this.user.accHosting & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accHosting |= 9;
        } else {
          this.user.accHosting &= ~8;
        }
      },
    },
    accSecretsRead: {
      get() {
        return (this.user.accSecrets & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accSecrets |= 1;
        } else {
          this.user.accSecrets &= ~1;
        }
      },
    },
    accSecretsWrite: {
      get() {
        return (this.user.accSecrets & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accSecrets |= 3;
        } else {
          this.user.accSecrets &= ~2;
        }
      },
    },
    accSecretsUpdate: {
      get() {
        return (this.user.accSecrets & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accSecrets |= 5;
        } else {
          this.user.accSecrets &= ~4;
        }
      },
    },
    accSecretsDelete: {
      get() {
        return (this.user.accSecrets & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accSecrets |= 9;
        } else {
          this.user.accSecrets &= ~8;
        }
      },
    },
    accBugTrackRead: {
      get() {
        return (this.user.accBugTrack & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accBugTrack |= 1;
        } else {
          this.user.accBugTrack &= ~1;
        }
      },
    },
    accAzureGroupsRead: {
      get() {
        return (this.user.accAzureGroups & 1) === 1;
      },
      set(x) {
        if (x) {
          this.user.accAzureGroups |= 1;
        } else {
          this.user.accAzureGroups &= ~1;
        }
      },
    },
    accAzureGroupsWrite: {
      get() {
        return (this.user.accAzureGroups & 2) === 2;
      },
      set(x) {
        if (x) {
          this.user.accAzureGroups |= 3;
        } else {
          this.user.accAzureGroups &= ~2;
        }
      },
    },
    accAzureGroupsUpdate: {
      get() {
        return (this.user.accAzureGroups & 4) === 4;
      },
      set(x) {
        if (x) {
          this.user.accAzureGroups |= 5;
        } else {
          this.user.accAzureGroups &= ~4;
        }
      },
    },
    accAzureGroupsDelSet: {
      get() {
        return (this.user.accAzureGroups & 8) === 8;
      },
      set(x) {
        if (x) {
          this.user.accAzureGroups |= 9;
        } else {
          this.user.accAzureGroups &= ~8;
        }
      },
    },
    accAzureGroupsDelGroup: {
      get() {
        return (this.user.accAzureGroups & 16) === 16;
      },
      set(x) {
        if (x) {
          this.user.accAzureGroups |= 17;
        } else {
          this.user.accAzureGroups &= ~16;
        }
      },
    },
  },
});
</script>
