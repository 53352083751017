<template>
  <v-card height="400">
    <v-card-title class="primary">Files: </v-card-title>
    <v-card-text v-if="database.name">
      <v-row class="mt-1">
        <v-col class="py-1" cols="12">
          <v-btn
            block
            small
            tile
            color="btnColorEdit"
            @click="(selectedFolder = 'linkedDocs'), (listFiles = []), loadFiles(), (filesDialog = true)"
          >
            Linked Docs
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1" cols="12">
          <v-btn
            block
            small
            tile
            color="btnColorEdit"
            @click="(selectedFolder = 'reports'), (listFiles = []), loadFiles(), (filesDialog = true)"
          >
            Reports
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1" cols="12">
          <v-btn
            block
            small
            tile
            color="btnColorEdit"
            @click="(selectedFolder = 'stylesheets'), (listFiles = []), loadFiles(), (filesDialog = true)"
          >
            Stylesheets
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1" cols="12">
          <v-btn
            block
            small
            tile
            color="btnColorEdit"
            @click="(selectedFolder = 'additionalFiles'), (listFiles = []), loadFiles(), (filesDialog = true)"
          >
            Additional Files
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!---------DIALOGS----------------------------------------------------------------->
    <v-dialog v-model="filesDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="headline primary" primary-title>
          Files: {{ selectedFolder }}
          <v-spacer />
          <v-icon large @click="filesDialog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-cloak @drop.prevent="attachmentDropFile" @dragover.prevent>
              <v-row v-if="$store.getters.userSettings.accAzureGroups.write">
                <v-col class="py-0 my-0">
                  <v-file-input v-model="chosenFiles" counter clearable multiple hide-details label="Add files" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pr-0">
                  <v-data-table
                    :items="listFiles"
                    :headers="headersFiles"
                    :loading="listFilesLoader"
                    fixed-header
                    hide-default-footer
                    disable-pagination
                    dense
                    :height="340"
                    class="elevation-2 tableSelectCursor"
                  >
                    <template v-slot:item="{ item }">
                      <tr style="max-width: 100%">
                        <td width="30%">{{ item.name }}</td>
                        <td width="20%">{{ item.size }}</td>
                        <td width="10%" class="text-xs">
                          <v-btn
                            icon
                            :href="`/api/v1/azure/files/${azuid}/${database.domain}/${selectedFolder}/${item.name}`"
                          >
                            <v-icon>{{ mdiFileDownload }}</v-icon>
                          </v-btn>
                        </td>
                        <td width="10%" class="text-xs">
                          <v-icon color="red" @click="filesDelete(item.name)">{{ mdiDeleteEmpty }}</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoaderDialog :openDialog.sync="loaderDialog" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import LoaderDialog from '@/components/loaderDialog.vue';
import { mdiClose, mdiDeleteEmpty, mdiFileDownload } from '@mdi/js';

export default Vue.extend({
  name: 'resourceGroupsFiles',
  components: { LoaderDialog },
  props: {
    azuid: String,
    database: Object,
  },
  data: () => ({
    filesDialog: false,
    chosenFiles: [],
    selectedFolder: '',
    listFiles: [],
    listFilesLoader: false,
    headersFiles: [
      { text: 'Name', value: 'name', width: '70%' },
      { text: 'Size', value: 'size' },
      { text: '', value: '' },
      { text: '', value: '' },
    ],
    loaderDialog: false,
    mdiClose: mdiClose,
    mdiDeleteEmpty: mdiDeleteEmpty,
    mdiFileDownload: mdiFileDownload,
  }),
  watch: {
    chosenFiles() {
      if (this.chosenFiles.length > 0) {
        this.filesUpload();
      }
    },
  },
  mounted() {},
  methods: {
    loadFiles() {
      this.listFilesLoader = true;
      axios
        .get(`/api/v1/azure/files/${this.azuid}/${this.database.domain}/${this.selectedFolder}`)
        .then((response) => {
          for (const file of response.data) {
            file.size = Math.round(file.size / 1024);
            if (file.size >= 1024) {
              file.size = `${Math.round(file.size / 1024)} MB`;
            } else {
              file.size = `${file.size} kB`;
            }
          }
          this.listFiles = response.data;
          this.listFilesLoader = false;
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
          this.listFilesLoader = false;
        });
    },
    attachmentDropFile(inputFiles: { dataTransfer: { files: [] } }) {
      this.chosenFiles = Array.from(inputFiles.dataTransfer.files);
    },
    filesUpload() {
      this.loaderDialog = true;
      const requests = this.chosenFiles.reduce((promiseChain, item) => {
        return promiseChain.then(
          () =>
            new Promise((resolve) => {
              const formData = new FormData();
              formData.append('file', item);
              axios
                .post(`/api/v1/azure/files/${this.azuid}/${this.database.domain}/${this.selectedFolder}`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  resolve();
                })
                .catch((err) => {
                  this.$store.dispatch('catchErrHandler', err);
                });
            }),
        );
      }, Promise.resolve());
      requests.then(() => {
        this.chosenFiles = [];
        this.loaderDialog = false;
        this.loadFiles();
      });
    },
    filesDelete(file: string) {
      axios
        .delete(`/api/v1/azure/files/${this.azuid}/${this.database.domain}/${this.selectedFolder}/${file}`)
        .then(() => {
          this.loadFiles();
        })
        .catch((err) => {
          this.$store.dispatch('catchErrHandler', err);
        });
    },
  },
});
</script>
